import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import up from "../../assets/images/up.png";
import down from "../../assets/images/down.png";

const OptionsDiv = styled.div`
  position: absolute;
  z-index: 2;
  background-color: white;
  padding: 8px;
  //width: 100%;
  border-bottom: 1px solid gray;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  min-width: 198px;
  padding-top: 2px;
  padding-bottom: 2px;
  height: 140px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  /* align-items: center; */
`;

const TitleDiv = styled.div`
  padding: 4px;
  min-width: 198px;
  //width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid gray;
  box-sizing: border-box;
`;

const Input = styled.input`
  min-width: 14px;
`;

const LabelValueCheckBox = ({
  options,
  value,
  onChange,
  name,
  id,
  defaultValue,
  formik,
}) => {
  const [defaultSetValues, setDefaultSetValues] = useState(defaultValue);
  const [defaultSetValueIds, setDefaultSetValueIds] = useState([]);

  useEffect(() => {
    if (defaultValue) {
      setDefaultSetValueIds(defaultValue?.map((x) => x._id));
    }
  }, [defaultSetValues]);

  const [open, setOpen] = useState(false);

  const checkboxGroupRef = useRef(null);

  const handleToggle = () => setOpen(!open);

  const handleClose = (event) => {
    if (event && event.target && event.target.tagName !== "INPUT") {
      setOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        checkboxGroupRef.current &&
        !checkboxGroupRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const isChecked = (option) => {
    return defaultSetValues.some(
      (defaultOption) => defaultOption?._id === option?._id
    );
  };

  const handleCheckboxChange = (option) => {
    const updatedSetValues = defaultSetValueIds?.includes(option?._id)
      ? defaultSetValues?.filter((x) => x._id !== option._id)
      : [...defaultSetValues, option];

    setDefaultSetValues(updatedSetValues);
    setDefaultSetValueIds(updatedSetValues?.map((option) => option?._id));

    // const updatedValue = updatedSetValues?.map((option) => option?._id);
    // console.log("updated Value ========> ", updatedValue);
    // onChange(updatedValue); // Call the onChange function passed from Formik
    // formik.setFieldValue(name, updatedValue); // Update form field value using setFieldValue
  };

  useEffect(() => {
    formik.setFieldValue(name, defaultSetValues);
  }, [defaultSetValues]);
  return (
    <div ref={checkboxGroupRef}>
      <TitleDiv onClick={handleToggle}>
        <span>Click to {open ? "Close" : "Open"}</span>
        {open ? <img src={up} alt="up" /> : <img src={down} alt="down" />}
      </TitleDiv>
      {open && (
        <OptionsDiv onClick={handleClose}>
          {options.map((option, index) => (
            <div style={{ display: "flex", gap: "8px" }} key={option?._id}>
              <Input
                type="checkbox"
                name={name}
                value={option._id}
                id={id}
                onChange={() => handleCheckboxChange(option)}
                checked={isChecked(option)}
              />
              <label htmlFor={id}>{option?.farmerName}</label>
            </div>
          ))}
        </OptionsDiv>
      )}
    </div>
  );
};

export default LabelValueCheckBox;
