import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  singleHoney: {},
  loading: true,
  error: "",
  message: "",
};

export const makeHoney = createAsyncThunk("makeHoney", async (body) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_HUMBLEBEE_API_URL}/honeys/`,
      body,
      { "Content-Type": "multipart/form-data" }
    );
    console.log("body from makeHoney ", body);
    console.log("res.data from makeHoney  ", res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    //@ts-ignore
    return error.response.data;
  }
});

const makeHoneySlice = createSlice({
  name: "makeHoney",
  initialState,
  reducers: {},
  extraReducers: {
    //@ts-ignore
    [makeHoney.pending]: (state, action) => {
      state.loading = true;
    },
    //@ts-ignore
    [makeHoney.fulfilled]: (
      //@ts-ignore
      state,
      //@ts-ignore
      { payload: { error, singleHoney, message } }
    ) => {
      state.loading = false;
      if (error) {
        state.error = message;
      } else {
        state.singleHoney = singleHoney;
        state.message = message;
      }
    },
    //@ts-ignore
    [makeHoney.rejected]: (state, { message, error }) => {
      state.error = error;
      state.message = message;
    },
  },
});

export default makeHoneySlice.reducer;
