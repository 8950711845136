import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Styles from "./style";
import { toast } from "react-toastify";
import { getAllClusters } from "../../../reduxLogic/reducers/clusters/getAllClustersSlice";
import { deleteCluster } from "../../../reduxLogic/reducers/clusters/deleteClusterSlice";
const DeleteModal = (props) => {
  const { idToDelete, setDeleteModal } = props;
  let dispatch = useDispatch();

  const handleDeleteProject = () => {
    dispatch(deleteCluster(idToDelete))
      .then(() => dispatch(getAllClusters()))
      .then(() => setDeleteModal(false))
      .then(() => toast.warning(`Project with id ${idToDelete} deleted`));
  };

  const handleCloseModal = () => {
    setDeleteModal(false);
  };
  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        <Styles.InnerContainer>
          <h3>Are you sure you want to delete?</h3>
          <Styles.ButtonDiv>
            <Styles.DeleteButton onClick={handleDeleteProject}>
              Yes
            </Styles.DeleteButton>
            <Styles.CloseButton onClick={handleCloseModal}>
              No
            </Styles.CloseButton>
          </Styles.ButtonDiv>
        </Styles.InnerContainer>
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  );
};

export default DeleteModal;
