import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  // ConfigProvider,
  // DatePicker,
  Select,
  // Input,
  Checkbox,
  Switch,
  InputNumber,
} from "antd";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Col,
  Row,
  Modal,
  Button,
  Badge,
  Card,
  Accordion,
  DropdownButton,
  Dropdown,
  // Button
} from "react-bootstrap";
// import enUS from "antd/es/locale/en_US";
import React, { Component } from "react";
import Swal from "sweetalert2";
import reactswal from "@sweetalert/with-react";

import moment from "moment";
import $ from "jquery";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'
import AddCandidatesToProjectModal from '../AddCandidatesToProjectModal';

// import { ScaleLoader } from "react-spinners";
// import { indexOf } from "lodash";
var createHost = require('cross-domain-storage/host');
// import createHost from 'cross-domain-storage/host'

// import { allBanks} from '../../../commonConfig';
// import Check from "../../../../public/images/check.png";
// import Cancel from "../../../../public/images/cancel.png";

const readCookie = require("../cookie.js").readCookie;
const currentToken = readCookie("access_token");

// const getRatiosString = (creditCount, selfInvestmentCount) => {
//   if (creditCount <= 0) return [].join("");

//   if (selfInvestmentCount <= 0)
//     return `Please ensure that the payment claims are raised only once a month and includes only credit linked candidates`;

//   let ratios = [];
//   // for ()
//   let numer = Math.round(creditCount / 5);
//   let denom = Math.round(selfInvestmentCount / 5);
//   for (let i = 1; i <= 4; i++) {
//     ratios.push(`${numer * i}:${denom * i}`);
//   }
//   // while (hcf < 5) {
//   // 	numer = numer+1;
//   // 	hcf = findHCF(numer, denom);
//   // 	console.log(hcf, numer, denom);
    
//   // }
//   ratios.push(`${creditCount}:${selfInvestmentCount}`);
//   return `Please ensure that the payment claims are raised only once a month and in the ratio (credit linked : 100 % self investment) - ${ratios.join(
//     " or "
//   )}`;
// };
const { SearchBar } = Search;
// const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;

  // var storageHost = createHost([
  //   {
  //     origin: process.env.REACT_APP_CAMPAIGN_URL,
  //     allowedMethods: ['get', 'set', 'remove'],
  //   }
  // ]);

export default class Leads extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      allSupportServices: [],
      showImportModal: false,
      leadsLoading: true,
      importing: false,
      candidatesImportFile: null,
      allLeads: [],
      allStates: [],
      focusStates: [],
      searchText: "",
      allSaarthis: [],
      allDistricts: [],
      allSources: [],
      allBusinessIdeas: [],
      swavalambanFellows: [],
      allMilestones: [],
      projects: [],
      filterData: {
        essOptedFor: '',
        paymentClaim: "",
        scaleUpQuestionnaire: "",
        bplanAge:"",
        pwd: "",
        idea: "",
        phase: "",
        audit: "",
        entrepreneurStoryVerification: "",
        state: "",
        gender: "",
        cluster: [],
        category: "",
        source: "",
        district: [],
        userType: "",
        taggedTo: "",
        toDate: null,
        onHold: false,
        collateral: "",
        starred: false,
        fromDate: null,
        assignedTo: "",
        hasComments: "",
        financeType: "",
        bankAccounts: [],
        minLoanAmount: "",
        maxLoanAmount: "",
        filedIncomeTax: "",
        maturityStatus: [],
        strengthRating: "",
        ddOnHoldReasons: [],
        bpOnHoldReasons: [],
        disqualified: false,
        profilingStages: [],
        overallSentiment: "",
        modeOfApplication: "",
        profilingStage: false,
        dueDiligenceStages: [],
        businessPlanStages: [],
        dueDiligenceStage: false,
        businessPlanStage: false,
        enterpriseSetupStages: [],
        capitalFulfilledStatus: [],
        capitalFulfilledStages: [],
        disqualificationReasons: [],
        enterpriseSetupStage: false,
        capitalFulfilledStage: false,
        businessPlanVerification: "",
        tentativeHorizonToCloseThis: "",
        enterpriseSustainabilityStages: [],
        enterpriseSustainabilityStage: false,
        loanSchemeId: [],
        loanScheme: [],
        sidbiMilestone: "",
      },
      allBanks: [],
      allLoanSchemes: [],
      page: 1,
      totalLeads: 0,
      leadCounts: {},
      allUserTypes: [],
      sizePerPage: 100,
      allAssignedTo: [],
      showFilter: false,
      sizePerPageList: [50, 100, 150, 200, 500],
      ddOnHoldReasons: [
        "Unresponsive (Not picking up)",
        "Unresponsive (Unable to connect/Switched off)",
        "Interested to start business after some time",
        "Applicant different from entrepreneur: Has no idea of the business",
        "Business Idea not finalized",
        "Less idea about why to start the business",
        "Business Idea requires license, but candidate does not have those",
        "Need more time to know the exact location to start the business",
        "Need more time to identify target customers",
        "Need more time to estimate monthly sales and costs",
        "Need more time to find nearby competitive businesses",
        "Need more time to find the place to buy goods/machines/raw materials",
        "Previous loan taken and ongoing",
        "Need more time to supply quotations corresponding to items purchased",
        "Candidate has no PAN",
        "Other Reason",
      ],
      bpOnHoldReasons: [
        "Unresponsive (Not picking up)",
        "Unresponsive (Unable to connect/Switched off)",
        "Submission of quotations shall take time",
        "Not keen to visit bank(s) at the moment",
        "Business location not finalized",
        "Business metrics (assumptions) not finalized",
        "Collateral/Security related documents pending",
        "Required license/rent deed/other key document pending",
        "Skill/EDP training pending",
        "Other Reason",
      ],
      disqualificationReasons: [
        "No longer interested",
        "Has no idea/intention of starting up",
        "Spam caller",
        "Not able to get in touch at all",
        "Migrated",
        "Passed away",
        "Poor CIBIL score",
        "Only looking for bank loan (not very genuine)",
        "Scale-up: Has availed credit linkage before",
        "Duplicate lead",
        "Other Reason",
      ],
      // Changes for follow up data
      followUpCounts: { dueThisWeek: 0, dueToday: 0, overDue: 0 },
      followUpDues: { dueThisWeek: [], dueToday: [], overDue: [] },
      followUpFilters: { today: false, overDue: false, week: false },
      showFollowUpModule: false,
      enterpriseProgressStats: {},
      selectMode: false,
      selectAllLeadsQuery: '',
      selectedLeads: [],
      selectedLeadsIds: [],
      countSelectedLeads: 0,
      selectAllLeadsInfo: [],
      showAddLeadsToProjectModal: false,
      allLeadsSelected: false,
      projectMoveAction: '',
      allUsers: [],
    };
  }
  

  hideAddLeadsToProjectModal = (addedToProject=false) =>{
    this.setState({ showAddLeadsToProjectModal: false });
    if (addedToProject) {
      this.setState({
        selectedLeads: [],
        selectedLeadsIds: [],
        allLeadsSelected: false,
        countSelectedLeads:0,
        selectMode: false,
      });
      this.submitFilter('page');
    }
  }
  getCreditStats = () => {
    fetch(process.env.REACT_APP_API_URL + "/api/v1/stats/credit-stats", {
      method: "GET",
      headers: {
        "x-auth-token": JSON.parse(readCookie("access_token")),
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ enterpriseProgressStats: data.stats || {} });
        }
      });
  };

  fetchProjects = () => {
    fetch(process.env.REACT_APP_API_URL + `/api/v1/project/no-pid-auth`, {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
        // 'project': userData.id
      }
    }).then(data => data.json())
      .then(data => {
        if (data.status === "ok") {
          this.setState({projects: data.projects});
        }
      })
  }

  componentDidMount() {
    createHost([{
        origin: process.env.REACT_APP_CAMPAIGN_URL,
        allowedMethods: ['get', 'set', 'remove'],
    }])
  }
  componentWillMount() {
    if (JSON.parse(readCookie("userData")).type === "partners") {
      this.getCreditStats();
    }


  
    //   ###########################################################################################################################################
    this.getAllSources();
    this.fetchProjects();
    this.getAllBanks();
    if (
      JSON.parse(readCookie("userData")).userType.name ===
      "Project Management Unit" ||
      JSON.parse(readCookie("userData")).userType.name === "Zonal Manager" ||
      JSON.parse(readCookie("userData")).userType.name === "Swavalamban Fellows"
    )
      this.setState({ showFollowUpModule: true });

    const res = fetch(`${process.env.REACT_APP_HUMBLEBEE_API_URL}/users?limit=1000&role=trainee_beekeeper`, {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${currentToken}`
      },
    })
    .then(data => data.json())
    .then(data => {
      console.log("res.data from Users ==> ", data.userInfo[0].userInfo);
      this.setState({ allUsers: data.userInfo });
    })

    fetch(process.env.REACT_APP_API_URL + "/api/v1/schemes", {
      method: "GET",
      headers: {
        "x-auth-token": JSON.parse(readCookie("access_token")),
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ allLoanSchemes: data.schemes });
        }
      });

    fetch(process.env.REACT_APP_API_URL + "/api/v1/user/user-types", {
      method: "GET",
      headers: {
        "x-auth-token": JSON.parse(readCookie("access_token")),
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ allUserTypes: data.userTypes });
        }
      });

    fetch(process.env.REACT_APP_API_URL + "/api/v1/location/states", {
      method: "GET",
      headers: {
        "x-auth-token": JSON.parse(readCookie("access_token")),
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          let focusStates = data.data.states.filter((st) => st.inFocusRegion);
          focusStates = focusStates.map((st) => st.name);
          this.setState({ allStates: data.data.states, focusStates });
        }
      });
    
    fetch(
      process.env.REACT_APP_API_URL +
      "/api/v1/user/users/saarthis?type=list&status=Active",
      {
        method: "GET",
        headers: {
          "x-auth-token": JSON.parse(readCookie("access_token")),
        },
      }
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ allSaarthis: data.users });
        }
      });

    fetch(
      process.env.REACT_APP_API_URL + "/api/v1/location/districts?sortBy=name",
      {
        method: "GET",
        headers: {
          "x-auth-token": JSON.parse(readCookie("access_token")),
        },
      }
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ allDistricts: data.districts });
        }
      });

    if (
      JSON.parse(readCookie("userData")).type === "le" &&
      (JSON.parse(readCookie("userData")).userType.name ===
        "Project Management Unit" ||
        JSON.parse(readCookie("userData")).userType.name === "Zonal Manager")
    ) {
      fetch(
        process.env.REACT_APP_API_URL +
        "/api/v1/user/users/swavalamban-fellows?type=list&status=Active&fellowAccessType=Full",
        {
          method: "GET",
          headers: {
            "x-auth-token": JSON.parse(readCookie("access_token")),
          },
        }
      )
        .then((data) => data.json())
        .then((data) => {
          if (data.status) {
            this.setState({ swavalambanFellows: data.users });
          }
        });
    }
    let getAllLeadsFilter = sessionStorage.getItem("getAllLeadsFilter");
    if (getAllLeadsFilter) {
      if (JSON.parse(getAllLeadsFilter).userType) {
        if (
          JSON.parse(getAllLeadsFilter).userType ===
          "swavalamban-connect-kendra"
        ) {
          fetch(process.env.REACT_APP_API_URL + "/api/v1/ngo", {
            method: "GET",
            headers: {
              "x-auth-token": JSON.parse(readCookie("access_token")),
            },
          })
            .then((data) => data.json())
            .then((data) => {
              if (data.status) {
                this.setState({ allAssignedTo: data.ngos });
              }
            });
        } else {
          fetch(
            process.env.REACT_APP_API_URL +
            "/api/v1/user/users/" +
            JSON.parse(getAllLeadsFilter).userType,
            {
              method: "GET",
              headers: {
                "x-auth-token": JSON.parse(readCookie("access_token")),
              },
            }
          )
            .then((data) => data.json())
            .then((data) => {
              if (data.status) {
                this.setState({ allAssignedTo: data.users });
              }
            });
        }
      }
      this.createBusinessIdeasQuery(JSON.parse(getAllLeadsFilter));
      this.setState({ filterData: JSON.parse(getAllLeadsFilter) }, () => {
        this.submitFilter("searchText");
      });
    } else {
      this.getAllLeads();
      this.createBusinessIdeasQuery(this.state.filterData);
    }
    // this.getFollowUpInfo();
  }
  getAllBanks = () => {
    fetch(process.env.REACT_APP_API_URL + "/api/v1/bank-branches/banks", {
      method: "GET",
      headers: {
        "x-auth-token": JSON.parse(readCookie("access_token")),
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ allBanks: data.allBanks });
        }
      });
  };
  getAllSources = () => {
    fetch(process.env.REACT_APP_API_URL + "/api/v1/lists/sources", {
      method: "GET",
      headers: {
        "x-auth-token": JSON.parse(readCookie("access_token")),
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ allSources: data.sources });
        }
      });
  };

  getAllLeads = (query, cb, selectMode) => {
    this.setState({ leadsLoading: true });
    if (!query)
      query = `?pageNumber=${this.state.page - 1}&limit=${this.state.sizePerPage
        }&disqualified=${this.state.filterData.disqualified}&starred=${this.state.filterData.starred
        }&onHold=${this.state.filterData.onHold}`;
    // if(this.props.sidebarMinimized) query += `&capitalFulfilledStage=${this.state.filterData.capitalFulfilledStage}&enterpriseSetupStage=${this.state.filterData.enterpriseSetupStage}`;
    // fetch(
    //   process.env.REACT_APP_API_URL + "/api/v1/swati-leads/counts" + query,
    //   {
    //     method: "GET",
    //     headers: {
    //       "x-auth-token": JSON.parse(readCookie("access_token")),
    //     },
    //   }
    // )
    //   .then((data) => data.json())
    //   .then((data) => {
    //     if (data.status) {
    //       this.setState({ leadCounts: data.leadCounts });
    //     }
    //   });
    // fetch(
    //   process.env.REACT_APP_API_URL +
    //   "/api/v1/swati-leads/followup-counts" +
    //   query,
    //   {
    //     method: "GET",
    //     headers: {
    //       "x-auth-token": JSON.parse(readCookie("access_token")),
    //     },
    //   }
    // )
    //   .then((data) => data.json())
    //   .then((data) => {
    //     if (data.status) {
    //       this.setState({ followUpCounts: data.followUpCounts });
    //     }
    //   });

    query += `&dueToday=${this.state.followUpFilters.today}&dueThisWeek=${this.state.followUpFilters.week}&overDue=${this.state.followUpFilters.overDue}`;
    let path =
      process.env.REACT_APP_API_URL + "/api/v1/swati-leads/all-in-one";
    if (
      this.props.type === "swavalamban-connect-kendra" &&
      this.props.view === "tab"
    )
      path =
        process.env.REACT_APP_API_URL +
        "/api/v1/swati-leads/" +
        this.props.orgId;
    fetch(
      // process.env.REACT_APP_API_URL + "/api/v1/swati-leads/all-in-one" + query,
      path + query,
      {
        method: "GET",
        headers: {
          "x-auth-token": JSON.parse(readCookie("access_token")),
        },
      }
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          let filterData = this.state.filterData;
          let selectedIdea = this.state.filterData.idea;
          if (selectedIdea) {
            let hasIdea = false;
            for (let i = 0; i < data.ideas.length; i++) {
              if (data.ideas[i].combine === selectedIdea) hasIdea = true;
            }
            if (!hasIdea) filterData["idea"] = "";
            this.setState({ filterData });
          }
          this.setState(
            {
              allLeads: data.simpleLeadInfo || [],
              selectAllLeadsInfo: data.selectAllLeadsInfo || [],
              totalLeads: data.totalLeads || 0,
              followUpDues: data.records || [],
              leadCounts: data.leadCounts || {},
              followUpCounts: data.followUpCounts || {},
              allBusinessIdeas: data.ideas || [],
              allSupportServices: data.allSupportServices || [],
              leadsLoading: false,
              allMilestones: data.milestones || [],
            },
            () => {
              $(() => {
                // use UI arrows in page content to control scrolling
                this.scrolling = false;
                // var that = this;

                $("#drag-table-left").mouseover((evt) => {
                  this.scrolling = true;
                  this.startScrolling(
                    $("table#leads-page-table.table"),
                    8,
                    evt.target.id,
                    "right"
                  );
                });

                $("#drag-table-right").mouseover((evt) => {
                  this.scrolling = true;
                  this.startScrolling(
                    $("table#leads-page-table.table"),
                    8,
                    evt.target.id,
                    "left"
                  );
                });

                $("#drag-table-left").mouseout(() => {
                  this.scrolling = false;
                });
                $("#drag-table-right").mouseout(() => {
                  this.scrolling = false;
                });
              });
            }
          );
        }
      });
    // fetch(path, {
    //   method: "GET",
    //   headers: {
    //     "x-auth-token": JSON.parse(readCookie("access_token")),
    //   },
    // })
    //   .then((data) => data.json())
    //   .then((data) => {
    //     if (cb) cb();
    //     if (data.status) {
    //       this.setState(
    //         {
    //           allLeads: data.simpleLeadInfo,
    //           totalLeads: data.totalLeads,
    //           followUpDues: data.records,
    //         },
    //         () => {
    //           $(() => {
    //             // use UI arrows in page content to control scrolling
    //             this.scrolling = false;
    //             // var that = this;

    //             $("#drag-table-left").mouseover((evt) => {
    //               this.scrolling = true;
    //               this.startScrolling(
    //                 $("table#leads-page-table.table"),
    //                 8,
    //                 evt.target.id,
    //                 "right"
    //               );
    //             });

    //             $("#drag-table-right").mouseover((evt) => {
    //               this.scrolling = true;
    //               this.startScrolling(
    //                 $("table#leads-page-table.table"),
    //                 8,
    //                 evt.target.id,
    //                 "left"
    //               );
    //             });

    //             $("#drag-table-left").mouseout(() => {
    //               this.scrolling = false;
    //             });
    //             $("#drag-table-right").mouseout(() => {
    //               this.scrolling = false;
    //             });
    //           });
    //         }
    //       );
    //     }
    //   });
  };

  selectAllLeads = (e) => {

    
        if (!e.target.checked) {
          this.setState({selectMode: false})
          this.setState({allLeadsSelected: false, selectedLeads: [], selectedLeadsIds: []})
          this.setState({selectMode: true})  
        }
        else {
          this.setState({selectMode: false})
          this.setState({ allLeadsSelected: true, selectedLeads: this.state.selectAllLeadsInfo || [], selectedLeadsIds: (this.state.selectAllLeadsInfo.map(x=>x._id)) || [] })
          this.setState({selectMode: true})
        }
    // if (!query)
    //   query = `?pageNumber=${this.state.page - 1}&limit=${this.state.sizePerPage
    //     }&disqualified=${this.state.filterData.disqualified}&starred=${this.state.filterData.starred
    //     }&onHold=${this.state.filterData.onHold}`;

    // query += `&dueToday=${this.state.followUpFilters.today}&dueThisWeek=${this.state.followUpFilters.week}&overDue=${this.state.followUpFilters.overDue}`;
    // let path =
    //   process.env.REACT_APP_API_URL + "/api/v1/swati-leads/selectall";
    // fetch(
    //   path + query,
    //   {
    //     method: "GET",
    //     headers: {
    //       "x-auth-token": JSON.parse(readCookie("access_token")),
    //     },
    //   }
    // )
    //   .then((data) => data.json())
    //   .then((data) => {
    //     if (this.state.allLeadsSelected) {
    //       this.setState({selectMode: false})
    //       this.setState({allLeadsSelected: false, selectedLeads: []})
    //       this.setState({selectMode: true})  
    //     } else {
    //       this.setState({selectMode: false})
    //       this.setState({allLeadsSelected: true, selectedLeads: data.simpleLeadInfo})
    //       this.setState({selectMode: true})
    //     }
    //   })
    
  };


  getAllBusinessIdeas = (query, selectedIdea) => {
    // fetch(
    //   process.env.REACT_APP_API_URL +
    //   "/api/v1/swati-leads/lead-business-ideas" +
    //   query,
    //   {
    //     method: "GET",
    //     headers: {
    //       "x-auth-token": JSON.parse(readCookie("access_token")),
    //     },
    //   }
    // )
    //   .then((data) => data.json())
    //   .then((data) => {
    //     if (data.status) {
    //       let filterData = this.state.filterData;
    //       if (selectedIdea) {
    //         let hasIdea = false;
    //         for (let i = 0; i < data.ideas.length; i++) {
    //           if (data.ideas[i].combine === selectedIdea) hasIdea = true;
    //         }
    //         if (!hasIdea) filterData["idea"] = "";
    //         this.setState({ filterData });
    //       }
    //       this.setState({ allBusinessIdeas: data.ideas });
    //     }
    //   });
  };

  startScrolling = (obj, spd, btn, direction) => {
    var travelX = 0,
      stop = false;
    if ($(obj).css("transform") !== "none")
      travelX = parseInt($(obj).css("transform").split(",")[4].trim());

    if (direction === "left") {
      let max =
        $(".leads-table-container .react-bootstrap-table").width() -
        $(obj).width();
      if (travelX - spd < max) {
        travelX = max;
        stop = true;
        $(obj).css("transform", "translateX(" + travelX + "px)");
      } else travelX -= spd;
    } else if (direction === "right") {
      if (travelX + spd > 0) {
        travelX = 0;
        stop = true;
        $(obj).css("transform", "translateX(" + travelX + "px)");
      } else travelX += spd;
    }

    if (!this.scrolling || stop) {
      obj.stop();
    } else {
      $(obj).css("transform", "translateX(" + travelX + "px)");
      // recursively call startScrolling while mouse is hovered
      setTimeout(() => {
        this.startScrolling(obj, spd, btn, direction);
      }, spd);
    }
  };

  resetFilter = () => {
    fetch(
      process.env.REACT_APP_API_URL + "/api/v1/location/districts?sortBy=name",
      {
        method: "GET",
        headers: {
          "x-auth-token": JSON.parse(readCookie("access_token")),
        },
      }
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ allDistricts: data.districts });
        }
      });

    let filterData = {
      loanScheme: [],
      loanSchemeId: [],
      paymentClaim: "",
      essOptedFor: "",
      scaleUpQuestionnaire: "",
      bplanAge:"",
      pwd: "",
      idea: "",
      phase: "",
      audit: "",
      entrepreneurStoryVerification: "",
      gender: "",
      category: "",
      state: "",
      district: [],
      cluster: [],
      fromDate: null,
      toDate: null,
      hasComments: "",
      businessPlanVerification: "",
      collateral: "",
      bankAccounts: [],
      financeType: "",
      minLoanAmount: "",
      maxLoanAmount: "",
      userType: "",
      assignedTo: "",
      taggedTo: "",
      overallSentiment: "",
      strengthRating: "",
      maturityStatus: [],
      capitalFulfilledStatus: [],
      filedIncomeTax: "",
      modeOfApplication: "",
      disqualified: false,
      starred: false,
      onHold: false,
      profilingStage: false,
      dueDiligenceStage: false,
      businessPlanStage: false,
      capitalFulfilledStage: false,
      enterpriseSetupStage: false,
      tentativeHorizonToCloseThis: "",
      enterpriseSustainabilityStage: false,
      sidbiMilestone: "",
    };
    let query = `?pageNumber=${this.state.page - 1}&limit=${this.state.sizePerPage
      }&disqualified=false&starred=false&onHold=false`;

    sessionStorage.setItem("getAllLeadsFilter", JSON.stringify(filterData));

    this.setState({ filterData, allAssignedTo: [] }, () =>
      this.getAllLeads(query)
    );
    this.createBusinessIdeasQuery(filterData);
    this.showFilter();
  };
  notifySuccess = (text) => toast.success(text)

  submitFilter = (
    type = "",
    page = this.state.page,
    sizePerPage = this.state.sizePerPage,
    searchText = this.state.searchText,
    sortBy = "",
    sortOrder = ""
  ) => {
    let filterData = this.state.filterData,
      query = `?pageNumber=${page - 1}&limit=${sizePerPage}`;

    if (searchText) query += `&q=${searchText}`;
    if (
      filterData.loanScheme &&
      filterData.loanSchemeId.length &&
      filterData.loanSchemeId[0]
    )
      query += "&loanSchemeId=" + filterData.loanSchemeId.join(",");
    if (filterData.pwd !== "" && filterData.pwd !== undefined)
      query += `&pwd=${filterData.pwd}`;
    if (filterData.idea !== "" && filterData.idea !== undefined)
      query += `&idea=${filterData.idea}`;
    if (filterData.phase !== "" && filterData.phase !== undefined)
      query += `&phase=${filterData.phase}`;
    if (filterData.paymentClaim !== "" && filterData.paymentClaim !== undefined)
      query += `&paymentClaim=${filterData.paymentClaim}`;
    if (filterData.essOptedFor !== "" && filterData.essOptedFor !== undefined)
      query += `&essOptedFor=${filterData.essOptedFor}`;
    if (filterData.scaleUpQuestionnaire !== "" && filterData.scaleUpQuestionnaire !== undefined)
      query += `&scaleUpQuestionnaire=${filterData.scaleUpQuestionnaire}`;
    if (filterData.audit !== "" && filterData.audit !== undefined)
      query += `&audit=${filterData.audit}`;
    if (
      filterData.entrepreneurStoryVerification !== "" &&
      filterData.entrepreneurStoryVerification !== undefined
    )
      query += `&entrepreneurStoryVerification=${filterData.entrepreneurStoryVerification}`;
    if (filterData.state !== "" && filterData.state !== undefined)
      query += `&state=${filterData.state}`;
    if (filterData.gender !== "" && filterData.gender !== undefined)
      query += `&gender=${filterData.gender}`;
    if (filterData.category !== "" && filterData.category !== undefined)
      query += `&category=${filterData.category}`;
    if (filterData.collateral !== "" && filterData.collateral !== undefined)
      query += `&collateral=${filterData.collateral}`;
    if (filterData.hasComments !== "" && filterData.hasComments !== undefined)
      query += `&hasComments=${filterData.hasComments}`;
    if (
      filterData.businessPlanVerification !== "" &&
      filterData.businessPlanVerification !== undefined
    )
      query += `&businessPlanVerification=${filterData.businessPlanVerification}`;
    if (
      filterData.bankAccounts &&
      filterData.bankAccounts.length &&
      filterData.bankAccounts[0]
    )
      query += `&bankAccounts=${filterData.bankAccounts.join(",")}`;
    if (filterData.source && filterData.source !== "") query += `&source=${filterData.source}`;
    if (
      filterData.district &&
      filterData.district.length &&
      filterData.district[0]
    )
      query += `&district=${filterData.district.join(",")}`;
    if (filterData.toDate)
      query += `&toDate=${moment(filterData.toDate).format("DD-MM-YYYY")}`;
    if (filterData.fromDate)
      query += `&fromDate=${moment(filterData.fromDate).format("DD-MM-YYYY")}`;
    if (filterData.financeType !== "" && filterData.financeType !== undefined)
      query += `&financeType=${encodeURIComponent(filterData.financeType)}`;
    if (
      filterData.overallSentiment !== "" &&
      filterData.overallSentiment !== undefined
    )
      query += `&overallSentiment=${filterData.overallSentiment}`;
    if (
      filterData.strengthRating !== "" &&
      filterData.strengthRating !== undefined
    )
      query += `&strengthRating=${filterData.strengthRating}`;
    if (
      filterData.filedIncomeTax !== "" &&
      filterData.filedIncomeTax !== undefined
    )
      query += `&filedIncomeTax=${filterData.filedIncomeTax}`;
    if (
      filterData.modeOfApplication !== "" &&
      filterData.modeOfApplication !== undefined
    )
      query += `&modeOfApplication=${filterData.modeOfApplication}`;
    if (filterData.disqualified !== "" && filterData.disqualified !== undefined)
      query += `&disqualified=${filterData.disqualified}`;
    if (filterData.starred !== "" && filterData.starred !== undefined)
      query += `&starred=${filterData.starred}`;
    if (filterData.onHold !== "" && filterData.onHold !== undefined)
      query += `&onHold=${filterData.onHold}`;
    if (filterData.profilingStage && filterData.profilingStages.length)
      query += `&profilingStages=${filterData.profilingStages}`;
    if (filterData.dueDiligenceStage && filterData.dueDiligenceStages.length)
      query += `&dueDiligenceStages=${filterData.dueDiligenceStages}`;
    if (filterData.businessPlanStage && filterData.businessPlanStages.length)
      query += `&businessPlanStages=${filterData.businessPlanStages}`;
    if (
      filterData.capitalFulfilledStage &&
      filterData.capitalFulfilledStages.length
    )
      query += `&capitalFulfilledStages=${filterData.capitalFulfilledStages}`;
    if (
      filterData.enterpriseSetupStage &&
      filterData.enterpriseSetupStages.length
    )
      query += `&enterpriseSetupStages=${filterData.enterpriseSetupStages}`;
    if (
      filterData.dueDiligenceStages &&
      filterData.dueDiligenceStages.indexOf("Hold") > -1 &&
      filterData.ddOnHoldReasons &&
      filterData.ddOnHoldReasons.length
    )
      query += `&ddOnHoldReasons=${filterData.ddOnHoldReasons.join("|")}`;
    if (
      filterData.businessPlanStages &&
      filterData.businessPlanStages.indexOf("Hold") > -1 &&
      filterData.bpOnHoldReasons &&
      filterData.bpOnHoldReasons.length
    )
      query += `&bpOnHoldReasons=${filterData.bpOnHoldReasons.join("|")}`;
    if (
      filterData.enterpriseSustainabilityStage &&
      filterData.enterpriseSustainabilityStages.length
    )
      query += `&enterpriseSustainabilityStages=${filterData.enterpriseSustainabilityStages}`;
    if (
      filterData.disqualified &&
      filterData.disqualificationReasons &&
      filterData.disqualificationReasons.length
    )
      query += `&disqualificationReasons=${filterData.disqualificationReasons.join(
        "|"
      )}`;
    if (
      filterData.tentativeHorizonToCloseThis !== "" &&
      filterData.tentativeHorizonToCloseThis !== undefined
    )
      query += `&tentativeHorizonToCloseThis=${filterData.tentativeHorizonToCloseThis}`;
    if (filterData.maturityStatus.length && filterData.maturityStatus[0])
      query += `&maturityStatus=${filterData.maturityStatus.join(",")}`;
    if (
      filterData.capitalFulfilledStatus &&
      filterData.capitalFulfilledStatus.length &&
      filterData.capitalFulfilledStatus[0]
    )
      query += `&capitalFulfilledStatus=${filterData.capitalFulfilledStatus.join(
        ","
      )}`;
    if (type === "sort" && sortBy && sortOrder)
      query += `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
    if (filterData.userType) query += `&userType=${filterData.userType}`;
    if (filterData.assignedTo) query += `&assignedTo=${filterData.assignedTo}`;
    if (filterData.taggedTo) query += `&taggedTo=${filterData.taggedTo}`;
    if (filterData.minLoanAmount)
      query += `&minLoanAmount=${filterData.minLoanAmount}`;
    if (filterData.maxLoanAmount)
      query += `&maxLoanAmount=${filterData.maxLoanAmount}`;

    // infographic filters
    if (filterData.bPlanCompleted)
      query += `&bPlanCompleted=${filterData.bPlanCompleted}`;
    if (filterData.loanAppApplied)
      query += `&loanAppApplied=${filterData.loanAppApplied}`;
    if (filterData.eSetupInProgress)
      query += `&eSetupInProgress=${filterData.eSetupInProgress}`;
    if (filterData.bplanAge)
      query += `&bplanAge=${filterData.bplanAge}`;
    if (filterData.sidbiMilestone)
      query += `&milestone=${filterData.sidbiMilestone}`;

    if (
      type !== "page" &&
      type !== "sizePerPage" &&
      type !== "searchText" &&
      type !== "sort"
    )
    this.showFilter();
    this.setState({selectAllLeadsQuery: query})
    this.getAllLeads(query);
  };

  changeStateFilter = (e) => {
    let filterData = this.state.filterData;
    filterData["state"] = e;
    if (e) {
      filterData["district"] = [];
      fetch(
        process.env.REACT_APP_API_URL +
        "/api/v1/location/get-address?stateId=" +
        e,
        {
          method: "GET",
          headers: {
            "x-auth-token": JSON.parse(readCookie("access_token")),
          },
        }
      )
        .then((data) => data.json())
        .then((data) => {
          if (data.status) {
            this.setState({ allDistricts: data.districts });
          }
        });
    } else {
      fetch(
        process.env.REACT_APP_API_URL +
        "/api/v1/location/districts?sortBy=name",
        {
          method: "GET",
          headers: {
            "x-auth-token": JSON.parse(readCookie("access_token")),
          },
        }
      )
        .then((data) => data.json())
        .then((data) => {
          if (data.status) {
            this.setState({ allDistricts: data.districts });
          }
        });
    }
    sessionStorage.setItem("getAllLeadsFilter", JSON.stringify(filterData));
    this.createBusinessIdeasQuery(filterData);
    this.setState({ filterData }, () => {
      this.submitFilter("searchText");
    });
  };

  changeFilterHandler = async (type, e) => {
    let filterData = this.state.filterData,
      value = "";
    if (e && e.hasOwnProperty("target")) value = e.target.value;
    else value = e;
    if (type === "fromToDate") {
      filterData["fromDate"] = e[0];
      filterData["toDate"] = e[1];
    } else if (type === "loanSchemes") {
      filterData["loanSchemeId"] = e;
      if (e && e.length) {
        filterData["loanScheme"] = this.state.allLoanSchemes.filter(
          (scheme) => e.indexOf(scheme._id) > -1
        );
      } else {
        filterData["loanScheme"] = [];
      }
    } else if (
      type === "disqualified" ||
      type === "starred" ||
      type === "onHold" ||
      type === "profilingStage" ||
      type === "dueDiligenceStage" ||
      type === "businessPlanStage" ||
      type === "capitalFulfilledStage" ||
      type === "enterpriseSetupStage" ||
      type === "enterpriseSustainabilityStage"
    ) {
      if (type === "profilingStage") {
        if (!filterData[type]) {
          filterData["profilingStages"] = ["Basic", "Advanced"];
        } else {
          filterData["profilingStages"] = [];
        }
      } else if (type === "dueDiligenceStage") {
        if (!filterData[type]) {
          filterData["dueDiligenceStages"] = ["Not Started", "Pending", "Hold"];
          filterData["ddOnHoldReasons"] = JSON.parse(
            JSON.stringify(this.state.ddOnHoldReasons)
          );
        } else {
          filterData["dueDiligenceStages"] = [];
          filterData["ddOnHoldReasons"] = [];
        }
      } else if (type === "businessPlanStage") {
        if (!filterData[type]) {
          filterData["businessPlanStages"] = ["Not Started", "Pending", "Hold"];
          filterData["bpOnHoldReasons"] = JSON.parse(
            JSON.stringify(this.state.bpOnHoldReasons)
          );
        } else {
          filterData["businessPlanStages"] = [];
          filterData["bpOnHoldReasons"] = [];
        }
      } else if (type === "capitalFulfilledStage") {
        if (!filterData[type]) {
          filterData["capitalFulfilledStages"] = [
            "Not Started",
            "Sanctioned",
            "Applied",
            "Rejected",
          ]; // 'Disbursed', 'Not required'
        } else {
          filterData["capitalFulfilledStages"] = [];
        }
      } else if (type === "enterpriseSetupStage") {
        if (!filterData[type]) {
          filterData["enterpriseSetupStages"] = [
            "Not Started",
            "Started up",
            "Scaled up",
          ];
        } else {
          filterData["enterpriseSetupStages"] = [];
        }
      } else if (type === "enterpriseSustainabilityStage") {
        if (!filterData[type]) {
          filterData["enterpriseSustainabilityStages"] = [
            "Not Started",
            "Operational",
            "Not Operational",
            "Halted",
            "Pivoted",
          ];
        } else {
          filterData["enterpriseSustainabilityStages"] = [];
        }
      } else if (type === "disqualified") {
        if (!filterData[type])
          filterData["disqualificationReasons"] = JSON.parse(
            JSON.stringify(this.state.disqualificationReasons)
          );
        else filterData["disqualificationReasons"] = [];
      }
      filterData[type] = !filterData[type];
    } else if (
      type === "district" ||
      type === "maturityStatus" ||
      type === "capitalFulfilledStatus"
    ) {
      if (value.indexOf("") > -1) filterData[type] = [""];
      else filterData[type] = value;
    } else if (type === "cluster") {
      for (let i = 0; i < this.state.swavalambanFellows.length; i++) {
        if (value.includes(this.state.swavalambanFellows[i]._id)) {
          fetch(
            process.env.REACT_APP_API_URL +
            "/api/v1/location/get-address?stateId=" +
            this.state.swavalambanFellows[i].state[0],
            {
              method: "GET",
              headers: {
                "x-auth-token": JSON.parse(readCookie("access_token")),
              },
            }
          )
            .then((data) => data.json())
            .then((data) => {
              if (data.status) {
                filterData["cluster"] = value;
                this.setState({ allDistricts: data.districts });
                filterData["state"] = this.state.swavalambanFellows[i].state[0];
                filterData["district"] =
                  this.state.swavalambanFellows[i].district;
                sessionStorage.setItem(
                  "getAllLeadsFilter",
                  JSON.stringify(filterData)
                );
                this.createBusinessIdeasQuery(filterData);
                this.setState({ filterData }, () => {
                  this.submitFilter("searchText");
                });
              }
            });
        }
      }
    } else if (type === "userType" && value === "swavalamban-connect-kendra") {
      filterData["assignedTo"] = "";
      filterData["userType"] = value;
      fetch(process.env.REACT_APP_API_URL + "/api/v1/ngo", {
        method: "GET",
        headers: {
          "x-auth-token": JSON.parse(readCookie("access_token")),
        },
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.status) {
            this.setState({ allAssignedTo: data.ngos });
            sessionStorage.setItem(
              "getAllLeadsFilter",
              JSON.stringify(filterData)
            );
            this.createBusinessIdeasQuery(filterData);
            this.setState({ filterData }, () => {
              this.submitFilter("searchText");
            });
          }
        });
    } else if (type === "userType" && value) {
      filterData["assignedTo"] = "";
      filterData["userType"] = value;
      if (value !== "Unassigned") {
        fetch(process.env.REACT_APP_API_URL + "/api/v1/user/users/" + value, {
          method: "GET",
          headers: {
            "x-auth-token": JSON.parse(readCookie("access_token")),
          },
        })
          .then((data) => data.json())
          .then((data) => {
            if (data.status) {
              this.setState({ allAssignedTo: data.users });
              sessionStorage.setItem(
                "getAllLeadsFilter",
                JSON.stringify(filterData)
              );
              this.createBusinessIdeasQuery(filterData);
              this.setState({ filterData }, () => {
                this.submitFilter("searchText");
              });
            }
          });
      } else {
        sessionStorage.setItem("getAllLeadsFilter", JSON.stringify(filterData));
        this.createBusinessIdeasQuery(filterData);
        this.setState({ filterData }, () => {
          this.submitFilter("searchText");
        });
      }
    } else if (type === "userType" && !value) {
      filterData["userType"] = "";
      filterData["assignedTo"] = "";
      sessionStorage.setItem("getAllLeadsFilter", JSON.stringify(filterData));
      this.createBusinessIdeasQuery(filterData);
      this.setState({ filterData }, () => {
        this.submitFilter("searchText");
      });
    }
    // follow up filters
    else if (type === "followUp") {
      var followUpFilters = { ...this.state.followUpFilters };
      followUpFilters[e.target.name] = !followUpFilters[e.target.name];
      this.setState({ followUpFilters });
    } else {
      filterData[type] = value;
    }

    if (type !== "cluster" && type !== "userType") {
      sessionStorage.setItem("getAllLeadsFilter", JSON.stringify(filterData));
      this.createBusinessIdeasQuery(filterData);
      this.setState({ filterData }, () => {
        this.submitFilter("searchText");
      });
    }
  };

  changeSubFilterHandler = (type, value) => {
    let filterData = this.state.filterData;

    if (filterData[type].indexOf(value) > -1) {
      filterData[type].splice(filterData[type].indexOf(value), 1);
      if (
        type === "ddOnHoldReasons" &&
        !filterData.ddOnHoldReasons.length &&
        filterData.dueDiligenceStages.indexOf("Hold") > -1
      )
        filterData.dueDiligenceStages.splice(
          filterData.dueDiligenceStages.indexOf("Hold"),
          1
        );
      else if (
        type === "bpOnHoldReasons" &&
        !filterData.bpOnHoldReasons.length &&
        filterData.businessPlanStages.indexOf("Hold") > -1
      )
        filterData.businessPlanStages.splice(
          filterData.businessPlanStages.indexOf("Hold"),
          1
        );
      else if (type === "dueDiligenceStages" && value === "Hold")
        filterData["ddOnHoldReasons"] = [];
      else if (type === "businessPlanStages" && value === "Hold")
        filterData["bpOnHoldReasons"] = [];
      if (!filterData.profilingStages || !filterData.profilingStages.length)
        filterData["profilingStage"] = false;
      if (
        !filterData.dueDiligenceStages ||
        !filterData.dueDiligenceStages.length
      )
        filterData["dueDiligenceStage"] = false;
      if (
        !filterData.businessPlanStages ||
        !filterData.businessPlanStages.length
      )
        filterData["businessPlanStage"] = false;
      if (
        !filterData.capitalFulfilledStages ||
        !filterData.capitalFulfilledStages.length
      )
        filterData["capitalFulfilledStage"] = false;
      if (
        !filterData.enterpriseSetupStages ||
        !filterData.enterpriseSetupStages.length
      )
        filterData["enterpriseSetupStage"] = false;
      if (
        !filterData.enterpriseSustainabilityStages ||
        !filterData.enterpriseSustainabilityStages.length
      )
        filterData["enterpriseSustainabilityStage"] = false;
    } else {
      filterData[type].push(value);
      if (
        type === "ddOnHoldReasons" &&
        filterData.dueDiligenceStages.indexOf("Hold") === -1
      )
        filterData.dueDiligenceStages.push("Hold");
      else if (
        type === "bpOnHoldReasons" &&
        filterData.businessPlanStages.indexOf("Hold") === -1
      )
        filterData.businessPlanStages.push("Hold");
      else if (type === "dueDiligenceStages") {
        if (filterData.dueDiligenceStages.indexOf("Hold") === -1)
          filterData["ddOnHoldReasons"] = [];
        else if (value === "Hold")
          filterData["ddOnHoldReasons"] = JSON.parse(
            JSON.stringify(this.state.ddOnHoldReasons)
          );
      } else if (type === "businessPlanStages") {
        if (filterData.businessPlanStages.indexOf("Hold") === -1)
          filterData["bpOnHoldReasons"] = [];
        else if (value === "Hold")
          filterData["bpOnHoldReasons"] = JSON.parse(
            JSON.stringify(this.state.bpOnHoldReasons)
          );
      } else if (
        type === "disqualificationReasons" &&
        !filterData.disqualificationReasons.length
      )
        filterData["disqualified"] = false;
    }

    sessionStorage.setItem("getAllLeadsFilter", JSON.stringify(filterData));
    this.createBusinessIdeasQuery(filterData);
    this.setState({ filterData }, () => {
      this.submitFilter("searchText");
    });
  };

  selectOnHoldReasons = (type, value) => {
    let filterData = this.state.filterData;
    if (type === "dueDiligenceStage") {
      if (value) {
        if (filterData.dueDiligenceStages.indexOf("Hold") === -1)
          filterData.dueDiligenceStages.push("Hold");
        filterData.ddOnHoldReasons = this.state.ddOnHoldReasons;
      } else {
        if (filterData.dueDiligenceStages.indexOf("Hold") > -1)
          filterData.dueDiligenceStages.splice(
            filterData.dueDiligenceStages.indexOf("Hold"),
            1
          );
        filterData.ddOnHoldReasons = [];
      }
    } else if (type === "businessPlanStage") {
      if (value) {
        if (filterData.businessPlanStages.indexOf("Hold") === -1)
          filterData.businessPlanStages.push("Hold");
        filterData.bpOnHoldReasons = this.state.bpOnHoldReasons;
      } else {
        if (filterData.businessPlanStages.indexOf("Hold") > -1)
          filterData.businessPlanStages.splice(
            filterData.businessPlanStages.indexOf("Hold"),
            1
          );
        filterData.bpOnHoldReasons = [];
      }
    }

    sessionStorage.setItem("getAllLeadsFilter", JSON.stringify(filterData));
    this.createBusinessIdeasQuery(filterData);
    this.setState({ filterData }, () => {
      this.submitFilter("searchText");
    });
  };

  createBusinessIdeasQuery = (filterData) => {
    let businessIdeasQuery = "?";
    // if (filterData.loanSchemeId)
    //   businessIdeasQuery += `loanSchemeId=${filterData.loanSchemeId}&`;

    if (filterData.pwd) businessIdeasQuery += "pwd=" + filterData.pwd;
    if (
      filterData.loanSchemeId &&
      filterData.loanSchemeId.length &&
      filterData.loanSchemeId[0]
    )
      businessIdeasQuery +=
        "&loanSchemeId=" + filterData.loanSchemeId.join(",");
    if (filterData.state)
      businessIdeasQuery += "&state=" + filterData.state;
    if (filterData.district.length && filterData.district[0])
      businessIdeasQuery += "&district=" + filterData.district.join(",");
    if (filterData.phase) businessIdeasQuery += `&phase=${filterData.phase}`;
    if (filterData.audit) businessIdeasQuery += `&audit=${filterData.audit}`;
    if (filterData.entrepreneurStoryVerification)
      businessIdeasQuery += `&entrepreneurStoryVerification=${filterData.entrepreneurStoryVerification}`;
    if (filterData.gender) businessIdeasQuery += `&gender=${filterData.gender}`;
    if (filterData.category)
      businessIdeasQuery += `&category=${filterData.category}`;
    if (filterData.collateral)
      businessIdeasQuery += "&collateral=" + filterData.collateral;
    if (filterData.toDate)
      businessIdeasQuery += `&toDate=${moment(filterData.toDate).format(
        "DD-MM-YYYY"
      )}`;
    if (filterData.fromDate)
      businessIdeasQuery += `&fromDate=${moment(filterData.fromDate).format(
        "DD-MM-YYYY"
      )}`;
    if (filterData.financeType !== "")
      businessIdeasQuery += `&financeType=${encodeURIComponent(
        filterData.financeType
      )}`;
    if (filterData.overallSentiment !== "")
      businessIdeasQuery += `&overallSentiment=${filterData.overallSentiment}`;
    if (filterData.strengthRating !== "")
      businessIdeasQuery += `&strengthRating=${filterData.strengthRating}`;
    if (filterData.filedIncomeTax !== "")
      businessIdeasQuery += `&filedIncomeTax=${filterData.filedIncomeTax}`;
    if (filterData.modeOfApplication !== "")
      businessIdeasQuery += `&modeOfApplication=${filterData.modeOfApplication}`;
    if (filterData.disqualified !== "")
      businessIdeasQuery += `&disqualified=${filterData.disqualified}`;
    if (filterData.starred !== "")
      businessIdeasQuery += `&starred=${filterData.starred}`;
    if (filterData.onHold !== "")
      businessIdeasQuery += `&onHold=${filterData.onHold}`;
    if (filterData.profilingStage && filterData.profilingStages.length)
      businessIdeasQuery += `&profilingStages=${filterData.profilingStages}`;
    if (filterData.dueDiligenceStage && filterData.dueDiligenceStages.length)
      businessIdeasQuery += `&dueDiligenceStages=${filterData.dueDiligenceStages}`;
    if (filterData.businessPlanStage && filterData.businessPlanStages.length)
      businessIdeasQuery += `&businessPlanStages=${filterData.businessPlanStages}`;
    if (
      filterData.capitalFulfilledStage &&
      filterData.capitalFulfilledStages.length
    )
      businessIdeasQuery += `&capitalFulfilledStages=${filterData.capitalFulfilledStages}`;
    if (
      filterData.enterpriseSetupStage &&
      filterData.enterpriseSetupStages.length
    )
      businessIdeasQuery += `&enterpriseSetupStages=${filterData.enterpriseSetupStages}`;
    if (
      filterData.dueDiligenceStages &&
      filterData.dueDiligenceStages.indexOf("Hold") > -1 &&
      filterData.ddOnHoldReasons &&
      filterData.ddOnHoldReasons.length
    )
      businessIdeasQuery += `&ddOnHoldReasons=${filterData.ddOnHoldReasons.join(
        "|"
      )}`;
    if (
      filterData.businessPlanStages &&
      filterData.businessPlanStages.indexOf("Hold") > -1 &&
      filterData.bpOnHoldReasons &&
      filterData.bpOnHoldReasons.length
    )
      businessIdeasQuery += `&bpOnHoldReasons=${filterData.bpOnHoldReasons.join(
        "|"
      )}`;
    if (
      filterData.enterpriseSustainabilityStage &&
      filterData.enterpriseSustainabilityStages.length
    )
      businessIdeasQuery += `&enterpriseSustainabilityStages=${filterData.enterpriseSustainabilityStages}`;
    if (
      filterData.disqualified &&
      filterData.disqualificationReasons &&
      filterData.disqualificationReasons.length
    )
      businessIdeasQuery += `&disqualificationReasons=${filterData.disqualificationReasons.join(
        "|"
      )}`;
    if (filterData.tentativeHorizonToCloseThis)
      businessIdeasQuery += `&tentativeHorizonToCloseThis=${filterData.tentativeHorizonToCloseThis}`;
    if (filterData.maturityStatus.length && filterData.maturityStatus[0])
      businessIdeasQuery += `&maturityStatus=${filterData.maturityStatus.join(
        ","
      )}`;
    if (
      filterData.capitalFulfilledStatus &&
      filterData.capitalFulfilledStatus.length &&
      filterData.capitalFulfilledStatus[0]
    )
      businessIdeasQuery += `&capitalFulfilledStatus=${filterData.capitalFulfilledStatus.join(
        ","
      )}`;
    if (filterData.source !== "") businessIdeasQuery += `&source=${filterData.source}`;

    if (businessIdeasQuery === "?") businessIdeasQuery = "";

    this.getAllBusinessIdeas(businessIdeasQuery, filterData.idea);
  };

  addressFormatter = (cell, row) => {
    if (cell) return cell;
    else return null;
  };

  nameFormatter = (cell, row) => {
    if (cell) {
      // console.log(row);
      if (row.id) {
        return (
          <a
            href={"/lead/" + (row.leadId || row.id)}
            target="_blank"
            rel="noopener noreferrer"
            style={row.enterpriseSetup ? { color: "#fff" } : null}
          >
            {cell}{" "}
            {(JSON.parse(readCookie("userData")).type !== "sidbi" ) ? (row.audit !== undefined && row.audit.status === "Audited" ? (
              <img src="/images/check.png" width="12" alt="" />
            ) : row.audit !== undefined &&
              row.audit.status === "Audit Failed" ? (
              <img src="/images/cancel.png" width="12" alt="" />
            ) : null): null}
            {(JSON.parse(readCookie("userData")).type !== "sidbi" ) ? (
              row?.paymentClaim !== undefined &&
                [
                  // 'In-house-intervention-Cannot be claimed',
                  // 'Unclaimed',
                  // 'Claimed but Audit Failed',
                  // 'Claimed and Payment Pending',
                  "Claimed and Settled",
                ].indexOf(row?.paymentClaim?.status) > -1 ? (
                // <span className="fa-stack">
                //   <i className="fa fa-circle fa-stack-1x"/>
                //   <i className="fa fa-inr fa-stack-1x fa-inverse" aria-hidden="true"></i>
                // </span>
                <span title="Payment Claimed">
                  <Badge
                    bg="dark"
                    style={{
                      backgroundColor: "#167b13",
                      fontSize: "9px",
                      marginLeft: "1px",
                    }}
                  >
                    <i className="fa fa-tag" aria-hidden="true" /> Claimed
                  </Badge>
                </span>
              ) : // null
                ["Enterprise Setup"].includes(row?.status) &&
                  row?.audit?.status === "Audited" ? (
                  ["In-house-intervention-Cannot be claimed"].includes(
                    row?.paymentClaim?.status
                  ) ? (
                    <span title="Can't claim this lead!">
                      <Badge
                        bg="dark"
                        style={{
                          backgroundColor: "#ff0000",
                          fontSize: "9px",
                          marginLeft: "1px",
                        }}
                      >
                        <i
                          className="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        />{" "}
                        Can't be claimed!
                      </Badge>
                    </span>
                  ) : (
                    <span title="Enterprise set-up, audit successful and ready for claim">
                      <Badge
                        bg="dark"
                        style={{
                          backgroundColor: "#167b13",
                          fontSize: "9px",
                          marginLeft: "1px",
                        }}
                      >
                        <i className="fa fa-tag" aria-hidden="true" /> Ready for Claim
                      </Badge>
                    </span>
                  )
                ) : null
            ) : null}
          </a>
        );
      } else return cell;
    } else return null;
  };

  selectFormatter = (cell, row, index) => {
    const addToSelcted = (e) => {
      let addSelected = this.state.selectedLeads;
      let addSelectedIds = this.state.selectedLeadsIds;
      // if (addSelected.some(item => item.id === row._id)) {
        //     const removeIndex = addSelected.map(item => item.id).indexOf(row._id);
        //     removeIndex >= 0 && addSelected.splice(removeIndex, 1);
        // }
        if (!e.target.checked) {
          const removeIndex = addSelected.map(item => item.id).indexOf(row._id);
          removeIndex >= 0 && addSelected.splice(removeIndex, 1);
          addSelectedIds = addSelected.map(x => x._id);
        }
        else {
        const newItem = {name: row.name, mobileNumber: row.mobileNumber, id: row._id, _id: row._id};
        addSelected.push(newItem)
          addSelectedIds = addSelected.map(x => x._id);

      }
      
      this.setState({selectedLeads: addSelected, selectedLeadsIds: addSelectedIds})

    }
    return (
            <Checkbox id={row._id}
              // defaultChecked={this.state.allLeadsSelected}
              checked={this.state.allLeadsSelected || this.state.selectedLeadsIds.includes(row._id)}
              // defaultChecked={this.state.allLeadsSelected || this.state.selectedLeadsIds.includes(row._id)}
              onChange={(e) => addToSelcted(e)} />
    )
  }

  starFormatter = (cell, row, index) => {
    if (cell) {
      if (row.enterpriseSetup)
        return (
          <i
            className="fa fa-star"
            style={{ fontSize: 20, color: "#fff" }}
            onClick={this.toggleStar.bind(this, row.id, !cell, index)}
          ></i>
        );
      else
        return (
          <i
            className="fa fa-star"
            style={{ fontSize: 20, color: "var(--themeColor)" }}
            onClick={this.toggleStar.bind(this, row.id, !cell, index)}
          ></i>
        );
    } else {
      return (
        <i
          className="fa fa-star-o"
          style={{ fontSize: 20 }}
          onClick={this.toggleStar.bind(this, row.id, !cell, index)}
        ></i>
      );
    }
  };

  addedByFormatter = (cell, row) => {
    if (!cell.id && row.generatedThrough === 'Learning App') {
      return 'Self'
    } else {
      return cell.fullName
    }
  }

  assignToFormatter = (cell, row) => {
    if (cell && cell.hasOwnProperty("_id")) {
      if (cell && cell.fullName) {
        if (
          row.userType &&
          (JSON.parse(readCookie("userData")).type === "le" ||
            JSON.parse(readCookie("userData")).type === "sidbi")
        ) {
          return (
            <a
              href={"/user-types/" + row.userType + "/" + cell._id}
              style={row.enterpriseSetup ? { color: "#fff" } : null}
            >
              {cell.fullName}
            </a>
          );
        } else return cell.fullName;
      } else if (cell && cell.ngoName) {
        return (
          <a
            href={
              JSON.parse(readCookie("userData")).type === "partners"
                ? "/settings"
                : "/org-types/swavalamban-connect-kendra/" + cell._id
            }
            style={row.enterpriseSetup ? { color: "#fff" } : null}
          >
            {cell.ngoName}
          </a>
        );
      } else if (cell && cell.udaanName) {
        return (
          <a
            href={
              JSON.parse(readCookie("userData")).type === "partners"
                ? "/settings"
                : "/udaan-centres/udaan/" + cell._id
            }
            style={row.enterpriseSetup ? { color: "#fff" } : null}
          >
            {cell.udaanName}
          </a>
        );
      } else return null;
    } else return null;
  };

  yesnoFormatter = (cell, row) => {
    if (cell) return "Yes";
    else if (cell === false) return "No";
    else return null;
  };
  localeStringFormatter = (cell) => {
    if (cell && cell !== "N/A")
      return <span>{parseFloat(cell).toLocaleString()}</span>;
    else if (cell === "N/A") return <span>{cell}</span>;
    else return null;
  };
  formatCreatedAt = (cell, row) => {
    if (cell) {
      return moment(cell).format("DD/MM/YYYY hh:mm A");
    } else return <span>-</span>;
  };
  formatBplanAge = (cell, row) => {
    if (cell) {
      return <span>{isNaN(cell) ? "-" : Math.floor(cell)}</span>;
    }
  }

  formatSaarthis = (cell, row) => {
    if (cell) {
      return (
        <span>
          <a
            className="saarthi-name"
            href={"/user-types/saarthis/" + cell._id}
            target="_blank"
            rel="noopener noreferrer"
          >
            {cell.fullName}
          </a>
        </span>
      );
    } else return "N/A";
  };

  formatEdit = (cell, row) => {
    if (row.id && JSON.parse(readCookie("userData")).type !== "sidbi") {
      return (
        <button
          className="btn edit-lead-btn"
          onClick={() => (window.location.pathname = "/edit-lead/" + row.id)}
        >
          Edit
        </button>
      );
    } else return null;
  };

  formatRating = (cell, row) => {
    if (cell) {
      return (
        <span className="note" style={{ fontSize: 18 }}>
          {cell}
        </span>
      );
    } else return null;
  };

  toggleStar = (leadId, starred, index) => {
    let title = "Are you sure you want to prioritize this lead?";
    if (!starred) title = "Are you sure you want to deprioritize this lead?";
    Swal.fire({
      title,
      focusConfirm: false,
      showCancelButton: true,
      cancelButtonText: "No, Cancel",
      confirmButtonText: "Yes, Update",
      cancelButtonAriaLabel: "No, Cancel",
      confirmButtonAriaLabel: "Yes, Update",
    }).then((result) => {
      if (result.value && leadId) {
        fetch(process.env.REACT_APP_API_URL + "/api/v1/swati-leads/" + leadId, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": JSON.parse(readCookie("access_token")),
          },
          body: JSON.stringify({ leadInfo: { starred } }),
        })
          .then((data) => data.json())
          .then((data) => {
            if (data.status) {
              let allLeads = this.state.allLeads;
              allLeads[index]["starred"] = starred;
              this.setState({ allLeads });
            } else {
              this.notifyError("Could not update");
            }
          });
      }
    });
  };

  addNewLead = () => {
    window.location.pathname = "/leads/new";
  };

  importLeadsFromCSV = () => {
    this.setState({ showImportModal: true, importStage: "" });
  };

  searchFilter = (input, option) => {
    input = input.toLowerCase();
    if (!Array.isArray(option.props.children)) {
      if (typeof option.props.children === "string") {
        return (
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      }
    }
  };

  showFilter = () => {
    this.setState({ showFilter: !this.state.showFilter }, () => {
      if (this.state.showFilter) {
        $(".filter-container").css("display", "block");
      } else {
        $(".filter-container").css("display", "none");
      }
    });
  };

  onTableChange = (type, obj) => {
    if (type === "sort" && obj.sortOrder && obj.sortField) {
      this.submitFilter(
        type,
        obj.page,
        obj.sizePerPage,
        obj.searchText,
        obj.sortField,
        obj.sortOrder
      );
    } else {
      let changeType = "";
      if (this.state.page !== obj.page) changeType = "page";
      else if (this.state.sizePerPage !== obj.sizePerPage)
        changeType = "sizePerPage";
      else if (this.state.searchText !== obj.searchText)
        changeType = "searchText";

      this.setState({
        page: obj.page,
        sizePerPage: obj.sizePerPage,
        searchText: obj.searchText,
      });
      this.submitFilter(changeType, obj.page, obj.sizePerPage, obj.searchText);
    }
  };
  
  rowClassNameFormat = (row, index) => {
    // coloring setup enterprises
    if (row.enterpriseSetup) {
      return { backgroundColor: "#78D245" };
    } else if (row.saarthis && row.saarthis.status === "Active") {
      if (row.saarthis.saarthiAccess === "dueDiligence")
        return { backgroundColor: "#FFE8FE" };
      else return { backgroundColor: "#D4FFFF" };
    }
    // coloring for follow-ups
    if (this.state.followUpDues) {
      if (
        this.state.followUpDues.dueToday &&
        this.state.followUpDues.dueToday.indexOf(row.id) > -1
      )
        return { backgroundColor: "rgb(250, 236, 47,0.8)" };
      if (
        this.state.followUpDues.overDue &&
        this.state.followUpDues.overDue.indexOf(row.id) > -1
      )
        return { backgroundColor: "rgb(166, 0, 0, 0.2)" };
      if (
        this.state.followUpDues.dueThisWeek &&
        this.state.followUpDues.dueThisWeek.indexOf(row.id) > -1
      )
        return { backgroundColor: "rgba(73, 154, 255, 0.75)" };
    }
  };

  starredStyle = (cell, row) => {
    if (cell) return { backgroundColor: "#FFF2C9" };
  };
  removeFilter = (type, value) => {
    let filterData = this.state.filterData;
    if (type === "state" || type === "cluster") {
      fetch(
        process.env.REACT_APP_API_URL +
        "/api/v1/location/districts?sortBy=name",
        {
          method: "GET",
          headers: {
            "x-auth-token": JSON.parse(readCookie("access_token")),
          },
        }
      )
        .then((data) => data.json())
        .then((data) => {
          if (data.status) {
            this.setState({ allDistricts: data.districts });
          }
        });
    }

    if (type === "cluster") {
      filterData["state"] = "";
      filterData["district"] = [];
    } else if (type === "userType") {
      filterData["assignedTo"] = "";
    } else if (type === "loanSchemes") {
      filterData["loanSchemeId"] = [];
      filterData["loanScheme"] = [];
    }
    filterData[type] = value;
    sessionStorage.setItem("getAllLeadsFilter", JSON.stringify(filterData));
    this.createBusinessIdeasQuery(filterData);
    this.setState({ filterData }, () => {
      this.submitFilter("searchText");
    });
  };
  handleSelectChange = (row) => {
    let selectedLeadsIds = this.state.selectedLeadsIds;
    if (!selectedLeadsIds.includes(row._id)) {
      selectedLeadsIds.push(row._id);
    } else {
      const removeIndex = selectedLeadsIds.map(item => item._id).indexOf(row._id);
      removeIndex >= 0 && selectedLeadsIds.splice(removeIndex, 1);
    }
    this.setState({ selectedLeadsIds });

  }
  handleSelectAllChange = (selected) => {
    if (selected) {
      this.setState({ selectedLeadsIds: (this.state.selectAllLeadsInfo || []) })
    } else {
      this.setState({ selectedLeadsIds: [] })
    }
  }
  render() {

    const SELECTED_ESS = this.state.filterData.essOptedFor;
    const selectedESSArr = SELECTED_ESS ? this.state.allSupportServices.filter(item => item._id === SELECTED_ESS): [];
    const selectedEssString = selectedESSArr && selectedESSArr.length ? selectedESSArr[0].serviceDetails.serviceTitle : '';
    const selectRow = {
      mode: 'checkbox',
      clickToSelect: JSON.parse(readCookie('userData')).userType.name === "Project Management Unit" && this.state.selectMode,
      selected: this.state.selectedLeadsIds,
      onSelect: this.handleSelectChange,
      onSelectAll: this.handleSelectAllChange,
      hideSelectColumn: !(JSON.parse(readCookie('userData')).userType.name === "Project Management Unit" && this.state.selectMode)
    };
    const allSentiments = [
      "Very enthusiastic",
      "Enthusiastic",
      "Exploring enterprise options in addition to the current engagement",
      "Not very enthusiastic",
    ],
      allHorizons = [
        "Under 1 month",
        "1-3 months",
        "3-6 months",
        "6-12 months",
        "12-24 months",
        ">24 months",
      ],
      //allBanks = allBanks,
      // [
      //   "//Allahabad Bank",
      //   "Andhra Bank",
      //   "Andhra Pradesh Grameena Vikas Bank",
      //   "Aryavart Bank",
      //   "Axis Bank",
      //   "Bandhan Bank",
      //   "Bank of Bahrain and Kuwait",
      //   "Bank of Baroda",
      //   "Bank of India",
      //   "Bank of Maharashtra",
      //   "Baroda Uttar Pradesh Gramin Bank",
      //   "Canara Bank",
      //   "Central Bank of India",
      //   "City Union Bank",
      //   "Corporation Bank",
      //   "Dakshin Bihar Gramin Bank",
      //   "Dena Bank",
      //   "Deutsche Bank",
      //   "Development Credit Bank",
      //   "Dhanlaxmi Bank",
      //   "Federal Bank",
      //   "Fino Payments Bank",
      //   "Gramin Bank",
      //   "HDFC Bank",
      //   "ICICI Bank",
      //   "IDBI Bank",
      //   "Indian Bank",
      //   "Indian Post Payments Bank",
      //   "Indian Overseas Bank",
      //   "IndusInd Bank",
      //   "ING Vysya Bank",
      //   "Jammu and Kashmir Bank",
      //   "Karnataka Bank Ltd",
      //   "Karur Vysya Bank",
      //   "Kashi Gomti Samyut Gramin Bank",
      //   "Kotak Bank",
      //   "Laxmi Vilas Bank",
      //   "Oriental Bank of Commerce",
      //   "Prathama Bank",
      //   "Punjab National Bank - Corporate Banking",
      //   "Punjab National Bank - Retail Banking",
      //   "Punjab &amp; Sind Bank",
      //   "RBL Bank",
      //   "Shamrao Vitthal Co-operative Bank",
      //   "South Indian Bank",
      //   "State Bank of Bikaner &amp; Jaipur",
      //   "State Bank of Hyderabad",
      //   "State Bank of India",
      //   "State Bank of Mysore",
      //   "State Bank of Patiala",
      //   "State Bank of Travancore",
      //   "Syndicate Bank",
      //   "Tamilnad Mercantile Bank Ltd.",
      //   "UCO Bank",
      //   "Union Bank of India",
      //   "United Bank of India",
      //   "Uttar Bihar Gramin Bank",
      //   "Vijaya Bank",
      //   "Yes Bank Ltd",
      // ],
      columns =
        window.screen.width < 500
          ? [
            {
              text: "Name",
              sort: true,
              hidden: false,
              export: true,
              searchable: true,
              headerStyle: () => {
                return { width: "200px" };
              },
              dataField: "userInfo.name",
              formatter: this.nameFormatter,
            },
            {
              text: "Mobile No.",
              sort: true,
              hidden: false,
              export: true,
              searchable: true,
              headerStyle: () => {
                return { width: "110px" };
              },
              dataField: "userInfo.mobileNumber",
            },
            {
              text: "District",
              sort: true,
              hidden: false,
              export: true,
              searchable: true,
              headerStyle: () => {
                return { width: "130px" };
              },
              dataField: "userInfo.address.district",
              formatter: this.addressFormatter,
            },
          ]
          : [
            {
              text: "",
              sort: false,
              hidden: true,
              export: true,
              searchable: false,
              dataField: "id",
            },
            // {
            //   text: <div> Select all <br/> 
            //           <div className="form-group-select">
            //       <Checkbox id='all'
            //         checked={this.state.allLeadsSelected}
            //         // onClick={(e) => this.selectAllLeads(e)} 
            //         onChange={(e) => this.selectAllLeads(e)} />
            //             <label htmlFor='all'></label>
            //           </div>
            //         </div>,
            //   hidden: !this.state.selectMode,
            //   export: false,
            //   searchable: false,
            //   align: "center",
            //   style: this.starredStyle,
            //   headerStyle: () => {
            //     return { width: "60px", textAlign: "center" };
            //   },
            //   // dataField: "select",
            //   formatter: this.selectFormatter,
            // },
            // {
            //   text: "Starred",
            //   sort: true,
            //   hidden: false,
            //   export: false,
            //   searchable: false,
            //   align: "center",
            //   style: this.starredStyle,
            //   headerStyle: () => {
            //     return { width: "60px", textAlign: "center" };
            //   },
            //   dataField: "starred",
            //   formatter: this.starFormatter,
            // },
            {
              text: "Name",
              sort: true,
              hidden: false,
              export: true,
              searchable: true,
              headerStyle: () => {
                return { width: "200px" };
              },
              dataField: "userInfo.name",
              formatter: this.nameFormatter,
            },
            {
              text: "Mobile No.",
              sort: true,
              hidden: false,
              export: true,
              searchable: true,
              headerStyle: () => {
                return { width: "110px" };
              },
              dataField: "userInfo.mobileNumber",
            },
            {
              text: "Profile(Survey)",
              sort: true,
              hidden: false,
              export: true,
              searchable: true,
              headerStyle: () => {
                return { width: "110px" };
              },
              dataField: "userInfo._id",
              formatter: (cell, row) => {
                if (cell) {
                  return (
                    <a
                      href={"/user-profile/" + cell}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View
                    </a>
                  );
                } else return null;
              },
            },
            {
              text: "State",
              sort: true,
              hidden: false,
              export: true,
              searchable: true,
              headerStyle: () => {
                return { width: "130px" };
              },
              dataField: "userInfo.address.state",
              formatter: this.addressFormatter,
            },
            {
              text: "District",
              sort: true,
              hidden: false,
              export: true,
              searchable: true,
              headerStyle: () => {
                return { width: "130px" };
              },
              dataField: "userInfo.address.district",
              formatter: this.addressFormatter,
            },
            // {
            //   text: "Block",
            //   sort: true,
            //   hidden: false,
            //   export: true,
            //   searchable: true,
            //   headerStyle: () => {
            //     return { width: "130px" };
            //   },
            //   dataField: "block",
            //   formatter: this.addressFormatter,
            // },
            // {
            //   text: "City",
            //   sort: true,
            //   hidden: false,
            //   export: true,
            //   searchable: true,
            //   headerStyle: () => {
            //     return { width: "130px" };
            //   },
            //   dataField: "city",
            //   formatter: this.addressFormatter,
            // },
            {
              text: "Pincode",
              sort: true,
              hidden: false,
              export: true,
              searchable: true,
              headerStyle: () => {
                return { width: "70px" };
              },
              dataField: "userInfo.address.pincode",
            },
            {
              text: "Added To Cluster",
              sort: false,
              hidden: false,
              export: true,
              searchable: true,
              headerStyle: () => {
                return { width: "150px" };
              },
              dataField: "userInfo.fieldViewData[0].cluster_id",
              formatter: (cell, row) => {
                if (cell) {
                  return (
                    <p style={{ color: "green" }}
                    >
                      Yes
                    </p>
                  );
                }
                else {
                  return (
                    <p style={{ color: "red" }}
                    >
                      No
                    </p>
                  );
                }
              },
            },
            {
              text: "Status",
              sort: true,
              hidden: true,
              export: true,
              searchable: true,
              headerStyle: () => {
                return { width: "150px" };
              },
              dataField: "userInfo.status",
            },
            // {
            //   text: "Generated Through",
            //   sort: true,
            //   hidden: false,
            //   export: true,
            //   searchable: true,
            //   headerStyle: () => {
            //     return { width: "130px" };
            //   },
            //   dataField: "generatedThrough",
            // },
            // {
            //   text: "Self Finance Amount (INR)",
            //   sort: true,
            //   hidden: false,
            //   export: true,
            //   searchable: true,
            //   headerStyle: () => {
            //     return { width: "130px" };
            //   },
            //   dataField: "canSelfFinanceTheCapitalRequiredUpToINR",
            //   formatter: this.localeStringFormatter,
            // },
            // {
            //   text: "Finance Type",
            //   sort: true,
            //   hidden: false,
            //   export: true,
            //   searchable: true,
            //   headerStyle: () => {
            //     return { width: "160px" };
            //   },
            //   dataField: "financeType",
            // },
            // {
            //   text: "Has Business Idea",
            //   sort: true,
            //   hidden: true,
            //   export: true,
            //   searchable: true,
            //   headerStyle: () => {
            //     return { width: "140px" };
            //   },
            //   dataField: "hasBusinessIdea",
            // },
            // {
            //   text: "Has Business Plan",
            //   sort: true,
            //   hidden: true,
            //   export: true,
            //   searchable: true,
            //   headerStyle: () => {
            //     return { width: "140px" };
            //   },
            //   dataField: "hasABusinessPlanAlready",
            //   formatter: this.yesnoFormatter,
            // },
            // {
            //   text: "Overall Sentiment",
            //   sort: true,
            //   hidden: true,
            //   export: true,
            //   searchable: true,
            //   headerStyle: () => {
            //     return { width: "170px" };
            //   },
            //   dataField: "overallSentiment",
            // },
            {
              text: "Created On",
              sort: true,
              hidden: JSON.parse(readCookie("userData")).userType
                .name === "SIDBI",
              // hidden: false,
              export: true,
              searchable: true,
              headerStyle: () => {
                return { width: "150px", textAlign: "center" };
              },
              dataField: "userInfo.addedAt",
              formatter: this.formatCreatedAt,
            },
            {
              text: "Last Updated At",
              sort: true,
              hidden: false,
              export: true,
              searchable: true,
              headerStyle: () => {
                return { width: "150px", textAlign: "center" };
              },
              dataField: "userInfo.updatedAt",
              formatter: this.formatCreatedAt,
            },
            // {
            //   text: "SIDBI Payment Milestone",
            //   sort: true,
            //   hidden: false,
            //   export: true,
            //   searchable: true,
            //   headerStyle: () => {
            //     return { width: "150px", textAlign: "center" };
            //   },
            //   dataField: "sidbiMilestone",
            // },
            // {
            //   text: "Due-diligence done at",
            //   sort: true,
            //   hidden: false,
            //   export: true,
            //   searchable: true,
            //   headerStyle: () => {
            //     return { width: "150px", textAlign: "center" };
            //   },
            //   dataField: "ddCompletedAt",
            //   formatter: this.formatCreatedAt,
            // },
            // {
            //   text: "Bplan done At",
            //   sort: true,
            //   hidden: false,
            //   export: true,
            //   searchable: true,
            //   headerStyle: () => {
            //     return { width: "150px", textAlign: "center" };
            //   },
            //   dataField: "bplanCompletedAt",
            //   formatter: this.formatCreatedAt,
            // },
            // {
            //   text: "Bplan Age (in days)",
            //   sort: true,
            //   hidden: false,
            //   export: true,
            //   searchable: true,
            //   headerStyle: () => {
            //     return { width: "50px", textAlign: "center" };
            //   },
            //   dataField: "bplanAge",
            //   formatter: this.formatBplanAge,
            // },
            // {
            //   text: "Loan Application Done At",
            //   sort: true,
            //   hidden: false,
            //   export: true,
            //   searchable: true,
            //   headerStyle: () => {
            //     return { width: "150px", textAlign: "center" };
            //   },
            //   dataField: "loanApplicationCompletedAt",
            //   formatter: this.formatCreatedAt,
            // },
            // {
            //   text: "Loan Application Status",
            //   sort: true,
            //   hidden: false,
            //   export: true,
            //   searchable: true,
            //   headerStyle: () => {
            //     return { width: "150px", textAlign: "center" };
            //   },
            //   dataField: "loanApplicationStatus",
            // },
            // {
            //   text: "Started/Scaled Up on(in)",
            //   sort: true,
            //   hidden: false,
            //   export: true,
            //   searchable: true,
            //   headerStyle: () => {
            //     return { width: "150px", textAlign: "center" };
            //   },
            //   dataField: "enterpriseSetUpAt",
            //   // formatter: this.formatCreatedAt,
            // },
            // {
            //   text: "Enterprise status last updated on",
            //   sort: true,
            //   hidden: false,
            //   export: true,
            //   searchable: true,
            //   headerStyle: () => {
            //     return { width: "150px", textAlign: "center" };
            //   },
            //   dataField: "enterpriseUpdatedOn",
            //   formatter: this.formatCreatedAt,
            // },
            // {
            //   text: "Enterprise marked started/scaled up on",
            //   sort: true,
            //   hidden: JSON.parse(readCookie("userData")).userType
            //     .name === "SIDBI",
            //   // hidden: false,
            //   export: true,
            //   searchable: true,
            //   headerStyle: () => {
            //     return { width: "150px", textAlign: "center" };
            //   },
            //   dataField: "enterpriseMarkedSetupOn",
            //   formatter: this.formatCreatedAt,
            // },
          ];
    if (window.screen.width > 500) {
      if (JSON.parse(readCookie("userData")).type === "le") {
        // columns.splice(7, 0, {
        //   text: "Profile Completed (%)",
        //   sort: true,
        //   hidden: false,
        //   export: true,
        //   searchable: true,
        //   headerStyle: () => {
        //     return { width: "90px" };
        //   },
        //   dataField: "profileCompletion",
        // });
        // columns.splice(8, 0, {
        //   text: "Strength Rating",
        //   sort: true,
        //   hidden: false,
        //   export: true,
        //   searchable: true,
        //   headerStyle: () => {
        //     return { width: "70px", textAlign: "center" };
        //   },
        //   align: "center",
        //   dataField: "strengthRating",
        //   formatter: this.formatRating,
        // });
        if (JSON.parse(readCookie("userData")).accountType !== "saarthi") {
          // columns.splice(15, 0, {
          //   text: "SAARTHIs",
          //   sort: true,
          //   hidden: true,
          //   export: true,
          //   searchable: true,
          //   headerStyle: () => {
          //     return { width: "160px" };
          //   },
          //   dataField: "saarthis",
          //   formatter: this.formatSaarthis,
          // });
        }
        // columns.splice(9, 0, { text: 'Edit', sort: false, hidden: false, export: false, searchable: false, align: 'center', headerStyle: () => {return {width: '75px', textAlign: 'center'}}, dataField: 'edit', formatter: this.formatEdit });
      } else {
        // columns.splice(14, 0, {
        //   text: "Profile Completed (%)",
        //   sort: true,
        //   hidden: false,
        //   export: true,
        //   searchable: true,
        //   headerStyle: () => {
        //     return { width: "150px" };
        //   },
        //   dataField: "profileCompletion",
        // });
        // columns.splice(19, 0, {
        //   text: "Strength Rating",
        //   sort: true,
        //   hidden: false,
        //   export: true,
        //   searchable: true,
        //   headerStyle: () => {
        //     return { width: "140px", textAlign: "center" };
        //   },
        //   align: "center",
        //   dataField: "strengthRating",
        //   formatter: this.formatRating,
        // });
        // if(JSON.parse(readCookie('userData')).type !== 'sidbi') {
        // 	columns.splice(7, 0, { text: 'Edit', sort: false, hidden: false, export: false, searchable: false, align: 'center', headerStyle: () => {return {width: '75px', textAlign: 'center'}}, dataField: 'edit', formatter: this.formatEdit });
        // }
      }
    }
    let hasFilter = false;
    if (
      this.state.filterData.bPlanCompleted === true ||
      this.state.filterData.source !== "" ||
      this.state.filterData.loanAppApplied === true ||
      this.state.filterData.eSetupInProgress === true ||
      this.state.filterData.fromDate ||
      this.state.filterData.toDate ||
      this.state.filterData.state ||
      (this.state.filterData.district &&
        this.state.filterData.district.length) ||
      (this.state.filterData.cluster && this.state.filterData.cluster.length) ||
      this.state.filterData.bplanAge || 
      this.state.filterData.minLoanAmount ||
      this.state.filterData.maxLoanAmount ||
      this.state.filterData.userType ||
      this.state.filterData.assignedTo ||
      this.state.filterData.taggedTo ||
      this.state.filterData.idea ||
      this.state.filterData.phase ||
      this.state.filterData.audit ||
      this.state.filterData.paymentClaim ||
      this.state.filterData.essOptedFor ||
      this.state.filterData.scaleUpQuestionnaire ||
      this.state.filterData.entrepreneurStoryVerification ||
      this.state.filterData.gender ||
      this.state.filterData.category ||
      this.state.filterData.financeType ||
      this.state.filterData.pwd ||
      this.state.filterData.hasComments ||
      this.state.filterData.businessPlanVerification ||
      this.state.filterData.collateral ||
      (this.state.filterData.bankAccounts &&
        this.state.filterData.bankAccounts.length) ||
      this.state.filterData.overallSentiment ||
      this.state.filterData.tentativeHorizonToCloseThis ||
      this.state.filterData.strengthRating ||
      this.state.filterData.filedIncomeTax ||
      this.state.filterData.modeOfApplication ||
      (this.state.filterData.loanSchemeId &&
        this.state.filterData.loanSchemeId.length) ||
      this.state.filterData.maturityStatus.length ||
      (this.state.filterData.capitalFulfilledStatus &&
        this.state.filterData.capitalFulfilledStatus.length) ||
      (!this.props.sidebarMinimized &&
        this.state.filterData.capitalFulfilledStage) || 
      this.state.filterData.sidbiMilestone
    )
      hasFilter = true;

    return (
      <div
        className={
          this.props.type === "swavalamban-connect-kendra" &&
            this.props.view === "tab"
            ? "leads-page"
            : this.props.sidebarMinimized &&
              window.location.pathname === "/leads"
              ? "page-container leads-page sidebar-minimized"
              : "page-container leads-page"
        }
      >
        {this.props.type !== "swavalamban-connect-kendra" &&
          this.props.view !== "tab" ? (
          <div className="page-heading d-flex">
            <h3>Leads</h3>
            {JSON.parse(readCookie("userData")).type !== "sidbi" &&
              JSON.parse(readCookie("userData")).accountType !== "saarthi" ? (
              <div
                className="filter add-new-prospect-container"
                style={{ right: "40px", position: "absolute" }}
              >
                <Row>
                  {JSON.parse(readCookie("userData")).userType.name ===
                    "Project Management Unit" ? (
                    // || true
                    <Col>
                      <button
                        // className="btn add-new-prospect-btn"
                        className="btn"
                        disabled
                        style={{ borderRadius: 0, width: "max-content" }}
                        onClick={this.importLeadsFromCSV}
                      >
                        <i className="fa fa-plus mr5" />
                        Import/Update Leads from a CSV/Excel file
                        <i className="fa fa-upload" aria-hidden="true" />
                      </button>
                    </Col>
                  ) : null}
                  <Col>
                    {/* <button
                      className="btn add-new-prospect-btn"
                      style={{ borderRadius: 0 }}
                      onClick={this.addNewLead}
                    >
                      <i className="fa fa-plus mr5"></i>
                      Add New Prospect
                    </button> */}
                  </Col>
                </Row>
              </div>
            ) : null}
          </div>
        ) : null}
        {this.props.type !== "swavalamban-connect-kendra" &&
          this.props.view !== "tab" &&
          this.props.sidebarMinimized ? (
          <Row className="leads-vertical-filter-container">
            {this.state.showFollowUpModule && this.state.followUpCounts && (
              <React.Fragment>
                <h2 style={{ alignSelf: "flex-start" }}>Lead Follow-up</h2>
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    type="checkbox"
                    id="vertical-overdue-checkbox"
                    name="overDue"
                    checked={this.state.followUpFilters.overDue}
                    onChange={this.changeFilterHandler.bind(this, "followUp")}
                  />
                  <label
                    htmlFor="vertical-overdue-checkbox"
                      style={{ backgroundColor: "rgb(166, 0, 0, 0.2)" }}
                  >
                    Overdue{" "}
                    {this.state.followUpCounts.hasOwnProperty("overDue")
                      ? `(${this.state.followUpCounts.overDue})`
                      : null}
                  </label>
                </span>
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    type="checkbox"
                    id="vertical-due-today-checkbox"
                    name="today"
                    checked={this.state.followUpFilters.today}
                    onChange={this.changeFilterHandler.bind(this, "followUp")}
                  />
                  <label
                    htmlFor="vertical-due-today-checkbox"
                      style={{ backgroundColor: "rgb(250, 236, 47,0.8)" }}
                  >
                    Due Today{" "}
                    {this.state.followUpCounts.hasOwnProperty("dueToday")
                      ? `(${this.state.followUpCounts.dueToday})`
                      : null}
                  </label>
                </span>
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    type="checkbox"
                    id="vertical-due-week-checkbox"
                    name="week"
                    checked={this.state.followUpFilters.week}
                    onChange={this.changeFilterHandler.bind(this, "followUp")}
                  />
                  <label
                    htmlFor="vertical-due-week-checkbox"
                      style={{ backgroundColor: "rgba(73, 154, 255, 0.75)" }}
                  >
                    Due This Week{" "}
                    {this.state.followUpCounts.hasOwnProperty("dueThisWeek")
                      ? `(${this.state.followUpCounts.dueThisWeek})`
                      : null}
                  </label>
                </span>
                <hr />
              </React.Fragment>
            )}
            <h2 style={{ alignSelf: "flex-start" }}>Stages</h2>
            <span
              style={{ alignSelf: "flex-start", width: "100%" }}
              className="inline-filter"
            >
              <input
                style={window.screen.width < 500 ? { width: "10%" } : null}
                type="checkbox"
                id="vertical-profiling-stage-checkbox"
                checked={this.state.filterData.profilingStage}
                onChange={this.changeFilterHandler.bind(this, "profilingStage")}
              />
              <label htmlFor="vertical-profiling-stage-checkbox">
                Profiling Stage
                {this.state.leadCounts.hasOwnProperty("profStage")
                  ? ` (${this.state.leadCounts.profStage})`
                  : null}
              </label>
            </span>
            {this.state.filterData.profilingStage ? (
              <div style={{ alignSelf: "flex-start", width: "100%" }} className="filter ml5">
                <span
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    className="stage-check"
                    type="checkbox"
                    id="profiling-stage-basic-checkbox"
                    checked={
                      this.state.filterData.profilingStages &&
                      this.state.filterData.profilingStages.indexOf("Basic") >
                      -1
                    }
                    onChange={this.changeSubFilterHandler.bind(
                      this,
                      "profilingStages",
                      "Basic"
                    )}
                  />
                  <label htmlFor="profiling-stage-basic-checkbox">
                    Basic
                    {this.state.leadCounts.hasOwnProperty("profBasic")
                      ? ` (${this.state.leadCounts.profBasic})`
                      : null}
                  </label>
                </span>
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={
                      window.screen.width < 500
                        ? { marginLeft: 0, width: "10%" }
                        : null
                    }
                    type="checkbox"
                    id="profiling-stage-advanced-checkbox"
                    checked={
                      this.state.filterData.profilingStages &&
                      this.state.filterData.profilingStages.indexOf(
                        "Advanced"
                      ) > -1
                    }
                    onChange={this.changeSubFilterHandler.bind(
                      this,
                      "profilingStages",
                      "Advanced"
                    )}
                  />
                  <label htmlFor="profiling-stage-advanced-checkbox">
                    Advanced
                    {this.state.leadCounts.hasOwnProperty("profAdvanced")
                      ? ` (${this.state.leadCounts.profAdvanced})`
                      : null}
                  </label>
                </span>
              </div>
            ) : null}
            <span
              style={{ alignSelf: "flex-start", width: "100%" }}
              className="inline-filter"
            >
              <input
                style={window.screen.width < 500 ? { width: "10%" } : null}
                type="checkbox"
                id="vertical-due-diligence-stage-checkbox"
                checked={this.state.filterData.dueDiligenceStage}
                onChange={this.changeFilterHandler.bind(
                  this,
                  "dueDiligenceStage"
                )}
              />
              <label htmlFor="vertical-due-diligence-stage-checkbox">
                Due-Diligence Stage
                {this.state.leadCounts.hasOwnProperty("ddStage")
                  ? ` (${this.state.leadCounts.ddStage})`
                  : null}
              </label>
            </span>
            {this.state.filterData.dueDiligenceStage ? (
              <div style={{ alignSelf: "flex-start", width: "100%" }} className="filter ml5">
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    type="checkbox"
                    id="dd-stage-not-started-checkbox"
                    checked={
                      this.state.filterData.dueDiligenceStages &&
                      this.state.filterData.dueDiligenceStages.indexOf(
                        "Not Started"
                      ) > -1
                    }
                    onChange={this.changeSubFilterHandler.bind(
                      this,
                      "dueDiligenceStages",
                      "Not Started"
                    )}
                  />
                  <label htmlFor="dd-stage-not-started-checkbox">
                    Not Started
                    {this.state.leadCounts.hasOwnProperty("ddNotStarted")
                      ? ` (${this.state.leadCounts.ddNotStarted})`
                      : null}
                  </label>
                </span>
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    type="checkbox"
                    id="dd-stage-pending-checkbox"
                    checked={
                      this.state.filterData.dueDiligenceStages &&
                      this.state.filterData.dueDiligenceStages.indexOf(
                        "Pending"
                      ) > -1
                    }
                    onChange={this.changeSubFilterHandler.bind(
                      this,
                      "dueDiligenceStages",
                      "Pending"
                    )}
                  />
                  <label htmlFor="dd-stage-pending-checkbox">
                    In progress
                    {this.state.leadCounts.hasOwnProperty("ddPending")
                      ? ` (${this.state.leadCounts.ddPending})`
                      : null}
                  </label>
                </span>
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    type="checkbox"
                    id="dd-stage-hold-checkbox"
                    checked={
                      this.state.filterData.dueDiligenceStages &&
                      this.state.filterData.dueDiligenceStages.indexOf("Hold") >
                      -1
                    }
                    onChange={this.changeSubFilterHandler.bind(
                      this,
                      "dueDiligenceStages",
                      "Hold"
                    )}
                  />
                  <label htmlFor="dd-stage-hold-checkbox">
                    On Hold
                    {this.state.leadCounts.hasOwnProperty("ddOnHold")
                      ? ` (${this.state.leadCounts.ddOnHold})`
                      : null}
                  </label>
                </span>
                <div style={{ justifyContent: "flex-start", width: "100%" }} className="text-start filter ml5">
                  <span
                    style={{ alignSelf: "flex-start", width: "100%" }}
                    className="inline-filter link text-start"
                    onClick={this.selectOnHoldReasons.bind(
                      this,
                      "dueDiligenceStage",
                      true
                    )}
                  >
                    Select All
                  </span>
                  <span
                    style={{ alignSelf: "flex-start", width: "100%" }}
                    className="inline-filter link"
                    onClick={this.selectOnHoldReasons.bind(
                      this,
                      "dueDiligenceStage",
                      false
                    )}
                  >
                    Deselect All
                  </span>
                  {this.state.ddOnHoldReasons.map((reason, index) => {
                    return (
                      <span
                        style={{ alignSelf: "flex-start", width: "100%" }}
                        className="inline-filter"
                      >
                        <input
                          style={
                            window.screen.width < 500 ? { width: "10%" } : null
                          }
                          type="checkbox"
                          id={`dd-hold-reason-${index}-checkbox`}
                          checked={
                            this.state.filterData.ddOnHoldReasons &&
                            this.state.filterData.ddOnHoldReasons.indexOf(
                              reason
                            ) > -1
                          }
                          onChange={this.changeSubFilterHandler.bind(
                            this,
                            "ddOnHoldReasons",
                            reason
                          )}
                        />
                        <label htmlFor={`dd-hold-reason-${index}-checkbox`}>
                          {reason}
                          {this.state.leadCounts.ddOnHoldReasons &&
                            this.state.leadCounts.ddOnHoldReasons.hasOwnProperty(
                              reason
                            )
                            ? ` (${this.state.leadCounts.ddOnHoldReasons[reason]})`
                            : " (0)"}
                        </label>
                      </span>
                    );
                  })}
                </div>
              </div>
            ) : null}
            <span
              style={{ alignSelf: "flex-start", width: "100%" }}
              className="inline-filter"
            >
              <input
                style={window.screen.width < 500 ? { width: "10%" } : null}
                type="checkbox"
                id="vertical-business-plan-stage-checkbox"
                checked={this.state.filterData.businessPlanStage}
                onChange={this.changeFilterHandler.bind(
                  this,
                  "businessPlanStage"
                )}
              />
              <label htmlFor="vertical-business-plan-stage-checkbox">
                Business Plan Stage
                {this.state.leadCounts.hasOwnProperty("bpStage")
                  ? ` (${this.state.leadCounts.bpStage})`
                  : null}
              </label>
            </span>
            {this.state.filterData.businessPlanStage ? (
              <div className="filter ml5">
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    type="checkbox"
                    id="bp-stage-not-started-checkbox"
                    checked={
                      this.state.filterData.businessPlanStages &&
                      this.state.filterData.businessPlanStages.indexOf(
                        "Not Started"
                      ) > -1
                    }
                    onChange={this.changeSubFilterHandler.bind(
                      this,
                      "businessPlanStages",
                      "Not Started"
                    )}
                  />
                  <label htmlFor="bp-stage-not-started-checkbox">
                    Not Started
                    {this.state.leadCounts.hasOwnProperty("bpNotStarted")
                      ? ` (${this.state.leadCounts.bpNotStarted})`
                      : null}
                  </label>
                </span>
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    type="checkbox"
                    id="bp-stage-pending-checkbox"
                    checked={
                      this.state.filterData.businessPlanStages &&
                      this.state.filterData.businessPlanStages.indexOf(
                        "Pending"
                      ) > -1
                    }
                    onChange={this.changeSubFilterHandler.bind(
                      this,
                      "businessPlanStages",
                      "Pending"
                    )}
                  />
                  <label htmlFor="bp-stage-pending-checkbox">
                    In progress
                    {this.state.leadCounts.hasOwnProperty("bpPending")
                      ? ` (${this.state.leadCounts.bpPending})`
                      : null}
                  </label>
                </span>
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    type="checkbox"
                    id="bp-stage-hold-checkbox"
                    checked={
                      this.state.filterData.businessPlanStages &&
                      this.state.filterData.businessPlanStages.indexOf("Hold") >
                      -1
                    }
                    onChange={this.changeSubFilterHandler.bind(
                      this,
                      "businessPlanStages",
                      "Hold"
                    )}
                  />
                  <label htmlFor="bp-stage-hold-checkbox">
                    On Hold
                    {this.state.leadCounts.hasOwnProperty("bpOnHold")
                      ? ` (${this.state.leadCounts.bpOnHold})`
                      : null}
                  </label>
                </span>
                <div className="filter ml5">
                  <span
                    style={{ alignSelf: "flex-start", width: "100%" }}
                    className="inline-filter link"
                    onClick={this.selectOnHoldReasons.bind(
                      this,
                      "businessPlanStage",
                      true
                    )}
                  >
                    Select All
                  </span>
                  <span
                    style={{ alignSelf: "flex-start", width: "100%" }}
                    className="inline-filter link"
                    onClick={this.selectOnHoldReasons.bind(
                      this,
                      "businessPlanStage",
                      false
                    )}
                  >
                    Deselect All
                  </span>
                  {this.state.bpOnHoldReasons.map((reason, index) => {
                    return (
                      <span
                        style={{ alignSelf: "flex-start", width: "100%" }}
                        className="inline-filter"
                        key={index}
                      >
                        <input
                          style={
                            window.screen.width < 500 ? { width: "10%" } : null
                          }
                          type="checkbox"
                          id={`bp-hold-reason-${index}-checkbox`}
                          checked={
                            this.state.filterData.bpOnHoldReasons &&
                            this.state.filterData.bpOnHoldReasons.indexOf(
                              reason
                            ) > -1
                          }
                          onChange={this.changeSubFilterHandler.bind(
                            this,
                            "bpOnHoldReasons",
                            reason
                          )}
                        />
                        <label htmlFor={`bp-hold-reason-${index}-checkbox`}>
                          {reason}
                          {this.state.leadCounts.bpOnHoldReasons &&
                            this.state.leadCounts.bpOnHoldReasons.hasOwnProperty(
                              reason
                            )
                            ? ` (${this.state.leadCounts.bpOnHoldReasons[reason]})`
                            : " (0)"}
                        </label>
                      </span>
                    );
                  })}
                </div>
              </div>
            ) : null}
            <span
              style={{ alignSelf: "flex-start", width: "100%" }}
              className="inline-filter"
            >
              <input
                style={window.screen.width < 500 ? { width: "10%" } : null}
                type="checkbox"
                id="vertical-capital-fulfilled-stage-checkbox"
                checked={this.state.filterData.capitalFulfilledStage}
                onChange={this.changeFilterHandler.bind(
                  this,
                  "capitalFulfilledStage"
                )}
              />
              <label htmlFor="vertical-capital-fulfilled-stage-checkbox">
                Loan Application Stage
                {this.state.leadCounts.hasOwnProperty("cfStage")
                  ? ` (${this.state.leadCounts.cfStage})`
                  : null}
              </label>
            </span>
            {this.state.filterData.capitalFulfilledStage ? (
              <div style={{ alignSelf: "flex-start", width: "100%" }} className="filter ml5">
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    type="checkbox"
                    id="cf-stage-not-started-checkbox"
                    checked={
                      this.state.filterData.capitalFulfilledStages &&
                      this.state.filterData.capitalFulfilledStages.indexOf(
                        "Not Started"
                      ) > -1
                    }
                    onChange={this.changeSubFilterHandler.bind(
                      this,
                      "capitalFulfilledStages",
                      "Not Started"
                    )}
                  />
                  <label htmlFor="cf-stage-not-started-checkbox">
                    Not Started
                    {this.state.leadCounts.hasOwnProperty("cfNotStarted")
                      ? ` (${this.state.leadCounts.cfNotStarted})`
                      : null}
                  </label>
                </span>
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    type="checkbox"
                    id="cf-stage-applied-checkbox"
                    checked={
                      this.state.filterData.capitalFulfilledStages &&
                      this.state.filterData.capitalFulfilledStages.indexOf(
                        "Applied"
                      ) > -1
                    }
                    onChange={this.changeSubFilterHandler.bind(
                      this,
                      "capitalFulfilledStages",
                      "Applied"
                    )}
                  />
                  <label htmlFor="cf-stage-applied-checkbox">
                    Applied
                    {this.state.leadCounts.hasOwnProperty("cfApplied")
                      ? ` (${this.state.leadCounts.cfApplied})`
                      : null}
                  </label>
                </span>
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    type="checkbox"
                    id="cf-stage-sanctioned-checkbox"
                    checked={
                      this.state.filterData.capitalFulfilledStages &&
                      this.state.filterData.capitalFulfilledStages.indexOf(
                        "Sanctioned"
                      ) > -1
                    }
                    onChange={this.changeSubFilterHandler.bind(
                      this,
                      "capitalFulfilledStages",
                      "Sanctioned"
                    )}
                  />
                  <label htmlFor="cf-stage-sanctioned-checkbox">
                    Sanctioned
                    {this.state.leadCounts.hasOwnProperty("cfSanctioned")
                      ? ` (${this.state.leadCounts.cfSanctioned})`
                      : null}
                  </label>
                </span>
                {/*<span className="inline-filter">
									<input type="checkbox" id="cf-stage-disbursed-checkbox" checked={this.state.filterData.capitalFulfilledStages && this.state.filterData.capitalFulfilledStages.indexOf('Disbursed') > -1} onChange={this.changeSubFilterHandler.bind(this, 'capitalFulfilledStages', 'Disbursed')} />
									<label htmlFor="cf-stage-disbursed-checkbox">
										Disbursed
										{this.state.leadCounts.hasOwnProperty('cfDisbursed') ? ` (${this.state.leadCounts.cfDisbursed})` : null}
									</label>
								</span>*/}
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    type="checkbox"
                    id="cf-stage-rejected-checkbox"
                    checked={
                      this.state.filterData.capitalFulfilledStages &&
                      this.state.filterData.capitalFulfilledStages.indexOf(
                        "Rejected"
                      ) > -1
                    }
                    onChange={this.changeSubFilterHandler.bind(
                      this,
                      "capitalFulfilledStages",
                      "Rejected"
                    )}
                  />
                  <label htmlFor="cf-stage-rejected-checkbox">
                    Rejected
                    {this.state.leadCounts.hasOwnProperty("cfRejected")
                      ? ` (${this.state.leadCounts.cfRejected})`
                      : null}
                  </label>
                </span>
                {/*<span className="inline-filter">
									<input type="checkbox" id="cf-stage-not-required-checkbox" checked={this.state.filterData.capitalFulfilledStages && this.state.filterData.capitalFulfilledStages.indexOf('Not required') > -1} onChange={this.changeSubFilterHandler.bind(this, 'capitalFulfilledStages', 'Not required')} />
									<label htmlFor="cf-stage-not-required-checkbox">
										Not required
										{this.state.leadCounts.hasOwnProperty('cfNotRequired') ? ` (${this.state.leadCounts.cfNotRequired})` : null}
									</label>
								</span>*/}
              </div>
            ) : null}
            <span
              style={{ alignSelf: "flex-start", width: "100%" }}
              className="inline-filter"
            >
              <input
                style={window.screen.width < 500 ? { width: "10%" } : null}
                type="checkbox"
                id="vertical-enterprise-setup-stage-checkbox"
                checked={this.state.filterData.enterpriseSetupStage}
                onChange={this.changeFilterHandler.bind(
                  this,
                  "enterpriseSetupStage"
                )}
              />
              <label htmlFor="vertical-enterprise-setup-stage-checkbox">
                Enterprise Setup Stage
                {this.state.leadCounts.hasOwnProperty("esStage")
                  ? ` (${this.state.leadCounts.esStage})`
                  : null}
              </label>
            </span>
            {this.state.filterData.enterpriseSetupStage ? (
              <div style={{ alignSelf: "flex-start", width: "100%" }} className="filter ml5">
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    type="checkbox"
                    id="es-stage-not-started-checkbox"
                    checked={
                      this.state.filterData.enterpriseSetupStages &&
                      this.state.filterData.enterpriseSetupStages.indexOf(
                        "Not Started"
                      ) > -1
                    }
                    onChange={this.changeSubFilterHandler.bind(
                      this,
                      "enterpriseSetupStages",
                      "Not Started"
                    )}
                  />
                  <label htmlFor="es-stage-not-started-checkbox">
                    Not Started
                    {this.state.leadCounts.hasOwnProperty("esNotStarted")
                      ? ` (${this.state.leadCounts.esNotStarted})`
                      : null}
                  </label>
                </span>
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    type="checkbox"
                    id="es-stage-yes-checkbox"
                    checked={
                      this.state.filterData.enterpriseSetupStages &&
                      this.state.filterData.enterpriseSetupStages.indexOf(
                        "Started up"
                      ) > -1
                    }
                    onChange={this.changeSubFilterHandler.bind(
                      this,
                      "enterpriseSetupStages",
                      "Started up"
                    )}
                  />
                  <label htmlFor="es-stage-yes-checkbox">
                    Started up
                    {this.state.leadCounts.hasOwnProperty("esYes")
                      ? ` (${this.state.leadCounts.esYes})`
                      : null}
                  </label>
                </span>
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    type="checkbox"
                    id="es-stage-no-checkbox"
                    checked={
                      this.state.filterData.enterpriseSetupStages &&
                      this.state.filterData.enterpriseSetupStages.indexOf(
                        "Scaled up"
                      ) > -1
                    }
                    onChange={this.changeSubFilterHandler.bind(
                      this,
                      "enterpriseSetupStages",
                      "Scaled up"
                    )}
                  />
                  <label htmlFor="es-stage-no-checkbox">
                    Scaled up
                    {this.state.leadCounts.hasOwnProperty("esNo")
                      ? ` (${this.state.leadCounts.esNo})`
                      : null}
                  </label>
                </span>
              </div>
            ) : null}
            <span
              style={{ alignSelf: "flex-start", width: "100%" }}
              className="inline-filter"
            >
              <input
                style={window.screen.width < 500 ? { width: "10%" } : null}
                type="checkbox"
                id="vertical-enterprise-sustainability-stage-checkbox"
                checked={this.state.filterData.enterpriseSustainabilityStage}
                onChange={this.changeFilterHandler.bind(
                  this,
                  "enterpriseSustainabilityStage"
                )}
              />
              <label htmlFor="vertical-enterprise-sustainability-stage-checkbox">
                Enterprise Sustainability Stage
                {this.state.leadCounts.hasOwnProperty("esusStage")
                  ? ` (${this.state.leadCounts.esusStage})`
                  : null}
              </label>
            </span>
            {this.state.filterData.enterpriseSustainabilityStage ? (
              <div style={{ alignSelf: "flex-start", width: "100%" }} className="filter ml5">
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    type="checkbox"
                    id="esus-stage-not-started-checkbox"
                    checked={
                      this.state.filterData.enterpriseSustainabilityStages &&
                      this.state.filterData.enterpriseSustainabilityStages.indexOf(
                        "Not Started"
                      ) > -1
                    }
                    onChange={this.changeSubFilterHandler.bind(
                      this,
                      "enterpriseSustainabilityStages",
                      "Not Started"
                    )}
                  />
                  <label htmlFor="esus-stage-not-started-checkbox">
                    Yet to collect data {/* Not Started */}
                    {this.state.leadCounts.hasOwnProperty("esusNotStarted")
                      ? ` (${this.state.leadCounts.esusNotStarted})`
                      : null}
                  </label>
                </span>
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    type="checkbox"
                    id="esus-stage-operational-checkbox"
                    checked={
                      this.state.filterData.enterpriseSustainabilityStages &&
                      this.state.filterData.enterpriseSustainabilityStages.indexOf(
                        "Operational"
                      ) > -1
                    }
                    onChange={this.changeSubFilterHandler.bind(
                      this,
                      "enterpriseSustainabilityStages",
                      "Operational"
                    )}
                  />
                  <label htmlFor="esus-stage-operational-checkbox">
                    Operational
                    {this.state.leadCounts.hasOwnProperty("esusOperational")
                      ? ` (${this.state.leadCounts.esusOperational})`
                      : null}
                  </label>
                </span>
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    type="checkbox"
                    id="esus-stage-not-operational-checkbox"
                    checked={
                      this.state.filterData.enterpriseSustainabilityStages &&
                      this.state.filterData.enterpriseSustainabilityStages.indexOf(
                        "Not Operational"
                      ) > -1
                    }
                    onChange={this.changeSubFilterHandler.bind(
                      this,
                      "enterpriseSustainabilityStages",
                      "Not Operational"
                    )}
                  />
                  <label htmlFor="esus-stage-not-operational-checkbox">
                    Not Operational
                    {this.state.leadCounts.hasOwnProperty("esusNotOperational")
                      ? ` (${this.state.leadCounts.esusNotOperational})`
                      : null}
                  </label>
                </span>
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    type="checkbox"
                    id="esus-stage-halted-checkbox"
                    checked={
                      this.state.filterData.enterpriseSustainabilityStages &&
                      this.state.filterData.enterpriseSustainabilityStages.indexOf(
                        "Halted"
                      ) > -1
                    }
                    onChange={this.changeSubFilterHandler.bind(
                      this,
                      "enterpriseSustainabilityStages",
                      "Halted"
                    )}
                  />
                  <label htmlFor="esus-stage-halted-checkbox">
                    Halted
                    {this.state.leadCounts.hasOwnProperty("esusHalted")
                      ? ` (${this.state.leadCounts.esusHalted})`
                      : null}
                  </label>
                </span>
                <span
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  className="inline-filter"
                >
                  <input
                    style={window.screen.width < 500 ? { width: "10%" } : null}
                    type="checkbox"
                    id="esus-stage-pivoted-checkbox"
                    checked={
                      this.state.filterData.enterpriseSustainabilityStages &&
                      this.state.filterData.enterpriseSustainabilityStages.indexOf(
                        "Pivoted"
                      ) > -1
                    }
                    onChange={this.changeSubFilterHandler.bind(
                      this,
                      "enterpriseSustainabilityStages",
                      "Pivoted"
                    )}
                  />
                  <label htmlFor="esus-stage-pivoted-checkbox">
                    Pivoted
                    {this.state.leadCounts.hasOwnProperty("esusPivoted")
                      ? ` (${this.state.leadCounts.esusPivoted})`
                      : null}
                  </label>
                </span>
              </div>
            ) : null}
            <div className="divider-line"></div>
            <span
              style={{ alignSelf: "flex-start", width: "100%" }}
              className="inline-filter"
            >
              <input
                style={window.screen.width < 500 ? { width: "10%" } : null}
                type="checkbox"
                id="vertical-starred-checkbox"
                checked={this.state.filterData.starred}
                onChange={this.changeFilterHandler.bind(this, "starred")}
              />
              <label htmlFor="vertical-starred-checkbox">Starred Leads</label>
            </span>
            {/*<span className="inline-filter">
							<input type="checkbox" id="vertical-on-hold-checkbox" checked={this.state.filterData.onHold} onChange={this.changeFilterHandler.bind(this, 'onHold')} />
							<label htmlFor="vertical-on-hold-checkbox">On Hold</label>
						</span>
						{this.state.filterData.onHold ? (
							<div className="filter ml5">
								{this.state.onHoldReasons.map((reason, index) => {
									return (
										<span className="inline-filter">
											<input type="checkbox" id={`hold-reason-${index}-checkbox`} checked={this.state.filterData.onHoldReasons.indexOf(reason) > -1} onChange={this.changeSubFilterHandler.bind(this, 'onHoldReasons', reason)} />
											<label htmlFor={`hold-reason-${index}-checkbox`}>{reason}</label>
										</span>
									)
								})}
							</div>
						) : (null)*/}
            <span
              style={{ alignSelf: "flex-start", width: "100%" }}
              className="inline-filter"
            >
              <input
                style={window.screen.width < 500 ? { width: "10%" } : null}
                type="checkbox"
                id="vertical-disqualified-checkbox"
                checked={this.state.filterData.disqualified}
                onChange={this.changeFilterHandler.bind(this, "disqualified")}
              />
              <label htmlFor="vertical-disqualified-checkbox">
                Disqualified Leads
              </label>
            </span>
            {this.state.filterData.disqualified ? (
              <div className="filter ml5">
                {this.state.disqualificationReasons.map((reason, index) => {
                  return (
                    <span style={{ alignSelf: "flex-start", width: "100%" }} className="inline-filter">
                      <input
                        style={window.screen.width < 500 ? { width: "10%" } : null}
                        type="checkbox"
                        id={`disqualification-reason-${index}-checkbox`}
                        checked={
                          this.state.filterData.disqualificationReasons &&
                          this.state.filterData.disqualificationReasons.indexOf(
                            reason
                          ) > -1
                        }
                        onChange={this.changeSubFilterHandler.bind(
                          this,
                          "disqualificationReasons",
                          reason
                        )}
                      />
                      <label
                        htmlFor={`disqualification-reason-${index}-checkbox`}
                      >
                        {reason}
                      </label>
                    </span>
                  );
                })}
              </div>
            ) : null}
          </Row>
        ) : null}
       
        <div className="leads-container">
          {this.state.totalLeads ? (
            <span id="drag-table-left">{"<"}</span>
          ) : null}
          {this.state.totalLeads ? (
            <span id="drag-table-right">{">"}</span>
          ) : null}
          <LoadingOverlay
            active={this.state.leadsLoading || false}
            spinner={<BounceLoader />}
          >
          <Col md={12} className="leads-table-container">
            {this.state.allUsers ? (
              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  page: this.state.page,
                  sizePerPage: this.state.sizePerPage,
                  totalSize: this.state.totalLeads,
                  sizePerPageList: this.state.sizePerPageList,
                })}
                keyField="_id"
                data={this.state.allUsers}
                columns={columns}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    data={this.state.allUsers}
                    columns={columns}
                    search={{ searchFormatted: true }}
                  >
                    {(props) => (
                      <div>
                        {this.props.type !== "swavalamban-connect-kendra" &&
                          this.props.view !== "tab" &&
                          !this.props.sidebarMinimized ? (
                          <Row className="mt10 mb0 disqualified-leads-filter-container">
                            <span className="d-flex mr15">
                              <input
                                type="checkbox"
                                checked={
                                  this.state.filterData.dueDiligenceStage
                                }
                                onChange={this.changeFilterHandler.bind(
                                  this,
                                  "dueDiligenceStage"
                                )}
                              />
                              Due-Diligence Stage
                            </span>
                            <span className="d-flex mr15">
                              <input
                                className="stages-check"
                                type="checkbox"
                                checked={
                                  this.state.filterData.businessPlanStage
                                }
                                onChange={this.changeFilterHandler.bind(
                                  this,
                                  "businessPlanStage"
                                )}
                              />
                              Business Plan Stage
                            </span>
                            <span className="d-flex mr15">
                              <input
                                type="checkbox"
                                checked={this.state.filterData.onHold}
                                onChange={this.changeFilterHandler.bind(
                                  this,
                                  "onHold"
                                )}
                              />
                              On Hold
                            </span>
                            <span className="d-flex mr15">
                              <input
                                type="checkbox"
                                checked={this.state.filterData.starred}
                                onChange={this.changeFilterHandler.bind(
                                  this,
                                  "starred"
                                )}
                              />
                              Starred Leads
                            </span>
                            <span className="d-flex">
                              <input
                                type="checkbox"
                                checked={this.state.filterData.disqualified}
                                onChange={this.changeFilterHandler.bind(
                                  this,
                                  "disqualified"
                                )}
                              />
                              Disqualified Leads
                            </span>
                          </Row>
                        ) : null}
                        {false && JSON.parse(readCookie("userData")).userType.name === 'Project Management Unit' && <div align='end' className='row' style={{justifyContent: 'flex-end', paddingRight: 150}}>
                            {this.state.selectMode && <h5 style={{color: '#ef5a20', fontWeight: 'bold', marginRight: 10, paddingTop: 5}}>{this.state.selectedLeadsIds.length} Selected</h5>}
                            {this.state.selectMode > 0 && 
                              <DropdownButton style={{marginRight: 30}} disabled={this.state.selectedLeadsIds.length === 0} id="dropdown-basic-button" title="&emsp;Take Action&emsp;" onChange={() => console.log('SELECTED LEADS')}>
                                <Dropdown.Item onClick={() => {
                                  this.setState({ showAddLeadsToProjectModal: true, projectMoveAction: 'add' });
                                }
                                }>
                                  Add to another project
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                  this.setState({ showAddLeadsToProjectModal: true, projectMoveAction: 'move' });
                                }
                                }>
                                  Move to another project
                                </Dropdown.Item>
                                {/* <Dropdown.Item onClick={() => {
                                      localStorage.setItem('selectedLeads', JSON.stringify(this.state.selectedLeads));
                                      window.open(process.env.REACT_APP_CAMPAIGN_URL+"?token=" + JSON.parse(readCookie("access_token")), "_blank")
                                    }
                                  }>
                                  Trigger Campaign
                                </Dropdown.Item> */}
                              </DropdownButton>}
                            <h5 style={{paddingTop: 2}}>Selection Mode</h5>
                            <Switch checked={this.state.selectMode} style={{transform: 'scale(1.3)', width: 80, marginLeft: 30}} checkedChildren="On" unCheckedChildren="Off" onChange={(e) => this.setState({allLeadsSelected: false, selectedLeads: [], selectedLeadsIds: [], selectMode: e})} />
                        </div>}

                                                {JSON.parse(readCookie("userData")).userType.name === 'Project Management Unit' && this.state.selectMode && this.state.selectedLeadsIds.length !== 0 ? 
                          <AddCandidatesToProjectModal 
                              showModal={this.state.showAddLeadsToProjectModal && this.state.projectMoveAction}
                          leadIds={this.state.selectedLeadsIds}
                          leadsType={'leads'}
                          hideModal={this.hideAddLeadsToProjectModal}
                          action={this.state.projectMoveAction}
                              />   
                          : null }
                        <Row className="mt10 mb5">
                          <Row>
                            <div className="total-leads-count-container">
                              <span className="total-leads m0">
                                {"Total leads: " + this.state.allUsers.length}
                              </span>
                            </div>
                          </Row>

                          {this.props.type !== "swavalamban-connect-kendra" &&
                            this.props.view !== "tab" ? (
                            <Col
                              className="leads-table-search-container"
                              sm={8}
                              xs={8}
                            >
                              <SearchBar
                                {...props.searchProps}
                                placeholder="Search by Name/Mobile/Pincode"
                                className="leads-table-search"
                                delay={1500}
                              />
                            </Col>
                          ) : null}
                          {this.props.type !== "swavalamban-connect-kendra" &&
                            this.props.view !== "tab" ? (
                            <div
                              className="filter-class d-flex align-items-center justify-content-center"
                              onClick={this.showFilter}
                            >
                              Filter<i className="fas fa-filter"></i>
                            </div>
                          ) : null}
                        </Row>
                        {/* {JSON.parse(readCookie("userData")).type ===
                          "partners" ? (
                          <div className="credit-progress-container">
                            <Row>
                              <p style={{ color: "red", fontSize: "5px" }}> */}
                                {/* <h6>Note:</h6> */}
                                {/* <ul>
                                  <li>
                                    In this district,{" "}
                                    <strong>
                                      {this.state.enterpriseProgressStats
                                        .enterprisesCreatedSoFar || 0}
                                    </strong>{" "}
                                    enterprises have been created so far (
                                    <strong>
                                      {this.state.enterpriseProgressStats
                                        .creditLinkedCandidates || 0}
                                    </strong>{" "}
                                    with credit-linkage and{" "}
                                    <strong>
                                      {this.state.enterpriseProgressStats
                                        .selfInvestmentCandidates || 0}
                                    </strong>{" "}
                                    with 100% self-investment).{" "}
                                    {(35 -
                                      (this.state.enterpriseProgressStats
                                        .creditLinkedCandidates || 0) >
                                      0
                                      ? 35 -
                                      (this.state.enterpriseProgressStats
                                        .creditLinkedCandidates || 0)
                                      : 0) !== 0 ? (
                                      <>
                                        To complete the mission,{" "}
                                        <strong>
                                          {70 -
                                            (this.state.enterpriseProgressStats
                                              .enterprisesCreatedSoFar || 0)}
                                        </strong>{" "}
                                        more startups need to be created, out of
                                        which at least{" "}
                                        <strong>
                                          {35 -
                                            (this.state.enterpriseProgressStats
                                              .creditLinkedCandidates || 0)}
                                        </strong>{" "}
                                        need to be credit-linked (loan
                                        supported). The more, the better.
                                      </>
                                    ) : (70 -
                                      (this.state.enterpriseProgressStats
                                        .enterprisesCreatedSoFar || 0) >
                                      0
                                      ? 70 -
                                      (this.state.enterpriseProgressStats
                                        .enterprisesCreatedSoFar || 0)
                                      : 0) > 0 ? (
                                      <>
                                        To complete the mission,{" "}
                                        <strong>
                                          {70 -
                                            (this.state.enterpriseProgressStats
                                              .enterprisesCreatedSoFar || 0) >
                                            0
                                            ? 70 -
                                            (this.state
                                              .enterpriseProgressStats
                                              .enterprisesCreatedSoFar || 0)
                                            : 0}
                                        </strong>{" "}
                                        more startups need to be created
                                      </>
                                    ) : null}
                                  </li> */}
                                  {/* <li>Please ensure that the payment claims are raised only once a month and in the ratio (credit linked : 100% self investment) - 7:3 or 14:6 or 21:9 or 28:12 or 35:15.
                                  </li>
                                   */}
                                  {/* {getRatiosString(
                                    35 -
                                    (this.state.enterpriseProgressStats
                                      .creditLinkedCandidates || 0),
                                    70 -
                                    (this.state.enterpriseProgressStats
                                      .enterprisesCreatedSoFar || 0) -
                                    (35 -
                                      (this.state.enterpriseProgressStats
                                        .creditLinkedCandidates || 0))
                                  )}
                                  <li>
                                    Candidates who are marked successful, have
                                    undergone audit and haven’t been claimed yet
                                    are labelled{" "}
                                    <strong>“Ready for claim”</strong> and those
                                    candidate who have been claimed by you
                                    already are marked as{" "}
                                    <strong>"Claimed"</strong>.
                                  </li>
                                </ul>
                              </p>
                            </Row>
                          </div>
                        ) : null} */}
                        {this.props.type !== "swavalamban-connect-kendra" &&
                          this.props.view !== "tab" ? (
                          <div
                            style={window.screen.width < 500 ? { height: 400, overflow: 'scroll' } : null}
                            className="filter-container">
                            <div onClick={() => this.showFilter()} style={{ color: 'white', backgroundColor: '#ef5a20', height: 22, width: 22, alignSelf: 'flex-end', marginLeft: '96%', fontWeight: 'bold', fontSize: 16, paddingLeft: 5 }}>X</div>
                            {/* <div 
                              className="close-filter"
                              onClick={this.showFilter}
                              onClick={() => console.log('HERERERERERE')}
                            >
                              X
                            </div> */}
                            <Row>
                              <Col md={12} xs={6} sm={6} className='p-0'>
                                <div className="state-filter">
                                  <label className="control-label">State</label>
                                  <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select State"
                                    onChange={this.changeStateFilter}
                                    value={this.state.filterData.state}
                                    filterOption={(input, option) =>
                                      this.searchFilter(input, option)
                                    }
                                  >
                                    <Option value="">All</Option>
                                    <OptGroup label="Focus States">
                                      {/* {this.state.allStates.map(
                                    (state, stateIndex) => {
                                      if (state.inFocusRegion) {
                                        if (
                                          JSON.parse(readCookie("userData"))
                                            .userType.name ===
                                            "Swavalamban Fellows" &&
                                          JSON.parse(
                                            readCookie("userData")
                                          ).address.state.includes(state.name)
                                        ) {
                                          return (
                                            <Option
                                              key={stateIndex}
                                              value={state.name}
                                            >
                                              {state.name}
                                            </Option>
                                          );
                                        } else if (
                                          JSON.parse(readCookie("userData"))
                                            .userType.name !==
                                          "Swavalamban Fellows"
                                        ) {
                                          return (
                                            <Option
                                              key={stateIndex}
                                              value={state.name}
                                            >
                                              {state.name}
                                            </Option>
                                          );
                                        } else return null;
                                      } else return null;
                                    }
                                  )}
                                   */}
                                      {this.state.allStates.map(
                                        (state, stateIndex) => {
                                          if (state.inFocusRegion) {
                                            if (
                                              JSON.parse(readCookie("userData"))
                                                .userType.name ===
                                              "Swavalamban Fellows" &&
                                              JSON.parse(
                                                readCookie("userData")
                                              ).address.state.includes(state.name)
                                            ) {
                                              return (
                                                <Option
                                                  key={stateIndex}
                                                  value={state.name}
                                                >
                                                  {state.name}
                                                </Option>
                                              );
                                            } else if (
                                              JSON.parse(readCookie("userData"))
                                                .userType.name ===
                                              "Zonal Manager" &&
                                              JSON.parse(
                                                readCookie("userData")
                                              ).address.state.includes(state.name)
                                            ) {
                                              return (
                                                <Option
                                                  key={stateIndex}
                                                  value={state.name}
                                                >
                                                  {state.name}
                                                </Option>
                                              );
                                            } else if (
                                              JSON.parse(readCookie("userData"))
                                                .userType.name !==
                                              "Swavalamban Fellows" &&
                                              JSON.parse(readCookie("userData"))
                                                .userType.name !== "Zonal Manager"
                                            ) {
                                              return (
                                                <Option
                                                  key={stateIndex}
                                                  value={state.name}
                                                >
                                                  {state.name}
                                                </Option>
                                              );
                                            } else return null;
                                          } else return null;
                                        }
                                      )}
                                    </OptGroup>
                                    <OptGroup label="Not Focussed States">
                                      {this.state.allStates.map(
                                        (state, stateIndex) => {
                                          if (!state.inFocusRegion) {
                                            if (
                                              JSON.parse(readCookie("userData"))
                                                .userType.name ===
                                              "Swavalamban Fellows" &&
                                              JSON.parse(
                                                readCookie("userData")
                                              ).address.state.includes(state.name)
                                            ) {
                                              return (
                                                <Option
                                                  key={stateIndex}
                                                  value={state.name}
                                                >
                                                  {state.name}
                                                </Option>
                                              );
                                            } else if (
                                              JSON.parse(readCookie("userData"))
                                                .userType.name ===
                                              "Zonal Manager" &&
                                              JSON.parse(
                                                readCookie("userData")
                                              ).address.state.includes(state.name)
                                            ) {
                                              return (
                                                <Option
                                                  key={stateIndex}
                                                  value={state.name}
                                                >
                                                  {state.name}
                                                </Option>
                                              );
                                            } else if (
                                              JSON.parse(readCookie("userData"))
                                                .userType.name !==
                                              "Swavalamban Fellows" &&
                                              JSON.parse(readCookie("userData"))
                                                .userType.name !== "Zonal Manager"
                                            ) {
                                              return (
                                                <Option
                                                  key={stateIndex}
                                                  value={state.name}
                                                >
                                                  {state.name}{" "}
                                                </Option>
                                              );
                                            } else return null;
                                          } else return null;
                                        }
                                      )}
                                      {/* {this.state.allStates.map(
                                    (state, stateIndex) => {
                                      if (!state.inFocusRegion) {
                                        if (
                                          JSON.parse(readCookie("userData"))
                                            .userType.name ===
                                            "Swavalamban Fellows" &&
                                          JSON.parse(
                                            readCookie("userData")
                                          ).address.state.includes(state.name)
                                        ) {
                                          return (
                                            <Option
                                              key={stateIndex}
                                              value={state.name}
                                            >
                                              {state.name}
                                            </Option>
                                          );
                                        } else if (
                                          JSON.parse(readCookie("userData"))
                                            .userType.name !==
                                          "Swavalamban Fellows"
                                        ) {
                                          return (
                                            <Option
                                              key={stateIndex}
                                              value={state.name}
                                            >
                                              {state.name}
                                            </Option>
                                          );
                                        } else return null;
                                      } else return null;
                                    }
                                  )} */}
                                    </OptGroup>
                                  </Select>
                                </div>
                              </Col>
                              <Col md={12} xs={6} sm={6} className='p-0 pl-1'>
                                <div className="district-filter">
                                  <label className="control-label">District</label>
                                  <Select
                                    mode="multiple"
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select District"
                                    onChange={this.changeFilterHandler.bind(
                                      this,
                                      "district"
                                    )}
                                    value={this.state.filterData.district}
                                    filterOption={(input, option) =>
                                      this.searchFilter(input, option)
                                    }
                                  >
                                    {this.state.allDistricts.map(
                                      (district, districtIndex) => {
                                        let districtName = district.name;
                                        if (district.state)
                                          districtName +=
                                            " (" + district.state + ")";
                                        if (
                                          JSON.parse(readCookie("userData"))
                                            .userType.name ===
                                          "Swavalamban Fellows" &&
                                          JSON.parse(
                                            readCookie("userData")
                                          ).address.district.includes(district.name)
                                        ) {
                                          return (
                                            <Option
                                              key={districtIndex}
                                              value={district.name}
                                            >
                                              {districtName}
                                            </Option>
                                          );
                                        } else if (
                                          JSON.parse(readCookie("userData"))
                                            .userType.name === "Zonal Manager" &&
                                          JSON.parse(
                                            readCookie("userData")
                                          ).address.state.includes(district.state)
                                        ) {
                                          return (
                                            <Option
                                              key={districtIndex}
                                              value={district.name}
                                            >
                                              {districtName}
                                            </Option>
                                          );
                                        } else if (
                                          JSON.parse(readCookie("userData"))
                                            .userType.name !==
                                          "Swavalamban Fellows" &&
                                          JSON.parse(readCookie("userData"))
                                            .userType.name !== "Zonal Manager" &&
                                          this.state.focusStates.indexOf(
                                            district.state
                                          ) > -1
                                        ) {
                                          return (
                                            <Option
                                              key={districtIndex}
                                              value={district.name}
                                            >
                                              {districtName}
                                            </Option>
                                          );
                                        } else return null;
                                      }
                                    )}
                                  </Select>
                                </div>
                              </Col>
                              {JSON.parse(readCookie("userData")).type === "le" &&
                                (JSON.parse(readCookie("userData")).userType
                                  .name === "Project Management Unit" ||
                                  JSON.parse(readCookie("userData")).userType
                                    .name === "Zonal Manager") ? (
                                <Col md={12} xs={6} sm={6} className='p-0'>
                                  <div className="district-filter">
                                    <label className="control-label">Cluster</label>
                                    <Select
                                      showSearch
                                      style={{ width: "100%" }}
                                      placeholder="Select Cluster"
                                      onChange={this.changeFilterHandler.bind(
                                        this,
                                        "cluster"
                                      )}
                                      value={this.state.filterData.cluster}
                                      filterOption={(input, option) =>
                                        this.searchFilter(input, option)
                                      }
                                    >
                                      {this.state.swavalambanFellows.map(
                                        (fellow, fellowIndex) => {
                                          return (
                                            <Option
                                              key={fellowIndex}
                                              title={`${fellow.fullName} (${fellow.email})`}
                                              value={fellow._id}
                                            >
                                              {fellow.fullName}
                                            </Option>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </div>
                                </Col>
                              ) : null}
                              {JSON.parse(readCookie("userData")).type ===
                                "le" ? (
                                <Col md={12} xs={6} sm={6} className='p-0 pl-1'>
                                  <div className="district-filter">
                                    <label className="control-label">
                                      Select User Type
                                    </label>
                                    <Select
                                      style={{ width: "100%" }}
                                      placeholder="User Type"
                                      onChange={this.changeFilterHandler.bind(
                                        this,
                                        "userType"
                                      )}
                                      value={this.state.filterData.userType}
                                    >
                                      <Option value="">All</Option>
                                      <Option value="Unassigned">Unassigned</Option>
                                      {this.state.allUserTypes.map(
                                        (userType, userTypeIndex) => {
                                          return (
                                            <Option
                                              key={userTypeIndex}
                                              value={userType.customUrl}
                                              title={userType.name}
                                            >
                                              {userType.name}
                                            </Option>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </div>
                                </Col>
                              ) : null}
                              {JSON.parse(readCookie("userData")).type ===
                                "le" ? (
                                <Col md={12} xs={6} sm={6} className='p-0'>
                                  <div className="district-filter">
                                    <label className="control-label">
                                      Assigned To
                                    </label>
                                    <Select
                                      style={{ width: "100%" }}
                                      showSearch
                                      disabled={
                                        this.state.filterData.userType
                                          ? false
                                          : true
                                      }
                                      placeholder="Assigned To"
                                      onChange={this.changeFilterHandler.bind(
                                        this,
                                        "assignedTo"
                                      )}
                                      value={this.state.filterData.assignedTo}
                                      filterOption={(input, option) =>
                                        this.searchFilter(input, option)
                                      }
                                    >
                                      <Option value="">All</Option>
                                      {this.state.allAssignedTo.length
                                        ? this.state.allAssignedTo.map(
                                          (assignedTo, assignedToIndex) => {
                                            if (
                                              this.state.filterData.userType ===
                                              "swavalamban-connect-kendra"
                                            ) {
                                              return (
                                                <Option
                                                  key={assignedToIndex}
                                                  value={assignedTo._id}
                                                  title={assignedTo.ngoName}
                                                >
                                                  {assignedTo.ngoName}
                                                </Option>
                                              );
                                            } else {
                                              return (
                                                <Option
                                                  key={assignedToIndex}
                                                  value={assignedTo._id}
                                                  title={assignedTo.fullName}
                                                >
                                                  {assignedTo.fullName +
                                                    " (" +
                                                    assignedTo.email +
                                                    ")"}
                                                </Option>
                                              );
                                            }
                                          }
                                        )
                                        : null}
                                    </Select>
                                  </div>
                                </Col>
                              ) : null}
                              {JSON.parse(readCookie("userData")).type ===
                                "le" ? (
                                <Col md={12} xs={6} sm={6} className='p-0 pl-1'>
                                  <div className="district-filter">
                                    <label className="control-label">
                                      Tagged To
                                    </label>
                                    <Select
                                      style={{ width: "100%" }}
                                      showSearch
                                      placeholder="Tagged To"
                                      onChange={this.changeFilterHandler.bind(
                                        this,
                                        "taggedTo"
                                      )}
                                      value={this.state.filterData.taggedTo}
                                      filterOption={(input, option) =>
                                        this.searchFilter(input, option)
                                      }
                                    >
                                      <Option value="">All</Option>
                                      <Option value="None">None tagged</Option>
                                      {this.state.allSaarthis.map(
                                        (saarthi, saarthiIndex) => {
                                          return (
                                            <Option
                                              key={saarthiIndex}
                                              value={saarthi._id}
                                              title={saarthi.fullName}
                                            >
                                              {saarthi.fullName +
                                                " (" +
                                                saarthi.email +
                                                ")"}
                                            </Option>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </div>
                                </Col>
                                  ) : null}
                                  {JSON.parse(readCookie("userData")).userType
                                    .name === "SIDBI" ? null :   <Col md={12} xs={6} sm={6} className='p-0 pl-1'>
                                    <div className="phase-filter">
                                      <label className="control-label">
                                        B-Plan age above
                                      </label>
                                      <InputNumber
                                        // style={{ width: "calc(50% - 5px)" }}
                                        value={this.state.filterData.bplanAge}
                                        min={0}
                                        // formatter={(value) => `${value} day(s)`}
                                        // parser={(value) => value.replace("₹ ", "")}
                                        onChange={this.changeFilterHandler.bind(
                                          this,
                                          "bplanAge"
                                        )}
                                      />
                                    </div>
                                  </Col>}
                                  {JSON.parse(readCookie("userData")).userType
                                    .name === "SIDBI" ? null : <Col md={12} xs={12} sm={12} className='p-0'>
                                    <div className="district-filter">
                                      <label className="control-label">
                                        Loan Amount Required
                                      </label>
                                      <div className="d-flex">
                                        <InputNumber
                                          style={{ width: "calc(50% - 5px)" }}
                                          value={this.state.filterData.minLoanAmount}
                                          min={0}
                                          formatter={(value) => `₹ ${value}`}
                                          parser={(value) => value.replace("₹ ", "")}
                                          onChange={this.changeFilterHandler.bind(
                                            this,
                                            "minLoanAmount"
                                          )}
                                        />
                                        <InputNumber
                                          style={{
                                            width: "calc(50% - 5px)",
                                            marginLeft: 5,
                                          }}
                                          value={this.state.filterData.maxLoanAmount}
                                          min={this.state.filterData.minLoanAmount || 0}
                                          formatter={(value) => `₹ ${value}`}
                                          parser={(value) => value.replace("₹ ", "")}
                                          onChange={this.changeFilterHandler.bind(
                                            this,
                                            "maxLoanAmount"
                                          )}
                                        />
                                      </div>
                                      <div className="d-flex min-max-label">
                                        <div className="text-center">Min</div>
                                        <div className="text-center">Max</div>
                                      </div>
                                    </div>
                                  </Col>}

                              <Col md={12} xs={6} sm={6} className='p-0'>
                                <div className="phase-filter">
                                  <label className="control-label">Phase</label>
                                  <Select
                                    style={{ width: "100%" }}
                                    placeholder="Phase"
                                    onChange={this.changeFilterHandler.bind(
                                      this,
                                      "phase"
                                    )}
                                    value={this.state.filterData.phase}
                                  >
                                    <Option value="">All</Option>
                                    <Option value="Start-up">Start-up</Option>
                                    <Option value="Scale-up">Scale-up</Option>
                                  </Select>
                                </div>
                              </Col>
                              {/* 
														<div className="phase-filter">
															<label className="control-label">Phase</label>
															<Select style={{ width: '100%' }} placeholder="Phase" onChange={this.changeFilterHandler.bind(this, 'phase')} value={this.state.filterData.phase}>
																<Option value="">All</Option>
																<Option value="Start-up">Start-up</Option>
																<Option value="Scale-up">Scale-up</Option>
															</Select>
														</div> */}
                                  {JSON.parse(readCookie("userData")).userType
                                    .name === "SIDBI" ? null : <Col md={12} xs={6} sm={6} className='p-0 pl-1'>
                                <div className="phase-filter">
                                  <label className="control-label">
                                    Audit Status
                                  </label>
                                  <Select
                                    style={{ width: "100%" }}
                                    placeholder="Audit status"
                                    onChange={this.changeFilterHandler.bind(
                                      this,
                                      "audit"
                                    )}
                                    value={this.state.filterData.audit}
                                  >
                                    <Option value="">All</Option>
                                    <Option value="Audit Pending">
                                      Audit Pending
                                    </Option>
                                    <Option value="Audited">
                                      Audit Successful
                                    </Option>
                                    <Option value="Audit Failed">
                                      Audit Failed
                                    </Option>
                                    <Option disabled value="Audit Requested">
                                      Audit Requested
                                    </Option>
                                  </Select>
                                </div>
                                  </Col>}
                                    <Col md={12} xs={6} sm={6} className='p-0 pl-1'>
                                    <div className="phase-filter">
                                      <label className="control-label">
                                        SIDBI Payment Milestone
                                      </label>
                                      <Select
                                        style={{ width: "100%" }}
                                        placeholder="SIDBI Payment Milestone"
                                        onChange={this.changeFilterHandler.bind(
                                          this,
                                          "sidbiMilestone"
                                        )}
                                        value={this.state.filterData.sidbiMilestone}
                                      >
                                        <Option value="">All</Option>
                                        <Option value="No milestone assigned">No milestone assigned</Option>
                                        {
                                          this.state.allMilestones.map(milestone => <Option key={milestone} value={milestone}>
                                            {milestone}
                                          </Option>)
                                        }

                                      </Select>
                                    </div>
                                  </Col>
                              <Col md={12} xs={6} sm={6} className='p-0'>
                                <div className="phase-filter">
                                  <label className="control-label">Gender</label>
                                  <Select
                                    style={{ width: "100%" }}
                                    placeholder="Gender"
                                    onChange={this.changeFilterHandler.bind(
                                      this,
                                      "gender"
                                    )}
                                    value={this.state.filterData.gender}
                                  >
                                    <Option value="">All</Option>
                                    <Option value="Male">Male</Option>
                                    <Option value="Female">Female</Option>
                                    <Option value="Other">Other</Option>
                                  </Select>
                                </div>
                              </Col>
                              <Col md={12} xs={6} sm={6} className='p-0 pl-1'>
                                <div className="phase-filter">
                                  <label className="control-label">Category</label>
                                  <Select
                                    style={{ width: "100%" }}
                                    placeholder="Category"
                                    onChange={this.changeFilterHandler.bind(
                                      this,
                                      "category"
                                    )}
                                    value={this.state.filterData.category}
                                  >
                                    <Option value="">All</Option>
                                    <Option value="General">General</Option>
                                    <Option value="SC">SC</Option>
                                    <Option value="ST">ST</Option>
                                    <Option value="OBC">OBC</Option>
                                    <Option value="Others">Others</Option>
                                  </Select>
                                </div>
                              </Col>
                              <Col md={12} xs={12} sm={12} className='p-0'>
                                <div className="district-filter">
                                  <label className="control-label">
                                    Business Ideas
                                  </label>
                                  <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select Idea"
                                    onChange={this.changeFilterHandler.bind(
                                      this,
                                      "idea"
                                    )}
                                    value={this.state.filterData.idea}
                                    filterOption={(input, option) =>
                                      this.searchFilter(input, option)
                                    }
                                  >
                                    <Option value="">All</Option>
                                    {this.state.allBusinessIdeas.map(
                                      (idea, ideaIndex) => {
                                        return (
                                          <Option
                                            key={ideaIndex}
                                            title={
                                              idea.combine + " (" + idea.count + ")"
                                            }
                                            value={idea.combine}
                                          >
                                            {idea.name + " (" + idea.count + ")"}
                                          </Option>
                                        );
                                      }
                                    )}
                                  </Select>
                                </div>
                              </Col>
                              {JSON.parse(readCookie("userData")).userType
                                .name !== "SIDBI" && (
                                  <Col md={12} xs={12} sm={12} className='p-0'>
                                    <div className="phase-filter">
                                      <label className="control-label">
                                        Payment Claim Status
                                      </label>
                                      <Select
                                        style={{ width: "100%" }}
                                        placeholder="Payment Claim Status"
                                        onChange={this.changeFilterHandler.bind(
                                          this,
                                          "paymentClaim"
                                        )}
                                        value={this.state.filterData.paymentClaim}
                                      >
                                        <Option value="">All</Option>
                                        {[
                                          "In-house-intervention-Cannot be claimed",
                                          "Unclaimed",
                                          "Claimed but Audit Failed",
                                          "Claimed and Payment Pending",
                                          "Claimed and Settled",
                                        ].map((x, index) => {
                                          return (
                                            <Option key={index} value={x}>
                                              {x}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                    </div>
                                  </Col>
                                )}

                                  <Col md={12} xs={12} sm={12} className='p-0'>
                                    <div className="phase-filter">
                                      <label className="control-label">
                                        ESS Opted for
                                      </label>
                                      <Select
                                        style={{ width: "100%" }}
                                        placeholder="Select ESS Opted for"
                                        onChange={this.changeFilterHandler.bind(
                                          this,
                                          "essOptedFor"
                                        )}
                                        value={this.state.filterData.essOptedFor}
                                      >
                                        <Option value="">All</Option>
                                        {(this.state.allSupportServices || []).map((x, index) => {
                                          return (
                                            <Option key={index} value={x._id}>
                                              <strong>[{x.serviceDetails.showedServiceId || "" }]</strong> {x.serviceDetails.serviceTitle || ""} <strong>({ x.count || 0})</strong>
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                    </div>
                                  </Col>

                                  {JSON.parse(readCookie("userData")).userType
                                    .name === "SIDBI" ? null : <Col md={12} xs={12} sm={12} className='p-0'>
                                    <div className="phase-filter">
                                      <label className="control-label">
                                        Scale up questionnare filled?
                                      </label>
                                      <Select
                                        style={{ width: "100%" }}
                                        placeholder="Scale Up Questionnaire"
                                        onChange={this.changeFilterHandler.bind(
                                          this,
                                          "scaleUpQuestionnaire"
                                        )}
                                        value={this.state.filterData.scaleUpQuestionnaire}
                                      >
                                        <Option value="">All</Option>
                                        {[
                                         "Yes","No",
                                        ].map((x, index) => {
                                          return (
                                            <Option key={index} value={x}>
                                              {x}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                    </div>
                                  </Col>}

                                  {JSON.parse(readCookie("userData")).userType
                                    .name === "SIDBI" ? null :    <Col md={12} xs={12} sm={12} className='p-0'>
                                <div className="phase-filter">
                                  <label className="control-label">
                                    Entrepreneur Story Verified
                                  </label>
                                  <Select
                                    style={{ width: "100%" }}
                                    placeholder="Entrepreneur Story Verified"
                                    onChange={this.changeFilterHandler.bind(
                                      this,
                                      "entrepreneurStoryVerification"
                                    )}
                                    value={
                                      this.state.filterData
                                        .entrepreneurStoryVerification
                                    }
                                  >
                                    <Option value="">All</Option>
                                    <Option value="Verified">Yes</Option>
                                    <Option value="Unverified">No</Option>
                                  </Select>
                                </div>
                              </Col>}
                              <Col md={12} xs={12} sm={12} className='p-0'>
                                <div className="phase-filter">
                                  <label className="control-label">
                                    Eligible under Loan Scheme
                                  </label>
                                  <Select
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    onChange={this.changeFilterHandler.bind(
                                      this,
                                      "loanSchemes"
                                    )}
                                    value={this.state.filterData.loanSchemeId}
                                    showSearch
                                    placeholder="Select Loan Scheme"
                                  >
                                    {/* <Option value="">All</Option> */}
                                    {this.state.allLoanSchemes.map(
                                      (scheme, schemeIndex) => {
                                        return (
                                          <Option
                                            key={schemeIndex}
                                            value={scheme._id}
                                          >
                                            {scheme.name}
                                          </Option>
                                        );
                                      }
                                    )}
                                  </Select>
                                </div>
                              </Col>

                              {/* Adding source(generated through filter) */}
                              <Col md={12} xs={12} sm={12} className='p-0'>
                                <div className="phase-filter">
                                  <label className="control-label">
                                    Source (Generated Through)
                                  </label>
                                  <Select
                                    style={{ width: "100%" }}
                                    placeholder="Generated Through Filter"
                                    onChange={this.changeFilterHandler.bind(
                                      this,
                                      "source"
                                    )}
                                    value={this.state.filterData.source}
                                  >
                                    <Option value="">All</Option>
                                    {this.state.allSources.length > 0 &&
                                      this.state.allSources.map((option, ind) => {
                                        return (
                                          <Option key={ind} value={option}>
                                            {option}
                                          </Option>
                                        );
                                      })}
                                  </Select>
                                </div>
                              </Col>
                              <Col md={12} xs={12} sm={12} className='p-0'>
                                <div className="phase-filter">
                                  <label className="control-label">
                                    Finance Type
                                  </label>
                                  <Select
                                    style={{ width: "100%" }}
                                    placeholder="Finance Type"
                                    onChange={this.changeFilterHandler.bind(
                                      this,
                                      "financeType"
                                    )}
                                    value={this.state.filterData.financeType}
                                  >
                                    <Option value="">All</Option>
                                    <Option value="Bank Loan required">
                                      Bank Loan required
                                    </Option>
                                    <Option value="100% self-financed">
                                      100% self-financed
                                    </Option>
                                  </Select>
                                </div>
                              </Col>
                              <Col md={12} xs={12} sm={12} className='p-0 pl-1'>
                                <div className="phase-filter">
                                  <label className="control-label">
                                    Person with disability (PWD)
                                  </label>
                                  <Select
                                    style={{ width: "100%" }}
                                    placeholder="Person with disability"
                                    onChange={this.changeFilterHandler.bind(
                                      this,
                                      "pwd"
                                    )}
                                    value={this.state.filterData.pwd}
                                  >
                                    <Option value="">All</Option>
                                    <Option value="Yes">Yes</Option>
                                    <Option value="No">No</Option>
                                  </Select>
                                </div>
                              </Col>
                                  {JSON.parse(readCookie("userData")).userType
                                    .name === "SIDBI" ? null : <Col md={12} xs={12} sm={12} className='p-0 pl-1'>
                                <div className="phase-filter">
                                  <label className="control-label">
                                    Has Comments
                                  </label>
                                  <Select
                                    style={{ width: "100%" }}
                                    placeholder="Has Comments"
                                    onChange={this.changeFilterHandler.bind(
                                      this,
                                      "hasComments"
                                    )}
                                    value={this.state.filterData.hasComments}
                                  >
                                    <Option value="">All</Option>
                                    <Option value="Yes">Yes</Option>
                                    <Option value="No">No</Option>
                                  </Select>
                                </div>
                              </Col>}
                                  {JSON.parse(readCookie("userData")).userType
                                    .name === "SIDBI" ? null : <Col md={12} xs={12} sm={12} className='p-0'>
                                <div className="phase-filter">
                                  <label className="control-label">
                                    Business Plan Verification
                                  </label>
                                  <Select
                                    style={{ width: "100%" }}
                                    placeholder="Business Plan Verification"
                                    onChange={this.changeFilterHandler.bind(
                                      this,
                                      "businessPlanVerification"
                                    )}
                                    value={
                                      this.state.filterData.businessPlanVerification
                                    }
                                  >
                                    <Option value="">All</Option>
                                    <Option value="Verified">Verified</Option>
                                    <Option value="Not Verified">
                                      Not Verified
                                    </Option>
                                  </Select>
                                </div>
                              </Col>}
                              <Col md={12} xs={12} sm={12} className='p-0'>
                                <div className="phase-filter">
                                  <label className="control-label">
                                    Is willing to give collateral
                                  </label>
                                  <Select
                                    style={{ width: "100%" }}
                                    placeholder="Collateral"
                                    onChange={this.changeFilterHandler.bind(
                                      this,
                                      "collateral"
                                    )}
                                    value={this.state.filterData.collateral}
                                  >
                                    <Option value="">All</Option>
                                    <Option value="Yes">Yes</Option>
                                    <Option value="No">No</Option>
                                  </Select>
                                </div>
                              </Col>
                              <Col md={12} xs={12} sm={12} className='p-0'>
                                <div className="phase-filter">
                                  <label className="control-label">
                                    Has Account in Bank
                                  </label>
                                  <Select
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    placeholder="Account in Bank"
                                    onChange={this.changeFilterHandler.bind(
                                      this,
                                      "bankAccounts"
                                    )}
                                    value={this.state.filterData.bankAccounts}
                                    showSearch
                                  // placeholder="All"
                                  >
                                    {this.state.allBanks.map((bank, bankIndex) => {
                                      return (
                                        <Option key={bankIndex} value={bank}>
                                          {bank}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </div>
                              </Col>
                                  {JSON.parse(readCookie("userData")).userType
                                    .name === "SIDBI" ? null :    <Col md={12} xs={12} sm={12} className='p-0'>
                                <div className="phase-filter">
                                  <label className="control-label">
                                    Has filed Income Tax?
                                  </label>
                                  <Select
                                    style={{ width: "100%" }}
                                    placeholder="Income Tax"
                                    onChange={this.changeFilterHandler.bind(
                                      this,
                                      "filedIncomeTax"
                                    )}
                                    value={this.state.filterData.filedIncomeTax}
                                  >
                                    <Option value="">All</Option>
                                    <Option value="Yes">Yes</Option>
                                    <Option value="No">No</Option>
                                  </Select>
                                </div>
                              </Col>}
                                  {JSON.parse(readCookie("userData")).userType
                                    .name === "SIDBI" ? null : <Col md={12} xs={12} sm={12} className='p-0'>
                                <div className="phase-filter">
                                  <label className="control-label">
                                    Mode of loan application
                                  </label>
                                  <Select
                                    style={{ width: "100%" }}
                                    placeholder="Loan Application Mode"
                                    onChange={this.changeFilterHandler.bind(
                                      this,
                                      "modeOfApplication"
                                    )}
                                    value={this.state.filterData.modeOfApplication}
                                  >
                                    <Option value="">All</Option>
                                    <Option value="Online">Online</Option>
                                    <Option value="Offline">Offline</Option>
                                  </Select>
                                </div>
                              </Col>}
                                  {JSON.parse(readCookie("userData")).userType
                                    .name === "SIDBI" ? null :       <Col md={12} xs={12} sm={12} className='p-0'>
                                <div className="phase-filter">
                                  <label className="control-label">
                                    Overall sentiment
                                  </label>
                                  <Select
                                    style={{ width: "100%" }}
                                    placeholder="Overall sentiment"
                                    onChange={this.changeFilterHandler.bind(
                                      this,
                                      "overallSentiment"
                                    )}
                                    value={this.state.filterData.overallSentiment}
                                  >
                                    <Option value="">All</Option>
                                    {allSentiments.map(
                                      (sentiment, sentimentIndex) => {
                                        return (
                                          <Option
                                            key={sentimentIndex}
                                            value={sentiment}
                                          >
                                            {sentiment}
                                          </Option>
                                        );
                                      }
                                    )}
                                  </Select>
                                </div>
                              </Col>}
                                  {JSON.parse(readCookie("userData")).userType
                                    .name === "SIDBI" ? null : <Col md={12} xs={12} sm={12} className='p-0'>
                                <div className="phase-filter">
                                  <label className="control-label">
                                    Eagerness to start the enterprise
                                  </label>
                                  <Select
                                    style={{ width: "100%" }}
                                    placeholder="Eagerness to start the enterprise"
                                    onChange={this.changeFilterHandler.bind(
                                      this,
                                      "tentativeHorizonToCloseThis"
                                    )}
                                    value={
                                      this.state.filterData
                                        .tentativeHorizonToCloseThis
                                    }
                                  >
                                    <Option value="">All</Option>
                                    {allHorizons.map((horizon, horizonIndex) => {
                                      return (
                                        <Option key={horizonIndex} value={horizon}>
                                          {horizon}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </div>
                              </Col>}
                                  {JSON.parse(readCookie("userData")).userType
                                    .name === "SIDBI" ? null :   <Col md={12} xs={12} sm={12} className='p-0'>
                                <div className="phase-filter">
                                  <label className="control-label">
                                    Strength Rating
                                  </label>
                                  <Select
                                    style={{ width: "100%" }}
                                    placeholder="Strength Rating"
                                    onChange={this.changeFilterHandler.bind(
                                      this,
                                      "strengthRating"
                                    )}
                                    value={this.state.filterData.strengthRating}
                                  >
                                    <Option value="">All</Option>
                                    <Option value="Null">Not Available</Option>
                                    <Option value="Very Good">
                                      Very Good (more than 75%)
                                    </Option>
                                    <Option value="Good">Good (65% to 75%)</Option>
                                    <Option value="Average">
                                      Average (50% to 65%)
                                    </Option>
                                    <Option value="Poor">
                                      Poor (less than 50%)
                                    </Option>
                                  </Select>
                                </div>
                              </Col>}
                              <div className="d-flex filter-btn-container mt10">
                                <div
                                  className="reset-btn mr10"
                                  onClick={this.resetFilter}
                                >
                                  Reset
                                </div>
                              </div>
                            </Row>
                          </div>
                        ) : null}

                        {hasFilter &&
                          this.props.type !== "swavalamban-connect-kendra" &&
                          this.props.view !== "tab" ? (
                          <Row className="mt5 mb5 show-filters-container">
                            {this.state.filterData.fromDate ? (
                              <span>
                                From Date:{" "}
                                <span>
                                  {moment(
                                    this.state.filterData.fromDate
                                  ).format("DD-MM-YYYY")}
                                </span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "fromDate",
                                    null
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.toDate ? (
                              <span>
                                To Date:{" "}
                                <span>
                                  {moment(this.state.filterData.toDate).format(
                                    "DD-MM-YYYY"
                                  )}
                                </span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "toDate",
                                    null
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.state ? (
                              <span>
                                State:{" "}
                                <span>{this.state.filterData.state}</span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "state",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.district.length ? (
                              <span>
                                Districts:{" "}
                                <span>
                                  {this.state.filterData.district.join(", ")}
                                </span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "district",
                                    []
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.bPlanCompleted === true ? (
                              <span>
                                Business Plan: <span>Completed</span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "bPlanCompleted",
                                    null
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.loanAppApplied === true ? (
                              <span>
                                Loan Application Status: <span>Applied</span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "loanAppApplied",
                                    null
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.eSetupInProgress === true ? (
                              <span>
                                Enterprise Status: <span>In Progress</span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "eSetupInProgress",
                                    null
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.userType ? (
                              <span>
                                {"User Type: "}
                                <span>
                                  {this.state.allUserTypes.map(
                                    (userType, i) => {
                                      if (
                                        userType.customUrl ===
                                        this.state.filterData.userType
                                      ) {
                                        return (
                                          <span index={i}>{userType.name}</span>
                                        );
                                      } else return null;
                                    }
                                  )}
                                </span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "userType",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.assignedTo ? (
                              <span>
                                {"Assigned To: "}
                                <span>
                                  {this.state.allAssignedTo.map(
                                    (assignedTo, i) => {
                                      if (
                                        assignedTo._id ===
                                        this.state.filterData.assignedTo
                                      ) {
                                        return (
                                          <span index={i}>
                                            {this.state.filterData.userType ===
                                              "swavalamban-connect-kendra"
                                              ? assignedTo.ngoName
                                              : assignedTo.fullName +
                                              " (" +
                                              assignedTo.email +
                                              ")"}
                                          </span>
                                        );
                                      } else return null;
                                    }
                                  )}
                                </span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "assignedTo",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.taggedTo ? (
                              <span>
                                {"Tagged To: "}
                                <span>
                                  {this.state.filterData.taggedTo === "None"
                                    ? "None"
                                    : this.state.allSaarthis.map(
                                      (taggedTo, i) => {
                                        if (
                                          taggedTo._id ===
                                          this.state.filterData.taggedTo
                                        ) {
                                          return (
                                            <span index={i}>
                                              {taggedTo.fullName +
                                                " (" +
                                                taggedTo.email +
                                                ")"}
                                            </span>
                                          );
                                        } else return null;
                                      }
                                    )}
                                </span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "taggedTo",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.cluster &&
                              this.state.filterData.cluster.length ? (
                              <span>
                                {"Cluster: "}
                                {this.state.swavalambanFellows.map(
                                  (fellow, i) => {
                                    if (
                                      fellow._id ===
                                      this.state.filterData.cluster
                                    ) {
                                      return (
                                        <span
                                          index={i}
                                          title={`${fellow.fullName} (${fellow.email})`}
                                        >
                                          {fellow.fullName}
                                        </span>
                                      );
                                    } else return null;
                                  }
                                )}
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "cluster",
                                    []
                                  )}
                                ></i>
                              </span>
                            ) : null}
                                {this.state.filterData.bplanAge ? (
                                  <span>
                                    {"B-Plan Age above: "}
                                    <span>
                                      {this.state.filterData.bplanAge} day(s)
                                    </span>
                                    <i
                                      className="remove-filter-btn fa fa-times"
                                      onClick={this.removeFilter.bind(
                                        this,
                                        "bplanAge",
                                        ""
                                      )}
                                    ></i>
                                  </span>
                                ) : null}

                            {this.state.filterData.minLoanAmount ? (
                              <span>
                                {"Min Loan Amount: "}
                                <span>
                                  {this.state.filterData.minLoanAmount}
                                </span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "minLoanAmount",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.maxLoanAmount ? (
                              <span>
                                {"Max Loan Amount: "}
                                <span>
                                  {this.state.filterData.maxLoanAmount}
                                </span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "maxLoanAmount",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.source ? (
                              <span>
                                {"Source (Generated Through): "}
                                <span>{this.state.filterData.source}</span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "source",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.idea ? (
                              <span>
                                Business Idea:{" "}
                                <span>{this.state.filterData.idea}</span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "idea",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.phase ? (
                              <span>
                                Phase:{" "}
                                <span>{this.state.filterData.phase}</span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "phase",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}

                            {this.state.filterData.audit ? (
                              <span>
                                Audit Status:{" "}
                                <span>{this.state.filterData.audit}</span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "audit",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.paymentClaim ? (
                              <span>
                                Payment Claim Status:{" "}
                                <span>
                                  {this.state.filterData.paymentClaim}
                                </span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "paymentClaim",
                                    ""
                                  )}
                                ></i>
                              </span>
                                ) : null}
                                {selectedEssString ? (
                                  <span>
                                    ESS opted for:{" "}
                                    <span>
                                      {selectedEssString}
                                    </span>
                                    <i
                                      className="remove-filter-btn fa fa-times"
                                      onClick={this.removeFilter.bind(
                                        this,
                                        "essOptedFor",
                                        ""
                                      )}
                                    ></i>
                                  </span>
                                ) : null}
                                {this.state.filterData.scaleUpQuestionnaire ? (
                                  <span>
                                    Scale up questionnaire filled:{" "}
                                    <span>
                                      {this.state.filterData.scaleUpQuestionnaire}
                                    </span>
                                    <i
                                      className="remove-filter-btn fa fa-times"
                                      onClick={this.removeFilter.bind(
                                        this,
                                        "scaleUpQuestionnaire",
                                        ""
                                      )}
                                    ></i>
                                  </span>
                                ) : null}
                            {this.state.filterData
                              .entrepreneurStoryVerification ? (
                              <span>
                                Story Verification Status:{" "}
                                <span>
                                  {
                                    this.state.filterData
                                      .entrepreneurStoryVerification
                                  }
                                </span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "entrepreneurStoryVerification",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                                {
                                  this.state.filterData.sidbiMilestone ? <span>
                                    SIDBI Payment Milestone:{" "}
                                    <span>
                                      {
                                        this.state.filterData
                                          .sidbiMilestone
                                      }
                                    </span>
                                    <i
                                      className="remove-filter-btn fa fa-times"
                                      onClick={this.removeFilter.bind(
                                        this,
                                        "sidbiMilestone",
                                        ""
                                      )}
                                    ></i>
                                  </span> : null
                            }

                            {this.state.filterData.loanScheme &&
                              this.state.filterData.loanScheme.length ? (
                              <span>
                                Loan Scheme:{" "}
                                {/* <span>{this.state.filterData.loanScheme}</span> */}
                                <span>
                                  {" "}
                                  {this.state.filterData.loanScheme
                                    .map((x) => x.name)
                                    .join(", ")}
                                </span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "loanSchemes",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.gender ? (
                              <span>
                                Gender:{" "}
                                <span>{this.state.filterData.gender}</span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "gender",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.category ? (
                              <span>
                                Category:{" "}
                                <span>{this.state.filterData.category}</span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "category",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.financeType ? (
                              <span>
                                Finance Type:{" "}
                                <span>{this.state.filterData.financeType}</span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "financeType",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.pwd ? (
                              <span>
                                PWD: <span>{this.state.filterData.pwd}</span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "pwd",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.hasComments ? (
                              <span>
                                Has Comments:{" "}
                                <span>{this.state.filterData.hasComments}</span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "hasComments",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.businessPlanVerification ? (
                              <span>
                                Business Plan Verification:{" "}
                                <span>
                                  {
                                    this.state.filterData
                                      .businessPlanVerification
                                  }
                                </span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "businessPlanVerification",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.collateral ? (
                              <span>
                                Collateral:{" "}
                                <span>{this.state.filterData.collateral}</span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "collateral",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.bankAccounts &&
                              this.state.filterData.bankAccounts.length ? (
                              <span>
                                Has Account in Bank:{" "}
                                <span>
                                  {this.state.filterData.bankAccounts.join(
                                    ", "
                                  )}
                                </span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "bankAccounts",
                                    []
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.filedIncomeTax ? (
                              <span>
                                Filed Income Tax:{" "}
                                <span>
                                  {this.state.filterData.filedIncomeTax}
                                </span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "filedIncomeTax",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.modeOfApplication ? (
                              <span>
                                Mode of Loan Application:{" "}
                                <span>
                                  {this.state.filterData.modeOfApplication}
                                </span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "modeOfApplication",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.overallSentiment ? (
                              <span>
                                Overall Sentiment:{" "}
                                <span>
                                  {this.state.filterData.overallSentiment}
                                </span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "overallSentiment",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData
                              .tentativeHorizonToCloseThis ? (
                              <span>
                                Eagerness to start the enterprise:{" "}
                                <span>
                                  {
                                    this.state.filterData
                                      .tentativeHorizonToCloseThis
                                  }
                                </span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "tentativeHorizonToCloseThis",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.strengthRating ? (
                              <span>
                                Strength Rating:{" "}
                                <span>
                                  {this.state.filterData.strengthRating}
                                </span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "strengthRating",
                                    ""
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.maturityStatus.length ? (
                              <span>
                                Maturity Status:{" "}
                                <span>
                                  {this.state.filterData.maturityStatus.join(
                                    ", "
                                  )}
                                </span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "maturityStatus",
                                    []
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {this.state.filterData.capitalFulfilledStatus
                              .length ? (
                              <span>
                                Capital Fulfilled Status:{" "}
                                <span>
                                  {this.state.filterData.capitalFulfilledStatus.join(
                                    ", "
                                  )}
                                </span>
                                <i
                                  className="remove-filter-btn fa fa-times"
                                  onClick={this.removeFilter.bind(
                                    this,
                                    "capitalFulfilledStatus",
                                    []
                                  )}
                                ></i>
                              </span>
                            ) : null}
                            {!this.props.sidebarMinimized &&
                              this.state.filterData.capitalFulfilledStage ? (
                              <span>
                                Capital Fulfilled Stages
                                {this.state.filterData.capitalFulfilledStages &&
                                  this.state.filterData.capitalFulfilledStages
                                    .length ? (
                                  <span>
                                    <span>
                                      {": "}
                                      {this.state.filterData.capitalFulfilledStages.join(
                                        ", "
                                      )}
                                    </span>
                                    <i
                                      className="remove-filter-btn fa fa-times"
                                      onClick={this.removeFilter.bind(
                                        this,
                                        "capitalFulfilledStages",
                                        []
                                      )}
                                    ></i>
                                  </span>
                                ) : (
                                  <span>
                                    <i
                                      className="remove-filter-btn fa fa-times"
                                      onClick={this.removeFilter.bind(
                                        this,
                                        "capitalFulfilledStage",
                                        false
                                      )}
                                    ></i>
                                  </span>
                                )}
                              </span>
                            ) : null}
                          </Row>
                        ) : null}
                        <BootstrapTable
                          {...props.baseProps}
                          id="leads-page-table"
                          rowStyle={this.rowClassNameFormat}
                          remote={{ pagination: true }}
                          onTableChange={this.onTableChange}
                          {...paginationTableProps}
                          selectRow={selectRow}
                        />
                        <PaginationListStandalone {...paginationProps} />
                        <SizePerPageDropdownStandalone {...paginationProps} />
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            ) : null}
            </Col>
          </LoadingOverlay>

        </div>
        {this.state.showImportModal ? (
          this.state.importing ? (
            <Modal
              className="upload-candidates-modal"
              show={this.state.showImportModal}
              size="lg"
            >
              <Modal.Body>
                <i className="fa fa-cog fa-spin fa-3x fa-fw" />
              </Modal.Body>
            </Modal>
          ) : (
            <Modal
              className="upload-candidates-modal"
              show={this.state.showImportModal}
              size="lg"
            >
              <Modal.Header>
                <Modal.Title className="modal-header-custom">
                  Import Candidate profiles from a CSV
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="mb20">
                <Row>
                  <Col md={12}>
                    <label className="control-label is-imp">
                      Upload Candidates File (Only csv, xlsx accepted)
                    </label>
                    <input
                      className="form-control upload-file-input"
                      type="file"
                      style={{ width: "100%" }}
                      placeholder="Upload File"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx, .xls"
                      onChange={this.changeUploadHandler.bind(this)}
                    />
                    {/* <note className="note">Note: Kindly upload the csv with the following header rows- Name, Mobile*, Source*, Skills*, Pincode (Mandatory rows are marked with *)</note> */}
                    <span className="note">
                      Note: Kindly upload the csv file with the following header
                      rows- Generated Through, State*, Name*, Address*,
                      Pincode*, Mobile*, Alternative Mobile, Email, Gender*,
                      Category*, Qualification*, Date of Birth*, Block*,
                      District*, Industry Type, Business Idea, Idea Details,
                      Self Investment, Loan Amount, Bank Name, IFSC, Comments,
                      Follow up date (Mandatory rows are marked with *).{" "}
                    </span>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer className="footer-center">
                <Button
                  className="btn btn-danger brand-color-background"
                  onClick={this.hideImportCandidatesModal}
                >
                  Cancel
                </Button>
                <Button
                  className="btn btn-warning brand-color-background"
                  disabled={!this.state.candidatesImportFile}
                  onClick={this.submitCandidates}
                >
                  Import
                </Button>
              </Modal.Footer>
            </Modal>
          )
          ) : null}

      </div>
    );
  }

  changeUploadHandler = (value) => {
    let candidatesImportFile = value.target.files[0];
    this.setState({ candidatesImportFile });
  };

  hideImportCandidatesModal = () => {
    this.setState({
      showImportModal: false,
      importStage: "",
      candidatesImportFile: null,
    });
  };

  submitCandidates = () => {
    this.setState({ importing: true });
    let { candidatesImportFile } = this.state;
    if (candidatesImportFile) {
      let formData = new FormData();
      formData.append("candidatesFile", candidatesImportFile);
      fetch(process.env.REACT_APP_API_URL + "/api/v1/swati-leads/upload", {
        method: "POST",
        headers: {
          "x-auth-token": JSON.parse(readCookie("access_token")),
        },
        body: formData,
      })
        .then((data) => data.json())
        .then((data) => {
          // this.setState({ importing: false });
          if (data.status) {
            let htmlToShow = <></>;
            if (data.results.disqualified) {
              htmlToShow = <Accordion> <Card bg="success">
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="6">
                    Some leads were successfully diqualified - (
                    {data?.results?.disqualified ? data.results.disqualified.length : 0}
                    )
                  </Accordion.Toggle>
                </Card.Header>

                <Accordion.Collapse eventKey="6">
                  <Card.Body>
                    {data?.results?.disqualified.length
                      ? <>{data.results.disqualified.join(", ")}           <CopyToClipboard
                        text={data.results.disqualified.join(", ")}
                        onCopy={() => {

                          this.notifySuccess("Copied");
                        }}
                      >
                        <Button
                        //  onClick={() => {
                        //    incrementRow(row.id, "English");
                        //  }}
                        >
                          Copy to clipboard
                        </Button>
                      </CopyToClipboard>
                      </> : "None of the numbers were disqualified!"}
                  </Card.Body>
                </Accordion.Collapse>
              </Card></Accordion>
            }
            else if (data.results.bplanUpdations) {
              htmlToShow = <Accordion> <Card bg="success">
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="7">
                    B-Plan links updated for some leads - (
                    {data?.results?.bplanUpdations ? data.results.bplanUpdations.length : 0}
                    )
                  </Accordion.Toggle>
                </Card.Header>

                <Accordion.Collapse eventKey="7">
                  <Card.Body>
                    {data?.results?.bplanUpdations.length
                      ? <>{data.results.bplanUpdations.join(", ")}           <CopyToClipboard
                        text={data.results.bplanUpdations.join(", ")}
                        onCopy={() => {

                          this.notifySuccess("Copied");
                        }}
                      >
                        <Button
                        //  onClick={() => {
                        //    incrementRow(row.id, "English");
                        //  }}
                        >
                          Copy to clipboard
                        </Button>
                      </CopyToClipboard>
                      </> : "No B-plan links to upload!"}
                  </Card.Body>
                </Accordion.Collapse>
              </Card></Accordion>
            }
            else {
              htmlToShow = (
                <Accordion>
                  <Card bg="success">
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="6">
                        Successfully Created Profiles - (
                        {data?.results?.success ? data.results.success.length : 0}
                        )
                      </Accordion.Toggle>
                    </Card.Header>

                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        {data?.results?.success.length
                          ? <>{data.results.success.join(", ")}<CopyToClipboard
                            text={data.results.success.join(", ")}
                            onCopy={() => {

                              this.notifySuccess("Copied");
                            }}
                          >
                            <Button
                            //  onClick={() => {
                            //    incrementRow(row.id, "English");
                            //  }}
                            >
                              Copy to clipboard
                            </Button>
                          </CopyToClipboard></>
                          : "None of the numbers were uploaded!"}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  {/* updatedNumbers */}
                  {data?.results?.updatedNumbers.length ? (
                    <Card bg="info">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="4">
                          Rows with updated numbers - (
                          {data?.results?.updatedNumbers
                            ? data.results.updatedNumbers.length
                            : 0}
                          )
                        </Accordion.Toggle>
                      </Card.Header>

                      <Accordion.Collapse eventKey="4">
                        <Card.Body>
                          {data?.results?.updatedNumbers
                            ? <>{data.results.updatedNumbers.join(", ")}<CopyToClipboard
                              text={data.results.updatedNumbers.join(", ")}
                              onCopy={() => {

                                this.notifySuccess("Copied");
                              }}
                            >
                              <Button
                              //  onClick={() => {
                              //    incrementRow(row.id, "English");
                              //  }}
                              >
                                Copy to clipboard
                              </Button>
                            </CopyToClipboard></>
                            : "No numbers updated!"}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ) : null}
                  <hr />
                  {data?.results?.noNumbers.length ? (
                    <Card bg="danger">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                          Rows with no/invalid mobile numbers! - (
                          {data?.results?.noNumbers
                            ? data.results.noNumbers.length
                            : 0}
                          )
                        </Accordion.Toggle>
                      </Card.Header>
                    </Card>
                  ) : null}
                  {/* repeatNumbers */}
                  {/* {data?.results?.repeatNumbers.length ? (
                  <Card bg="danger">
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="4">
                        Rows with repeated numbers - (
                        {data?.results?.repeatNumbers
                          ? data.results.repeatNumbers.length
                          : 0}
                        )
                      </Accordion.Toggle>
                    </Card.Header>

                    <Accordion.Collapse eventKey="4">
                      <Card.Body>
                        {data?.results?.repeatNumbers
                          ? data.results.repeatNumbers.join(", ")
                          : "No numbers repeated:)"}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ) : null} */}

                  {data?.results?.invalidMandatoryFields.length ? (
                    <Card bg="danger">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                          Invalid Mandatory Fields - (
                          {data?.results?.invalidMandatoryFields
                            ? data.results.invalidMandatoryFields.length
                            : 0}
                          )
                        </Accordion.Toggle>
                      </Card.Header>

                      <Accordion.Collapse eventKey="2">
                        <Card.Body>
                          {data?.results?.invalidMandatoryFields
                            ? <>{data.results.invalidMandatoryFields.join(", ")}<CopyToClipboard
                              text={data.results.invalidMandatoryFields.join(", ")}
                              onCopy={() => {

                                this.notifySuccess("Copied");
                              }}
                            >
                              <Button
                              //  onClick={() => {
                              //    incrementRow(row.id, "English");
                              //  }}
                              >
                                Copy to clipboard
                              </Button>
                            </CopyToClipboard>
                            </> : "No errors:)"}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ) : null}
                  {data?.results?.invalidBankFields.length ? (
                    <Card bg="danger">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="3">
                          Invalid Bank Details Fields - (
                          {data?.results?.invalidBankFields
                            ? data.results.invalidBankFields.length
                            : 0}
                          )
                        </Accordion.Toggle>
                      </Card.Header>

                      <Accordion.Collapse eventKey="3">
                        <Card.Body>
                          {data?.results?.invalidBankFields
                            ? <>{data.results.invalidBankFields.join(", ")}<CopyToClipboard
                              text={data.results.invalidBankFields.join(", ")}
                              onCopy={() => {

                                this.notifySuccess("Copied");
                              }}
                            >
                              <Button
                              //  onClick={() => {
                              //    incrementRow(row.id, "English");
                              //  }}
                              >
                                Copy to clipboard
                              </Button>
                            </CopyToClipboard>
                            </> : "No errors:)"}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ) : null}
                </Accordion>
              );

            }
            reactswal({
              content: htmlToShow,
              title: data.title,
              icon: "success",
            })
              // .then();
              // Swal.fire({
              //   title: data.title,
              //   html: htmlToShow,
              //   type: data.type || 'success'
              // })
              .then((res) => {
                if (data.status) {
                  this.getAllLeads();
                  this.hideImportCandidatesModal();
                }
              });
          } else {
            Swal.fire({
              title: "Error in exporting lead profiles!",
              html: data.errorMessage,
              type: "error",
            });
          }
        });
      this.setState({ importing: false });
    } else {
      Swal.fire(
        "Oops! Select a file to upload first",
        "Import failed",
        "error"
      );
    }
  };
  showRedirectModal = (id) => {
    // Swal.fire({
    //   title: 'Are you interacting with this person?',
    //   html: 'Do you want to add notes of this conversation as comments?',
    //   showCloseButton: true,
    //   showCancelButton: true,
    //   focusConfirm: false,
    //   confirmButtonText: 'Yes, I wish to add notes',
    //   confirmButtonAriaLabel: 'Yes, I wish to add notes',
    //   cancelButtonText: 'No, I do not have anything to add',
    //   cancelButtonAriaLabel: 'No, I do not have anything to add'
    // }).then((result) => {
    //   if (result.value) {
    //     window.location.pathname = '/lead/' + id + '/comments';
    //   } else if(result.dismiss === 'cancel') {
    //   	window.location.pathname = '/lead/' + id;
    //   }
    // });
  };
}
