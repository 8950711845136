import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
  status: "",
  allDistricts: [],
  loading: false,
  error: "",
};


export const getAllDistrictsOfAState = createAsyncThunk("getAllDistrictsOfAState", async (statename) => {
  try {

    if (!statename) return [];
    const res = await axios.get(`${process.env.REACT_APP_SWATI_BASE_URL}/api/v1/learning-module-leads/districts?state=${statename}`, {
      // headers: {
      //   authorization: `Bearer ${currentToken}`,
      // },
    });
    console.log("🚀 ~ getAllDistrictsOfAState ~ res.data.Districts:", res.data.districts)
    return res.data.districts;

  } catch (err) {
    console.log("err in getting Districts", err);
    return err.response.data;
  }
});


const getAllDistrictsSlice = createSlice({
  name: "getAllDistrictsOfAState",
  initialState,
  reducers: {},

  extraReducers: {
    [getAllDistrictsOfAState.pending]: (state, action) => {
      state.loading = true;

      state.error = "";
    },

    [getAllDistrictsOfAState.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        state.allDistricts = payload;

        state.message = payload.message;
      }
    },

    [getAllDistrictsOfAState.rejected]: (state, { message }) => {
      state.error = message;
      state.loading = false;
    },
  },
});


export default getAllDistrictsSlice.reducer;
