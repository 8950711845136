import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  status: "",
  farmersData: [],
  loading: false,
  error: "",
  message: "",
};

export const getAllFarmers = createAsyncThunk("getAllFarmers", async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_HUMBLEBEE_API_URL}/farmers`);
    // console.log("res.data from Farmers ==> ", res.data);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
});

const getAllFarmersSlice = createSlice({
  name: "getAllFarmers",
  initialState,
  reducers: {},

  extraReducers: {
    [getAllFarmers.pending]: (state, action) => {
      state.loading = true;

      state.error = "";
    },

    [getAllFarmers.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        state.farmersData = payload.farmers;
      }
    },

    [getAllFarmers.rejected]: (
      state,
      //@ts-ignore
      { payload: { message, error } }
    ) => {
      state.error = error;
      state.message = message;
      state.loading = false;
    },
  },
});

export default getAllFarmersSlice.reducer;
