import styled from "styled-components";

export const InputGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Input = styled.input`
  padding: 4px;
  border-radius: 8px;
  outline: none;
  border: 1px solid gray;
  ::placeholder {
    margin: 4px;
  }

  :focus {
    ::placeholder {
      font-weight: bold;
    }
  }
`;

export const Labels = styled.label`
  height: 20px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* or 100% */

  letter-spacing: 0.01em;
  margin-bottom: 10px;
  color: #000000;
  margin-top: 20px;
`;
