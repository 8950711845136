import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Styles from "./style";
import { getAllBatches } from "../../../reduxLogic/reducers/batches/getAllBatchesSlice";
import { toast } from "react-toastify";
import { deleteBatch } from "../../../reduxLogic/reducers/batches/deleteBatchSlice";

const DeleteModal = (props) => {
  const { idToDelete, setDeleteModal } = props;
  let dispatch = useDispatch();

  const handleDeleteBatch = () => {
    dispatch(deleteBatch(idToDelete))
      .then(() => dispatch(getAllBatches()))
      .then(() => setDeleteModal(false))
      .then(() => toast.warning(`Batch with id ${idToDelete} deleted`));
  };

  const handleCloseModal = () => {
    setDeleteModal(false);
  };
  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        <Styles.InnerContainer>
          <h3>Are you sure you want to delete?</h3>
          <Styles.ButtonDiv>
            <Styles.DeleteButton onClick={handleDeleteBatch}>
              Yes
            </Styles.DeleteButton>
            <Styles.CloseButton onClick={handleCloseModal}>
              No
            </Styles.CloseButton>
          </Styles.ButtonDiv>
        </Styles.InnerContainer>
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  );
};

export default DeleteModal;
