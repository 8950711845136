import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import styled from "styled-components";

import { getClusterByProject } from "../reduxLogic/reducers/clusters/getClusterByProjectSlice";
import { useDispatch, useSelector } from "react-redux";
const cookies = new Cookies();
const token = cookies.get("access_token"); // Get token from storage
const project_id = cookies.get("project_id");

// Styled components
const Container = styled.div`
  margin-left: 20%;
  margin-top: 4%;
`;

const TopTabs = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const TopTab = styled.button`
  padding: 10px 20px;
  background-color: lightgray;
  border: none;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &.active {
    background-color: #ff8701;
    color: white;
  }
`;

const TabButton = styled.button`
  padding: 10px 15px;
  margin-right: 5px;
  margin-left: 2%;
  margin-bottom: 2%;
  background-color: lightgray;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &.active {
    background-color: #ff8701;
    color: white;
  }
`;

const TabContent = styled.div`
  margin-left: 2%;
`;

const SurveyTabs = () => {
  const [surveyData, setSurveyData] = useState([]);
  const [userData, setUserData] = useState({});
  const [activeTab, setActiveTab] = useState("profile");
  const [surveyActiveTab, setSurveyActiveTab] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getClusterByProject(project_id));
  }, [dispatch]);

  const { clusterViaProjectData } = useSelector(
    (state) => state.clusterViaProjectData
  );

  console.log("clusterData", clusterViaProjectData);

  const { _id } = useParams();

  const getUserSurvey = async (id) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const res = await axios.get(
        `${process.env.REACT_APP_API_APP_FIELD_URL}/surveys/user/${id}`,
        config
      );
      const stages = res.data.survey.stages || [];
      const sortedStages = stages.sort((a, b) =>
        a.stage_name.localeCompare(b.stage_name)
      );
      setSurveyData(sortedStages);
      if (sortedStages.length > 0) {
        setSurveyActiveTab(sortedStages[0].stage_name);
      }
    } catch (error) {
      console.log("Error in getting survey data:", error);
    }
  };

  const getUser = async (id) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          source: "humblebee_admin",
        },
      };
      const res = await axios.get(
        `${process.env.REACT_APP_API_APP_USER_URL}/user/user-id-details/${id}`,
        config
      );
      setUserData(res.data.user);
    } catch (error) {
      console.log("Error in getting user data:", error);
    }
  };

  useEffect(() => {
    getUserSurvey(_id);
    getUser(_id);
  }, [_id]);

  const renderUserProfile = () => (
    <div>
      <h2>Profile</h2>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <img
          style={{
            width: "150px",
            height: "150px",
            objectFit: "cover",
            borderRadius: "50%",
            marginBottom: "20px",
          }}
          src={userData?.profilePictureUrl}
          alt="Profile Pic"
        />
      </div>
      <p>
        <strong>Name:</strong> {userData?.name}
      </p>
      <p>
        <strong>Mobile Number:</strong> {userData?.mobileNumber}
      </p>
      <p>
        <strong>Gender:</strong> {userData?.gender}
      </p>
      <p>
        <strong>Address:</strong> {userData?.fulladdress}
      </p>
      <p>
        <strong>Roles:</strong> {userData?.userRoles?.map((role) => role + ", ")}
      </p>

      {/* <p> Existing Cluster: {userData?.fieldViewData[0]?.cluster_id}</p> */}
      <label style={{ marginRight: "10px" }}>Select Cluster: {"    "}</label>
      <select
        name="cluster"
        value={userData?.cluster}
        onChange={(e) => setUserData({ ...userData, cluster: e.target.value })}
      >
        <option value="">Select Cluster</option>
        {clusterViaProjectData?.map((cluster) => (
          <option key={cluster.clusterId} value={cluster._id}>
            {cluster.projectId.name + "  -  " + cluster.clusterName}
          </option>
        ))}
      </select>
      <br />
      <br />
      <button
        style={{
          backgroundColor: "#ff8701",
          color: "white",
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
        }}
        onClick={() => {
          const config = {
            headers: {
              Authorization: "Bearer " + token,
              source: "humblebee_admin",
            },
          };
          axios
            .post(
              `${process.env.REACT_APP_API_APP_FIELD_URL}/clusters/clusters/add-lead`,
              {
                user_id: _id,
                cluster_id: userData.cluster,
                project_id: project_id,
              },
              config
            )
            .then((res) => {
              console.log(res);
              alert("User updated successfully");
            })
            .catch((err) => {
              console.log(err);
              alert("Error updating user");
            });
        }}
      >
        Update User
      </button>
    </div>
  );

  // Function to render survey form data
  const renderFormData = (data) => {
    const renderValue = (value, key) => {
      if (key === "photos" || key === "Otherphotos") {
        // Split the comma-separated URLs into an array
        var urls;
        //check if value contains comma
        if (value.includes(",")) {
          urls = value.split(",");
        } else {
          urls = value;
        }
        console.log(urls);
        // Render each URL as an image
        return (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {urls.map((url, index) => (
              <img
                key={index}
                src={url} // Trim any extra spaces
                alt={`Photo ${index + 1}`}
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "cover",
                  margin: "10px",
                }}
              />
            ))}
          </div>
        );
      } else if (typeof value === "object" && value !== null) {
        if (Array.isArray(value)) {
          return value.join(", "); // Render arrays as comma-separated values
        } else {
          // Recursively render objects as key-value pairs
          return (
            <div style={{ marginLeft: "20px" }}>
              {Object.entries(value).map(([subKey, subValue]) => (
                <div key={subKey}>
                  <strong>{subKey}:</strong> {renderValue(subValue, subKey)}
                </div>
              ))}
            </div>
          );
        }
      } else {
        // Render primitive values directly (string, number, boolean, etc.)
        return value.toString();
      }
    };

    return (
      <div>
        {Object.entries(data).map(([key, value]) => (
          <div key={key} style={{ marginBottom: "10px" }}>
            <strong>{key.toUpperCase()}:</strong> {renderValue(value, key)}
          </div>
        ))}
      </div>
    );
  };

  return (
    <Container>
      {/* Top-level tabs for User Profile and Survey */}
      <TopTabs>
        <TopTab
          className={activeTab === "profile" ? "active" : ""}
          onClick={() => setActiveTab("profile")}
        >
          User Profile
        </TopTab>
        <TopTab
          className={activeTab === "survey" ? "active" : ""}
          onClick={() => setActiveTab("survey")}
        >
          Survey
        </TopTab>
      </TopTabs>

      <TabContent>
        {activeTab === "profile" && renderUserProfile()}

        {activeTab === "survey" && (
          <div>
            <div style={{ marginTop: "2%" }}>
              {surveyData.map((stage) => (
                <TabButton
                  key={stage._id}
                  className={
                    surveyActiveTab === stage.stage_name ? "active" : ""
                  }
                  onClick={() => setSurveyActiveTab(stage.stage_name)}
                >
                  {stage.stage_name}
                </TabButton>
              ))}
            </div>

            <TabContent>
              {!surveyData.length && <h2>No survey data available</h2>}
              {surveyData.map((stage) => {
                if (stage.stage_name === surveyActiveTab) {
                  return (
                    <div key={stage._id}>
                      <h2 style={{ fontWeight: "bold" }}>{stage.stage_name}</h2>
                      {renderFormData(stage.form_data)}
                    </div>
                  );
                }
                return null;
              })}
            </TabContent>
          </div>
        )}
      </TabContent>
    </Container>
  );
};

export default SurveyTabs;
