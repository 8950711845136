import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";

import Cookies from "universal-cookie";

const cookies = new Cookies();
const currentToken = cookies.get("access_token");

const initialState = {
  singleProject: {},
  loading: true,
  error: "",
  message: "",
};

export const createCluster = createAsyncThunk(
  "createCluster",
  async (body, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_HUMBLEBEE_API_URL}/projects/clusters/create`,
        body,
        {
          headers: {
            authorization: `Bearer ${currentToken}`,
          },
        }
      );
      console.log("body from createCluster ", body);
      console.log("res.data from createCluster  ", res.data);
      return res.data;
    } catch (error) {
      //@ts-ignore
      console.log("error in createCluster => ", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetMessageinClusterCreation = createAction(
  "resetMessageinClusterCreation"
);

export const resetErrorinClusterCreation = createAction(
  "resetErrorinClusterCreation"
);

const clusterCreationSlice = createSlice({
  name: "createCluster",
  initialState,
  reducers: {},
  extraReducers: {
    //@ts-ignore
    [createCluster.pending]: (state, action) => {
      state.loading = true;
    },
    //@ts-ignore
    [createCluster.fulfilled]: (
      //@ts-ignore
      state,
      //@ts-ignore
      { payload: { error, singleCluster, message } }
    ) => {
      state.loading = false;
      if (error) {
        state.error = error;
        console.log("this is error in fullfiled project creation", error);
      } else {
        state.singleCluster = singleCluster;
        state.message = message;
      }
    },
    //@ts-ignore
    [createCluster.rejected]: (
      state,
      //@ts-ignore
      { payload: { message, error } }
    ) => {
      state.error = error;
      state.message = message;
    },
    [resetMessageinClusterCreation]: (state) => {
      state.error = "";
    },
  },
});

export default clusterCreationSlice.reducer;
