import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";
const currentToken = localStorage.getItem("humblebeeAuthToken");
const initialState = {
  singleBeeMitra: {},
  loading: true,
  error: "",
  message: "",
};

export const resetMessageinEditBeeMitra = createAction(
  "resetMessageinEditBeeMitra"
);
export const resetErrorinEditBeeMitra = createAction(
  "resetErrorinEditBeeMitra"
);

export const editBeeMitra = createAsyncThunk(
  "editBeeMitra",
  async ({ editId, beeMitraData }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_HUMBLEBEE_API_URL}/bee-mitras/${editId}`,
        beeMitraData,
        {
          headers: {
            authorization: `Bearer ${currentToken}`,
            Accept: "/",
           
          },
        }
      );
   
      return res.data;
    } catch (error) {
      console.log(error);
      //@ts-ignore
      return rejectWithValue(error.response.data);
    }
  }
);

const editBeeMitraSlice = createSlice({
  name: "editBeeMitra",
  initialState,
  reducers: {},
  extraReducers: {
    //@ts-ignore
    [editBeeMitra.pending]: (state, action) => {
      state.loading = true;
    },
    //@ts-ignore
    [editBeeMitra.fulfilled]: (
      //@ts-ignore
      state,
      //@ts-ignore
      { payload: { error, singleBeeMitra, message } }
    ) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        state.message = message;
      }
    },
    //@ts-ignore
    [editBeeMitra.rejected]: (
      state,
      //@ts-ignore
      { payload: { message, error } }
    ) => {
      state.error = error;
      state.message = message;
    },
    [resetErrorinEditBeeMitra]: (state) => {
      state.error = "";
    },
    [resetMessageinEditBeeMitra]: (state) => {
      state.message = "";
    },
  },
});

export default editBeeMitraSlice.reducer;
