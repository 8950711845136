import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const currentToken = localStorage.getItem("humblebeeAuthToken");
const initialState = {
  status: "",
  clusterData: [],
  loading: false,
  error: "",
};

export const getAllClusters = createAsyncThunk("getAllClusters", async () => {
  try {

    const res = await axios.get(`${process.env.REACT_APP_HUMBLEBEE_API_URL}/projects/clusters/getAll`, {
      headers: {
        authorization: `Bearer ${currentToken}`,
      },
    });
console.log("ress",res.data)
    console.log('res.data.clusters: ', res.data.clusters);
    var data = res.data.clusters;
    return data;
   
  } catch (err) {
    console.log("err", err);
    return err.response.data;
  }
});

const getAllClustersSlice = createSlice({
  name: "getAllClusters",
  initialState,
  reducers: {},

  extraReducers: {
    [getAllClusters.pending]: (state, action) => {
      state.loading = true;

      state.error = "";
    },

    [getAllClusters.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        state.clusterData = payload;
     
        state.message = payload.message;
      }
    },

    [getAllClusters.rejected]: (state, { message }) => {
      state.error = message;
      state.loading = false;
    },
  },
});

export default getAllClustersSlice.reducer;
