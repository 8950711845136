import React, { useState, useRef, useMemo, useEffect } from "react";
import * as Styles from "./style";
import { Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import close from "../../../assets/images/close.png";
import { InputGroup } from "../../../shared/InputGroup/InputGroup";

import { SelectGroup } from "../../../shared/SelectGroup/SelectGroup";
import { HONEY_FLORAL_TYPES } from "../../../shared/Constants/Constants";
import { makeHoney } from "../../../reduxLogic/reducers/honeys/makeHoneySlice";
import { getAllHoney } from "../../../reduxLogic/reducers/honeys/getAllHoneySlice";
import {
  getSingleHoney,
  resetSingleHoneyData,
} from "../../../reduxLogic/reducers/honeys/getSingleHoneySlice";
import { editSingleHoney } from "../../../reduxLogic/reducers/honeys/editSingleHoneySlice";
import { TextareaGroup } from "../../../shared/TextareaGroup/TextareaGroup";

const initialValues = {
  floralSource: "",
  honeyType: "",
  region: "",
  currentToken: "",
};

const honeySchema = Yup.object().shape({
  floralSource: Yup.string().required("Floral Source is required"),
  honeyType: Yup.string().required("Honey TYpe is requireed"),
  region: Yup.string().required("Region is required"),
});

const EditHoneyForm = (props) => {
  const { openEditModal, setOpenEditModal, idToEdit, setIdToEdit } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedHoneyType, setSelectedHoneyType] = useState("");

  const { honeyData } = useSelector((state) => state.singleHoney);
  const { error, message } = useSelector((state) => state.editHoney);
  console.log("this is honeyData  from  edit honey form==> ", honeyData);

  const dispatch = useDispatch();
  const formikHoneyRef = useRef(null);

  const fetchSingleHoneyDetails = () => {
    dispatch(getSingleHoney(idToEdit));
  };

  useEffect(() => {
    fetchSingleHoneyDetails();
  }, [idToEdit]);

  useEffect(() => {
    formikHoneyRef.current.setValues(honeyData);
    setSelectedHoneyType(honeyData?.honeyType);
  }, [honeyData]);

  const handleClose = () => {
    dispatch(resetSingleHoneyData());
    setOpenEditModal(false);
  };

  const handleHoneyStateChange = (value) => {
    setSelectedHoneyType(value);
  };

  const handleSubmit = (values) => {
    console.log("these are honey values in handleSubmit => ", values);
    setIsSubmitting(true);

    let formData = new FormData();
    const token = localStorage.getItem("humblebeeAuthToken");

    formData.append("floralSource", values.floralSource);
    formData.append("honeyType", values.honeyType);
    formData.append("region", values.region);
    formData.append("color", values.color);
    formData.append("taste", values.taste);

    formData.append("currentToken", token);
    const _id = honeyData?._id;
    console.log("this is just above final dispatch formData", formData);
    dispatch(editSingleHoney({ _id, formData }))
      .then(() => dispatch(getAllHoney()))
      .then(() => setOpenEditModal(false))
      .then(() => {
        if (!error) {
          toast.success("Honey Updated Succesfully");
        } else {
          toast.error(error);
        }
      });

    // End of handleSubmit
  };

  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        <Formik
          initialValues={honeyData}
          onSubmit={handleSubmit}
          validationSchema={honeySchema}
          validateOnMount
          innerRef={formikHoneyRef}
        >
          {(formik) => (
            <Styles.FormWithCloseButton>
              <Styles.CloseButtonDiv>
                <h3>Edit Honey Form</h3>
                <Styles.FormCloseButton onClick={handleClose}>
                  <img src={close} alt="Close" />
                </Styles.FormCloseButton>
              </Styles.CloseButtonDiv>

              {/* Main Bode of Form Starts */}
              <Styles.MainFormDiv>
                <InputGroup
                  PlaceholderText="Enter Floral Source"
                  LabelText="Floral Source"
                  name="floralSource"
                  type="text"
                  star="*"
                  defaultValue={formik.values.floralSource}
                  onChange={formik.handleChange}
                />

                <SelectGroup
                  PlaceholderText="Select Honey Type"
                  LabelText="Honey Type"
                  name="honeyType"
                  onChange={(e) => {
                    handleHoneyStateChange(e.target.value);
                    formik.handleChange(e);
                  }}
                  value={selectedHoneyType}
                  options={HONEY_FLORAL_TYPES}
                  star="*"
                />

                <InputGroup
                  PlaceholderText="Enter Region"
                  LabelText="Region"
                  name="region"
                  type="text"
                  star="*"
                  defaultValue={formik.values.region}
                  onChange={formik.handleChange}
                />

                <InputGroup
                  PlaceholderText="Enter Color"
                  LabelText="Color"
                  name="color"
                  type="text"
                  defaultValue={formik.values.color}
                  onChange={formik.handleChange}
                />

                <TextareaGroup
                  PlaceholderText="Enter Taste Profile"
                  LabelText="Taste Profile"
                  name="taste"
                  type="text"
                  defaultValue={formik.values.taste}
                  onChange={formik.handleChange}
                />
              </Styles.MainFormDiv>

              {/* Main Body of Form stops */}

              <Styles.CreateButton
                // disabled={!formik.dirty || !formik.isValid}
                type="submit"
              >
                {isSubmitting ? "...editing" : "Edit"}
              </Styles.CreateButton>
            </Styles.FormWithCloseButton>
          )}
        </Formik>
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  );
};

export default EditHoneyForm;
