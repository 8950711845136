import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const currentToken = localStorage.getItem("humblebeeAuthToken");
const initialState = {
  status: "",
  projectData: [],
  loading: false,
  error: "",
};

export const deleteCluster = createAsyncThunk(
  "deleteCluster",
  async (_id) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_HUMBLEBEE_API_URL}/projects/clusters/delete/${_id}`,
        {
            headers: {
              authorization: `Bearer ${currentToken}`,
            },
          }
      );
   
      return res.data.message;
    } catch (error) {
      return error.response.data;
    }
  }
);

const deleteClusterSlice = createSlice({
  name: "deleteCluster",
  initialState,
  reducers: {},

  extraReducers: {
    [deleteCluster.pending]: (state, action) => {
      state.loading = true;

      state.error = "";
    },

    [deleteCluster.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        state.clusterData = payload;
      }
    },

    [deleteCluster.rejected]: (state, { message }) => {
      state.error = message;
      state.loading = false;
    },
  },
});

export default deleteClusterSlice.reducer;
