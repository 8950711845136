/** @format */

import React, { useState, useRef, useEffect } from "react";
import * as Styles from "./style";
import { Formik } from "formik";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import close from "../../../assets/images/close.png";
import { InputGroup } from "../../../shared/InputGroup/InputGroup";
import { SelectGroup } from "../../../shared/SelectGroup/SelectGroup";

import {
  getSingleProject,
  resetSingleProjectData,
} from "../../../reduxLogic/reducers/projects/getSingleProjectDetailsSlice";
import {
  editSingleProject,
  resetErrorinEditProject,
  resetMessageinEditProject,
} from "../../../reduxLogic/reducers/projects/editSingleProjectSlice";
import { getAllProjects } from "../../../reduxLogic/reducers/projects/getAllProjectSlice";

// Update initial values to include new fields
const initialValues = {
  name: "",
  status: "",
  startDate: "",
  endDate: "",
  targetedBeekeepersCount: 0,
  targetedClustersCount: 0,
  targetedState: "",
  targetedDistricts: [],
  maxBeekeepersPerDistrict: 0,
};

const projectSchema = Yup.object().shape({
  name: Yup.string().required("Project Name is required"),
  status: Yup.string().required("Status is required"),
  startDate: Yup.date().required("Start Date is required"),
  targetedBeekeepersCount: Yup.number()
    .required("Targeted Beekeepers Count is required")
    .min(1, "Beekeepers count must be greater than 0"),
  maxBeekeepersPerDistrict: Yup.number()
    .required("Max Beekeepers Per District is required")
    .min(1, "Max Beekeepers per district must be greater than 0"),
});

const EditProjectForm = (props) => {
  const { error, message } = useSelector((state) => state.editProject);
  const { openEditModal, setOpenEditModal, idToEdit, singleRow } = props;

  const [singleProjectData, setSingleProjectData] = useState(singleRow || {});
  const [isEditing, setIsEditing] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(singleProjectData.status);

  const formikRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleProject(idToEdit));
  }, [idToEdit]);

  useEffect(() => {
    if (singleProjectData) {
      formikRef.current.setValues(singleProjectData);
    }
  }, [singleProjectData]);

  const handleStateChange = (value) => {
    setSelectedStatus(value);
  };

  const handleClose = () => {
    dispatch(resetSingleProjectData());
    setOpenEditModal(false);
  };

  const statusOptions = ["active", "inactive"];
  const handleSubmit = (values) => {
    setIsEditing(true);
    const projectData = {
      ...values,
    };
    let editId = singleProjectData?._id;

    dispatch(editSingleProject({ editId, projectData }))
      .then(() => dispatch(resetSingleProjectData()))
      .then(() => dispatch(getAllProjects()))
      .then(() => setOpenEditModal(false))
      .then(() => setIsEditing(false))
      .catch((err) => toast.error(`${err}`));
  };

  useEffect(() => {
    if (error) {
      toast.error(`${error}`);
      dispatch(resetErrorinEditProject());
      dispatch(resetMessageinEditProject());
    }

    if (!error && message) {
      toast.success("Project was updated successfully");
      dispatch(resetMessageinEditProject());
    }
  }, [error, dispatch, message]);

  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        {singleProjectData ? (
          <Formik
            initialValues={singleProjectData || initialValues}
            onSubmit={handleSubmit}
            validationSchema={projectSchema}
            innerRef={formikRef}
            validateOnMount
          >
            {(formik) => (
              <Styles.FormWithCloseButton>
                <Styles.CloseButtonDiv>
                  <h3>Edit Project Form</h3>
                  <Styles.FormCloseButton onClick={handleClose}>
                    <img src={close} alt="Close" />
                  </Styles.FormCloseButton>
                </Styles.CloseButtonDiv>

                <Styles.MainSupplierFormDiv>
                  <InputGroup
                    PlaceholderText="Enter Project Name"
                    LabelText="Project Name"
                    star="*"
                    name="name"
                    type="text"
                    defaultValue={singleProjectData?.name}
                    onChange={formik.handleChange}
                  />
                  <Styles.RowFlex>
                  
                    <SelectGroup
                      PlaceholderText="Status"
                      LabelText="Select status"
                      name="status"
                      onChange={(e) => {
                        handleStateChange(e.target.value);
                        formik.handleChange(e);
                      }}
                      value={selectedStatus}
                      options={statusOptions}
                      star="*"
                    />
                  </Styles.RowFlex>

                  <InputGroup
                    PlaceholderText="Start Date"
                    LabelText="Start Date"
                    name="startDate"
                    type="date"
                    onChange={formik.handleChange}
                    defaultValue={singleProjectData?.startDate ? new Date(singleProjectData.startDate).toISOString().split('T')[0] : ''}
                    />
                  <InputGroup
                    PlaceholderText="End Date"
                    LabelText="End Date"
                    name="endDate"
                    type="date"
                    onChange={formik.handleChange}
                    defaultValue={singleProjectData?.endDate ? new Date(singleProjectData.endDate).toISOString().split('T')[0] : ''}
                  />

                  <InputGroup
                    PlaceholderText="Targeted Beekeepers Count"
                    LabelText="Beekeepers Count"
                    name="targetedBeekeepersCount"
                    type="number"
                    onChange={formik.handleChange}
                    defaultValue={singleProjectData?.targetedBeekeepersCount}
                  />

                  <InputGroup
                    PlaceholderText="Max Beekeepers Per District"
                    LabelText="Max Beekeepers Per District"
                    name="maxBeekeepersPerDistrict"
                    type="number"
                    onChange={formik.handleChange}
                    defaultValue={singleProjectData?.maxBeekeepersPerDistrict}
                  />
                </Styles.MainSupplierFormDiv>

                <Styles.CreateSupplierButton type="submit">
                  {!isEditing ? "Edit" : "...editing"}
                </Styles.CreateSupplierButton>
              </Styles.FormWithCloseButton>
            )}
          </Formik>
        ) : (
          <h4>There was an error fetching data for Project with ID {idToEdit}</h4>
        )}
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  );
};

export default EditProjectForm;
