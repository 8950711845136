import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Menu, Icon } from "antd";
// import { CommentOutlined } from '@ant-design/icons';
// import ReactDOM from 'react-dom';

const { SubMenu } = Menu;

const readCookie = require("../../cookie.js").readCookie;

// const TaskIcon = () => (<i className='fa fa-tasks'></i>);

export default class LinksTab extends Component {
  state = {
    defaultOpenKeys: [],
    defaultSelectedKeys: ["projects"],
  };

  componentDidMount() {
    let path = window.location.pathname;
    if (path === "/dashboard") {
      this.setState({
        defaultSelectedKeys: ["dashboard"],
        defaultOpenKeys: [],
      });
    } else if (path.match(/^\/clusters/) !== null) {
      this.setState({
        defaultSelectedKeys: ["clusters"],
        defaultOpenKeys: [],
      });
    } else if (path === "/users") {
      this.setState({
        defaultSelectedKeys: ["users"],
        defaultOpenKeys: [],
      });
    } else if (path === "/honeys") {
      this.setState({
        defaultSelectedKeys: ["honeys"],
        defaultOpenKeys: [],
      });
    } else if (path === "/suppliers") {
      this.setState({
        defaultSelectedKeys: ["suppliers"],
        defaultOpenKeys: [],
      });
    } else if (path === "/batches") {
      this.setState({
        defaultSelectedKeys: ["batches"],
        defaultOpenKeys: [],
      });
    } else if (path.match(/^\/lead-stats/) !== null) {
      this.setState({
        defaultSelectedKeys: ["lead-stats"],
        defaultOpenKeys: ["analytics"],
      });
    } else if (path.match(/^\/sck-performance/) !== null) {
      this.setState({
        defaultSelectedKeys: ["sck-performance"],
        defaultOpenKeys: ["analytics"],
      });
    } else if (
      path.match(/^\/lead/) !== null ||
      path.match(/^\/edit-lead/) !== null
    ) {
      this.setState({ defaultSelectedKeys: ["leads"], defaultOpenKeys: [] });
      // } else if (path.match(/^\/mature-leads/) !== null) {
      //   this.setState({ defaultSelectedKeys: ['mature-leads'], defaultOpenKeys: [] });
    } else if (path.match(/^\/analytics/) !== null) {
      this.setState({
        defaultSelectedKeys: ["overall-analytics"],
        defaultOpenKeys: ["analytics"],
      });
    } else if (path.match(/^\/follow-up-analytics/) !== null) {
      this.setState({
        defaultSelectedKeys: ["follow-up-analytics"],
        defaultOpenKeys: ["analytics"],
      });
    } else if (path.match(/^\/stakeholders/) !== null) {
      this.setState({
        defaultSelectedKeys: ["stakeholders"],
        defaultOpenKeys: [],
      });
    } else if (path.match(/^\/enterprises-created/) !== null) {
      this.setState({
        defaultSelectedKeys: ["enterprises-created"],
        defaultOpenKeys: [],
      });
    } else if (path === "/settings/user-types") {
      this.setState({
        defaultSelectedKeys: ["platform-users"],
        defaultOpenKeys: ["settings"],
      });
    } else if (path === "/settings/focus-locations") {
      this.setState({
        defaultSelectedKeys: ["focus-locations"],
        defaultOpenKeys: ["settings"],
      });
    } else if (path === "/settings/modules") {
      this.setState({
        defaultSelectedKeys: ["module-management"],
        defaultOpenKeys: ["settings"],
      });
    } else if (path.match(/^\/settings/) !== null) {
      if (
        (this.props.userData.type !== "le" ||
          this.props.userData.userType.name === "SAARTHIs" ||
          this.props.userData.userType.name === "Swavalamban Fellows") &&
        this.props.userData.type !== "sidbi"
      )
        this.setState({
          defaultSelectedKeys: ["settings"],
          defaultOpenKeys: [],
        });
      else
        this.setState({
          defaultSelectedKeys: ["personal-members"],
          defaultOpenKeys: ["settings"],
        });
    } else if (
      path.match(/^\/org-types/) !== null ||
      path.match(/^\/user-types/) !== null
    ) {
      this.setState({
        defaultSelectedKeys: ["platform-users"],
        defaultOpenKeys: ["settings"],
      });
    } else if (path.match(/^\/ivrs/) !== null) {
      this.setState({ defaultSelectedKeys: ["ivrs"], defaultOpenKeys: [] });
    } else if (path.match(/^\/activities/) !== null) {
      this.setState({
        defaultSelectedKeys: ["activities"],
        defaultOpenKeys: [],
      });
    } else if (path.match(/^\/tasks/) !== null) {
      this.setState({ defaultSelectedKeys: ["tasks"], defaultOpenKeys: [] });
    } else if (path.match(/^\/success-stories/) !== null) {
      this.setState({
        defaultSelectedKeys: ["success-stories"],
        defaultOpenKeys: [],
      });
    } else if (path.match(/^\/business-plans/) !== null) {
      this.setState({
        defaultSelectedKeys: ["business-plans"],
        defaultOpenKeys: ["resources"],
      });
    } else if (path.match(/^\/training-center/) !== null) {
      this.setState({
        defaultSelectedKeys: ["training-center"],
        defaultOpenKeys: ["resources"],
      });
    } else if (path.match(/^\/bank-branches/) !== null) {
      this.setState({
        defaultSelectedKeys: ["bank-branches"],
        defaultOpenKeys: ["resources"],
      });
    } else if (path.match(/^\/business-ideas/) !== null) {
      this.setState({
        defaultSelectedKeys: ["business-ideas"],
        defaultOpenKeys: ["resources"],
      });
    } else if (path.match(/^\/session/) !== null) {
      this.setState({
        defaultSelectedKeys: ["sessions-summary"],
        defaultOpenKeys: [],
      });
    } else if (path.match(/^\/schemes/) !== null) {
      this.setState({
        defaultSelectedKeys: ["schemes"],
        defaultOpenKeys: ["resources"],
      });
    } else if (path.match(/^\/maturation-messages /) !== null) {
    } else if (path.match(/^\/service/) !== null) {
      this.setState({ defaultSelectedKeys: ["service"], defaultOpenKeys: [] });
    } else if (path.match(/^\/maturation-messages /) !== null) {
      this.setState({
        defaultSelectedKeys: ["maturation-messages"],
        defaultOpenKeys: ["communication"],
      });
    } else if (path.match(/^\/offline-aspirants/) !== null) {
      this.setState({
        defaultSelectedKeys: ["offline-aspirants"],
        defaultOpenKeys: [],
      });
    }
  }

  handleClick = (e) => {
    let tab = e.key;
    if (
      tab === "dashboard" &&
      this.state.defaultSelectedKeys.indexOf("dashboard") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["dashboard"],
        defaultOpenKeys: [],
      });
    } else if (
      tab === "lead-stats" &&
      this.state.defaultSelectedKeys.indexOf("lead-stats") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["lead-stats"],
        defaultOpenKeys: ["analytics"],
      });
    } else if (
      tab === "clusters" &&
      this.state.defaultSelectedKeys.indexOf("clusters") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["clusters"],
        defaultOpenKeys: [],
      });
    } else if (
      tab === "users" &&
      this.state.defaultSelectedKeys.indexOf("users") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["users"],
        defaultOpenKeys: [],
      });
    } else if (
      tab === "honeys" &&
      this.state.defaultSelectedKeys.indexOf("honeys") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["honeys"],
        defaultOpenKeys: [],
      });
    } else if (
      tab === "suppliers" &&
      this.state.defaultSelectedKeys.indexOf("suppliers") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["suppliers"],
        defaultOpenKeys: [],
      });
    } else if (
      tab === "batches" &&
      this.state.defaultSelectedKeys.indexOf("batches") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["batches"],
        defaultOpenKeys: [],
      });
    } else if (
      tab === "projects" &&
      this.state.defaultSelectedKeys.indexOf("projects") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["projects"],
        defaultOpenKeys: [],
      });
    } else if (
      tab === "sck-performance" &&
      this.state.defaultSelectedKeys.indexOf("sck-performance") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["sck-performance"],
        defaultOpenKeys: ["analytics"],
      });
    } else if (
      tab === "leads" &&
      this.state.defaultSelectedKeys.indexOf("leads") === -1
    ) {
      this.setState({ defaultSelectedKeys: ["leads"], defaultOpenKeys: [] });
      // } else if (tab === 'mature-leads' && this.state.defaultSelectedKeys.indexOf('mature-leads') === -1) {
      //   this.setState({ defaultSelectedKeys: ['mature-leads'], defaultOpenKeys: [] });
    } else if (tab === "analytics") {
      if (this.state.defaultOpenKeys.indexOf("analytics") === -1)
        this.setState({ defaultOpenKeys: ["analytics"] });
      else this.setState({ defaultOpenKeys: [] });
    } else if (
      tab === "overall-analytics" &&
      this.state.defaultSelectedKeys.indexOf("overall-analytics") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["overall-analytics"],
        defaultOpenKeys: ["analytics"],
      });
    } else if (
      tab === "follow-up-analytics" &&
      this.state.defaultSelectedKeys.indexOf("follow-up-analytics") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["follow-up-analytics"],
        defaultOpenKeys: ["analytics"],
      });
    } else if (
      tab === "stakeholders" &&
      this.state.defaultSelectedKeys.indexOf("stakeholders") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["stakeholders"],
        defaultOpenKeys: [],
      });
    } else if (
      tab === "enterprises-created" &&
      this.state.defaultSelectedKeys.indexOf("enterprises-created") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["enterprises-created"],
        defaultOpenKeys: [],
      });
    } else if (
      tab === "manage-partners" &&
      this.state.defaultSelectedKeys.indexOf("manage-partners") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["manage-partners"],
        defaultOpenKeys: [],
      });
    } else if (tab === "settings") {
      if (this.state.defaultOpenKeys.indexOf("settings") === -1)
        this.setState({ defaultOpenKeys: ["settings"] });
      else this.setState({ defaultOpenKeys: [] });
      if (
        (this.props.userData.type !== "le" ||
          this.props.userData.userType.name === "Swavalamban Fellows") &&
        this.props.userData.type !== "sidbi"
      )
        this.setState({ defaultSelectedKeys: ["settings"] });
    } else if (
      tab === "personal-members" &&
      this.state.defaultSelectedKeys.indexOf("personal-members") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["personal-members"],
        defaultOpenKeys: ["settings"],
      });
    } else if (
      tab === "focus-locations" &&
      this.state.defaultSelectedKeys.indexOf("focus-locations") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["focus-locations"],
        defaultOpenKeys: ["settings"],
      });
    } else if (
      tab === "platform-users" &&
      this.state.defaultSelectedKeys.indexOf("platform-users") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["platform-users"],
        defaultOpenKeys: ["settings"],
      });
    } else if (
      tab === "module-management" &&
      this.state.defaultSelectedKeys.indexOf("module-management") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["module-management"],
        defaultOpenKeys: ["settings"],
      });
    } else if (
      tab === "ivrs" &&
      this.state.defaultSelectedKeys.indexOf("ivrs") === -1
    ) {
      this.setState({ defaultSelectedKeys: ["ivrs"], defaultOpenKeys: [] });
    } else if (
      tab === "activities" &&
      this.state.defaultSelectedKeys.indexOf("activities") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["activities"],
        defaultOpenKeys: [],
      });
    } else if (
      tab === "tasks" &&
      this.state.defaultSelectedKeys.indexOf("tasks") === -1
    ) {
      this.setState({ defaultSelectedKeys: ["tasks"], defaultOpenKeys: [] });
    } else if (
      tab === "success-stories" &&
      this.state.defaultSelectedKeys.indexOf("success-stories") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["success-stories"],
        defaultOpenKeys: [],
      });
    } else if (tab === "resources") {
      if (this.state.defaultOpenKeys.indexOf("resources") === -1)
        this.setState({ defaultOpenKeys: ["resources"] });
      else this.setState({ defaultOpenKeys: [] });
    } else if (
      tab === "business-plans" &&
      this.state.defaultSelectedKeys.indexOf("business-plans") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["business-plans"],
        defaultOpenKeys: ["resources"],
      });
    } else if (
      tab === "training-center" &&
      this.state.defaultSelectedKeys.indexOf("training-center") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["training-center"],
        defaultOpenKeys: ["resources"],
      });
    } else if (
      tab === "bank-branches" &&
      this.state.defaultSelectedKeys.indexOf("bank-branches") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["bank-branches"],
        defaultOpenKeys: ["resources"],
      });
    } else if (
      tab === "business-ideas" &&
      this.state.defaultSelectedKeys.indexOf("business-ideas") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["business-ideas"],
        defaultOpenKeys: ["resources"],
      });
    } else if (
      tab === "sessions-summary" &&
      this.state.defaultSelectedKeys.indexOf("sessions-summary") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["sessions-summary"],
        defaultOpenKeys: [],
      });
    } else if (
      tab === "schemes" &&
      this.state.defaultSelectedKeys.indexOf("schemes") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["schemes"],
        defaultOpenKeys: ["resources"],
      });
    } else if (tab === "communication") {
    } else if (
      tab === "service" &&
      this.state.defaultSelectedKeys.indexOf("service") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["service"],
        defaultOpenKeys: ["resources"],
      });
    } else if (tab === "communication") {
      if (this.state.defaultOpenKeys.indexOf("communication") === -1)
        this.setState({ defaultOpenKeys: ["communication"] });
      else this.setState({ defaultOpenKeys: [] });
    } else if (
      tab === "sck-progress-mailers" &&
      this.state.defaultSelectedKeys.indexOf("sck-progress-mailers") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["sck-progress-mailers"],
        defaultOpenKeys: ["communication"],
      });
    } else if (
      tab === "follow-up-tracker" &&
      this.state.defaultSelectedKeys.indexOf("follow-up-tracker") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["follow-up-tracker"],
        defaultOpenKeys: [],
      });
    } else if (
      tab === "maturation-messages" &&
      this.state.defaultSelectedKeys.indexOf("maturation-messages") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["maturation-messages"],
        defaultOpenKeys: ["communication"],
      });
    } else if (
      tab === "offline-aspirants" &&
      this.state.defaultSelectedKeys.indexOf("offline-aspirants") === -1
    ) {
      this.setState({
        defaultSelectedKeys: ["offline-aspirants"],
        defaultOpenKeys: [],
      });
    } else if (tab === "humblebee") {
      if (this.state.defaultOpenKeys.indexOf("humblebee") === -1)
        this.setState({ defaultOpenKeys: ["humblebee"] });
      else this.setState({ defaultOpenKeys: [] });
    }
  };

  render() {
    if (
      this.props.sidebarMinimized &&
      ((window.screen.width >= 500 && window.location.pathname === "/leads") ||
        window.screen.width < 500)
    ) {
      return (
        <div className="main-menus">
          <ul className="sidebar-menu-options">
            <Menu
              onClick={this.handleClick}
              style={{ width: 90 }}
              selectedKeys={this.state.defaultSelectedKeys}
              openKeys={this.state.defaultOpenKeys}
              mode="inline"
            >
              {this.props.userData.accountType !== "saarthi" ? (
                <Menu.Item
                  key="dashboard"
                  className="sidebar-menu-items"
                  title="DASHBOARD"
                >
                  <Icon type="dashboard" />
                  <Link to={"/dashboard"}></Link>
                </Menu.Item>
              ) : null}
              {this.props.userData.type === "le" &&
              this.props.userData.accountType !== "saarthi" ? (
                <Menu.Item
                  key="offline-aspirants"
                  className="sidebar-menu-items"
                  title="Offline Aspirants"
                >
                  <Icon type="mobile" theme="outlined" />
                  <Link to={"/offline-aspirants"}></Link>
                </Menu.Item>
              ) : null}
              {this.props.userData.accountType !== "saarthi" &&
              this.props.userData.userType.name !== "Funder" ? (
                <Menu.Item
                  key="ivrs"
                  className="sidebar-menu-items"
                  title="IVRS CALLERS"
                >
                  <Icon type="phone" />
                  <Link to={"/ivrs/leads"}></Link>
                </Menu.Item>
              ) : null}
              <Menu.Item
                key="leads"
                className="sidebar-menu-items"
                title="LEADS PROFILED"
              >
                <Icon type="pie-chart" />
                <Link to={"/leads"}></Link>
              </Menu.Item>
              {this.props.userData.userType.name !== "Funder" ? (
                <SubMenu
                  key="analytics"
                  title={
                    <span title="ANALYTICS">
                      <Icon type="flag" />
                      <span></span>
                    </span>
                  }
                  onTitleClick={this.handleClick}
                >
                  {this.props.userData.type === "le" &&
                  this.props.userData.userType.name ===
                    "Project Management Unit" ? (
                    <Menu.Item
                      key="overall-analytics"
                      className="sidebar-menu-items"
                      title="OVERALL ANALYTICS"
                    >
                      <Icon type="flag" />
                      <Link to={"/analytics"}></Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userData.type === "le" ? (
                    <Menu.Item
                      key="follow-up-analytics"
                      className="sidebar-menu-items"
                      title="FOLLOW UP ANALYTICS"
                    >
                      <Icon type="flag" />
                      <Link to={"/follow-up-analytics"}></Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userData.type === "le" ||
                  this.props.userData.type === "sidbi" ? (
                    <Menu.Item
                      key="lead-stats"
                      className="sidebar-menu-items"
                      title="LEAD STATS"
                    >
                      <Icon type="flag" />
                      <Link to={"/lead-stats"}></Link>
                    </Menu.Item>
                  ) : null}
                  {!window.location.hostname.includes("udyamita") ? (
                    <Menu.Item
                      key="sck-performance"
                      className="sidebar-menu-items"
                      title="SCK PERFORMANCE"
                    >
                      <Icon type="flag" />
                      <Link to={"/sck-performance"}></Link>
                    </Menu.Item>
                  ) : null}
                </SubMenu>
              ) : null}
              {this.props.userData.email === "monika@letsendorse.com" ? (
                <Menu.Item
                  key="sessions-summary"
                  className="sidebar-menu-items"
                  title="SESSIONS TRACKING"
                >
                  <Icon type="audit" />
                  <Link to={"/session/summary"}></Link>
                </Menu.Item>
              ) : null}
              {this.props.userData.type === "le" ? (
                // && this.props.userData.userType.name === 'Project Management Unit' ? (
                <SubMenu
                  key="resources"
                  title={
                    <span title="RESOURCES">
                      <Icon type="database" />
                      <span></span>
                    </span>
                  }
                  onTitleClick={this.handleClick}
                >
                  {this.props.userData.userType.name ===
                  "Project Management Unit" ? (
                    <Menu.Item
                      key="business-plans"
                      className="sidebar-menu-items"
                      title="MOTHER BUSINESS PLANS"
                    >
                      <Icon type="audit" />
                      <Link to={"/business-plans"}></Link>
                    </Menu.Item>
                  ) : null}
                  {["Project Management Unit", "Swavalamban Fellows"].includes(
                    this.props.userData.userType.name
                  ) ? (
                    <Menu.Item
                      key="schemes"
                      className="sidebar-menu-items"
                      title="CREDIT SCHEMES"
                    >
                      <Icon type="audit" />
                      <Link to={"/schemes"}></Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userData.userType.name ===
                  "Project Management Unit" ? (
                    <Menu.Item
                      key="bank-branches"
                      className="sidebar-menu-items"
                      title="BANK BRANCHES"
                    >
                      <Icon type="audit" />
                      <Link to={"/bank-branches"}></Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userData.userType.name ===
                  "Project Management Unit" ? (
                    <Menu.Item
                      key="business-ideas"
                      className="sidebar-menu-items"
                      title="BUSINESS IDEAS"
                    >
                      <Icon type="audit" />
                      <Link to={"/business-ideas"}></Link>
                    </Menu.Item>
                  ) : null}
                  {/* {this.props.userData.type === 'le' ? ( 
                    // && this.props.userData.email === 'monika@letsendorse.com' ? (
                    <Menu.Item key="bank-branches" className="sidebar-menu-items" title="BANK BRANCHES">
                      <Icon type="audit" />
                      <Link to={'/bank-branches'}></Link>
                    </Menu.Item>
                  {/* ) : (null)} */}
                  {/* <Menu.Item
                      key="training-center"
                      className="sidebar-menu-items"
                      title="TRAINING CENTER"
                    >
                      <Icon type="audit" />
                      <Link to={"/training-center"}></Link>
                    </Menu.Item> */}
                </SubMenu>
              ) : null}
              {this.props.userData.type === "le" &&
              this.props.userData.accountType !== "saarthi" ? (
                <Menu.Item
                  key="stakeholders"
                  className="sidebar-menu-items"
                  title="Stakeholders Management"
                >
                  <Icon type="schedule" />
                  <Link to={"/stakeholders"}></Link>
                </Menu.Item>
              ) : null}
              {(this.props.userData.type === "le" &&
                this.props.userData.accountType !== "saarthi") ||
              this.props.userData.type === "sidbi" ? (
                <Menu.Item
                  key="enterprises-created"
                  className="sidebar-menu-items"
                  title="ENTERPRISES CREATED"
                >
                  <Icon type="shop" />
                  <Link to={"/enterprises-created"}></Link>
                </Menu.Item>
              ) : null}

              {this.props.userData.type === "le" &&
              this.props.userData.userType.name ===
                "Project Management Unit" ? (
                <SubMenu
                  key="communication"
                  title={
                    <span title="COMMUNICATIONS">
                      <Icon type="mail" />
                      <span></span>
                    </span>
                  }
                  onTitleClick={this.handleClick}
                >
                  <Menu.Item
                    key="sck-progress-mailers"
                    className="sidebar-menu-items"
                    title="SCK PROGRESS MAILERS"
                  >
                    <Icon type="mail" />
                    <Link to={"/communication"}></Link>
                  </Menu.Item>
                  <Menu.Item
                    key="maturation-messages"
                    className="sidebar-menu-items"
                    title="MATURATION MESSAGES"
                  >
                    <Icon type="audit" />
                    <Link to={"/maturation-messages"}></Link>
                  </Menu.Item>
                </SubMenu>
              ) : null}

              <Menu.Item
                key="activities"
                className="sidebar-menu-items"
                title="ACTIVITIES"
              >
                <Icon type="schedule" />
                <Link to={"/activities"}></Link>
              </Menu.Item>

              {/* {this.props.userData.type === 'le' && this.props.userData.email === 'monika@letsendorse.com' ? (
                <Menu.Item key="follow-up-tracker" className="sidebar-menu-items" title="FOLLOW-UP-TRACKER">
                  <Icon component={TaskIcon} />
                  <Link to={'/follow-up-tracker'}></Link>
                </Menu.Item>
              ) : (null)}

              {this.props.userData.type === 'le' && this.props.userData.accountType !== 'saarthi' ? (
                <Menu.Item key="tasks" className="sidebar-menu-items" title="MY TASKS">
                  <Icon component={TaskIcon} />
                  <Link to={'/tasks'}></Link>
                </Menu.Item>
              ) : (null)} */}

              {this.props.userData ? (
                (this.props.userData.type === "le" &&
                  this.props.userData.accountType !== "saarthi") ||
                this.props.userData.type === "sidbi" ? (
                  <SubMenu
                    key="settings"
                    title={
                      <span title="SETTINGS">
                        <Icon type="setting" />
                        <span></span>
                      </span>
                    }
                    onTitleClick={this.handleClick}
                  >
                    <Menu.Item
                      key="personal-members"
                      className="sidebar-menu-items"
                      title={
                        JSON.parse(readCookie("userData")).type !== "sidbi"
                          ? "Personal / Members"
                          : "Personal"
                      }
                    >
                      <Icon type="user" />
                      <Link to={"/settings"}></Link>
                    </Menu.Item>
                    <Menu.Item
                      key="platform-users"
                      className="sidebar-menu-items"
                      title="PLATFORM USERS"
                    >
                      <Icon type="user" />
                      <Link to={"/settings/user-types"}></Link>
                    </Menu.Item>
                    {this.props.userData.type === "le" &&
                    this.props.userData.userType.name ===
                      "Project Management Unit" ? (
                      <Menu.Item
                        key="focus-locations"
                        className="sidebar-menu-items"
                        title="FOCUS LOCATIONS"
                      >
                        <Icon type="environment" />
                        <Link to={"/settings/focus-locations"}></Link>
                      </Menu.Item>
                    ) : null}
                    {this.props.userData.type === "le" &&
                    this.props.userData.userType.name ===
                      "Project Management Unit" ? (
                      <Menu.Item
                        key="module-management"
                        className="sidebar-menu-items"
                        title="MODULE MANAGEMENT"
                      >
                        <Icon type="user" />
                        <Link to={"/settings/modules"}></Link>
                      </Menu.Item>
                    ) : null}
                  </SubMenu>
                ) : (
                  <Menu.Item
                    key="settings"
                    className="sidebar-menu-items"
                    title="SETTINGS"
                  >
                    <Icon type="setting" />
                    <Link to={"/settings"}></Link>
                  </Menu.Item>
                )
              ) : null}
            </Menu>
          </ul>
        </div>
      );
    } else {
      return (
        <div className="main-menus">
          <ul className="sidebar-menu-options">
            <Menu
              onClick={this.handleClick}
              style={
                window.screen.width >= 500 ? { width: 225 } : { width: "100vw" }
              }
              selectedKeys={this.state.defaultSelectedKeys}
              openKeys={this.state.defaultOpenKeys}
              mode="inline"
            >
              {this.props.userData.accountType !== "saarthi" ? (
                <Menu.Item
                  onClick={() =>
                    window.screen.width < 500
                      ? this.props.toggleSidebar()
                      : null
                  }
                  key="dashboard"
                  className="sidebar-menu-items"
                >
                  <Icon type="dashboard" />
                  <Link to={"/dashboard"}>DASHBOARD</Link>
                </Menu.Item>
              ) : null}
              {window.screen.width > 500 &&
              this.props.userData.type === "le" &&
              this.props.userData.accountType !== "saarthi" ? (
                <Menu.Item key="users" className="sidebar-menu-items">
                  <Icon type="user" />
                  <Link to={"/users"}>Users</Link>
                </Menu.Item>
              ) : null}
              {window.screen.width > 500 &&
              this.props.userData.type === "le" &&
              this.props.userData.accountType !== "saarthi" ? (
                <Menu.Item key="projects" className="sidebar-menu-items">
                  <Icon type="project" />
                  <Link to={"/projects"}>Projects</Link>
                </Menu.Item>
              ) : null}
              {window.screen.width > 500 && (
                <Menu.Item key="clusters" className="sidebar-menu-items">
                  <Icon type="cluster" />
                  {(readCookie("project_id")) ? (
                    <Link
                      to={`/clusters`}
                    >
                      Clusters
                    </Link>
                  ) : (
                    <Link to="/clusters">Clusters</Link>
                  )}
                </Menu.Item>
              )}
              <Menu.Item
                onClick={() =>
                  window.screen.width < 500 ? this.props.toggleSidebar() : null
                }
                key="leads"
                className="sidebar-menu-items"
              >
                <Icon type="pie-chart" />
                <Link to={"/leads"}>LEADS PROFILED</Link>
              </Menu.Item>

                  <SubMenu
                    key="analytics"
                    title={
                      <span>
                        <Icon type="apartment" />
                        <span>HONEY TRACEABILITY</span>
                      </span>
                    }
                    onTitleClick={this.handleClick}
                  >
                      <Menu.Item
                        key="overall-analytics"
                        className="sidebar-menu-items"
                      >
                        <Icon type="flag" />
                        <Link to={"/honeys"}>HONEYS</Link>
                      </Menu.Item>
                      <Menu.Item
                        key="follow-up-analytics"
                        className="sidebar-menu-items"
                      >
                        <Icon type="appstore" />
                        <Link to={"/batches"}>
                          BATCHES
                        </Link>
                      </Menu.Item>
                   
                      <Menu.Item
                        key="lead-stats"
                        className="sidebar-menu-items"
                      >
                        <Icon type="table" />
                        <Link to={"/suppliers"}>SUPPLIERS</Link>
                      </Menu.Item>
                  </SubMenu>

              {window.screen.width > 500 &&
              this.props.userData.email === "monika@letsendorse.com" ? (
                <Menu.Item
                  key="sessions-summary"
                  className="sidebar-menu-items"
                  title="SESSIONS TRACKING"
                >
                  <Icon type="audit" />
                  <Link to={"/session/summary"}>SESSIONS TRACKING</Link>
                </Menu.Item>
              ) : null}
              {window.screen.width > 500 &&
              this.props.userData.type === "le" ? (
                <SubMenu
                  key="resources"
                  title={
                    <span>
                      <Icon type="database" />
                      <span>RESOURCES</span>
                    </span>
                  }
                  onTitleClick={this.handleClick}
                >
                  {["Project Management Unit", "Swavalamban Fellows"].includes(
                    this.props.userData.userType.name
                  ) ? (
                    <Menu.Item key="schemes" className="sidebar-menu-items">
                      <Icon type="audit" />
                      <Link to={"/schemes"}>CREDIT SCHEMES</Link>
                    </Menu.Item>
                  ) : null}

                  <Menu.Item key="bank-branches" className="sidebar-menu-items">
                    <Icon type="audit" />
                    <Link to={"/bank-branches"}>BANK BRANCHES</Link>
                  </Menu.Item>

                  <Menu.Item
                    key="training-center"
                    className="sidebar-menu-items"
                  >
                    <Icon type="audit" />
                    <Link to={"/training-center"}>TRAINING CENTER</Link>
                  </Menu.Item>
                </SubMenu>
              ) : null}
              {/* <Menu.Item key="stakeholders" className="sidebar-menu-items">
                <Icon type="schedule" />
                <Link to={"/stakeholders"}>Stakeholders</Link>
              </Menu.Item> */}

              {/* <Menu.Item key="activities" className="sidebar-menu-items">
                <Icon type="schedule" />
                <Link to={"/activities"}>ACTIVITIES</Link>
              </Menu.Item> */}

              {/* {window.screen.width > 500 && this.props.userData ? (
                <SubMenu
                  key="settings"
                  title={
                    <span>
                      <Icon type="setting" />
                      <span>SETTINGS</span>
                    </span>
                  }
                  onTitleClick={this.handleClick}
                >
                  <Menu.Item
                    key="personal-members"
                    className="sidebar-menu-items"
                  >
                    <Icon type="user" />
                    <Link to={"/settings"}>
                      {JSON.parse(readCookie("userData")).type !== "sidbi"
                        ? "Personal / Members"
                        : "Personal"}
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key="platform-users"
                    className="sidebar-menu-items"
                  >
                    <Icon type="user" />
                    <Link to={"/settings/user-types"}>PLATFORM USERS</Link>
                  </Menu.Item>
                  {this.props.userData.type === "le" &&
                  this.props.userData.userType.name ===
                    "Project Management Unit" ? (
                    <Menu.Item
                      key="focus-locations"
                      className="sidebar-menu-items"
                    >
                      <Icon type="environment" />
                      <Link to={"/settings/focus-locations"}>
                        FOCUS LOCATIONS
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userData.type === "le" &&
                  this.props.userData.userType.name ===
                    "Project Management Unit" ? (
                    <Menu.Item
                      key="module-management"
                      className="sidebar-menu-items"
                    >
                      <Icon type="user" />
                      <Link to={"/settings/modules"}>MODULE MANAGEMENT</Link>
                    </Menu.Item>
                  ) : null}
                </SubMenu>
              ) : (
                <Menu.Item key="settings" className="sidebar-menu-items">
                  <Icon type="setting" />
                  <Link to={"/settings"}>SETTINGS</Link>
                </Menu.Item>
              )} */}
            </Menu>
          </ul>
        </div>
      );
    }
  }
}
