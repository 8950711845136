import React from "react";
export const renderPreviewForPrevious = (file, index) => {
  if (file.endsWith(".pdf")) {
    return (
      <a
        style={{ display: "block", width: "100%" }}
        href={file}
        target="_blank"
        rel="noopener noreferrer"
      >{`PDF Link ${index}`}</a>
    ); // Display short name of the PDF file
  } else if (
    file.endsWith(".jpg") ||
    file.endsWith(".png") ||
    file.endsWith(".jpeg") ||
    file.endsWith(".svg")
  ) {
    return <img src={file} alt="imgFile" />; // Display image preview from the File object
  } else {
    return null;
  }
};

export const renderPreviewForCurrent = (file, index) => {
  if (file?.type?.includes("image")) {
    return <img src={URL.createObjectURL(file)} alt="imageFile"  style={{height:80,width:90}}/>;
  } else if (file.type === "application/pdf") {
    return (
      <p
        style={{
          border: "1px solid gray",
          padding: "4px",
          borderRadius: "8px",
        }}
      >
        {file.name}
      </p>
    ); // Display the name of the PDF file
  }
  return null; // If file type is neither image nor PDF, do not render anything
};
