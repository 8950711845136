import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  status: "",
  suppliersData: [],
  loading: false,
  error: "",
};

export const deleteSupplier = createAsyncThunk(
  "deleteSupplier",
  async (_id) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_HUMBLEBEE_API_URL}/suppliers/${_id}`
      );
      // console.log(
      //   "res.data from getSingleSupplier ==> ",
      //   res.data.singleSupplier
      // );
      return res.data.singleSupplier;
    } catch (error) {
      return error.response.data;
    }
  }
);

const deleteSupplierSlice = createSlice({
  name: "deleteSupplier",
  initialState,
  reducers: {},

  extraReducers: {
    [deleteSupplier.pending]: (state, action) => {
      state.loading = true;

      state.error = "";
    },

    [deleteSupplier.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        state.suppliersData = payload;
      }
    },

    [deleteSupplier.rejected]: (state, { message }) => {
      state.error = message;
      state.loading = false;
    },
  },
});

export default deleteSupplierSlice.reducer;
