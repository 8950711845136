import React, { useState, useEffect } from "react";
import * as Styles from "../FarmerForm/style";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import close from "../../../assets/images/close.png";
import { InputGroup } from "../../../shared/InputGroup/InputGroup";
import { SelectGroup } from "../../../shared/SelectGroup/SelectGroup";
import {
  makeUser,
  resetErrorinMakeUser,
  resetMessageinMakeUser,
} from "../../../reduxLogic/reducers/users/makeUserSlice";
import { getAllUsers } from "../../../reduxLogic/reducers/auth/getAllUsersSlice";
import { MultiSelect } from "react-multi-select-component";
import { ROLE_OPTIONS } from "../../../shared/Constants/Constants";
import { BlockData } from "../../../shared/Constants/blocks";
import { TextareaGroup } from "../../../shared/TextareaGroup/TextareaGroup";
import ReactQuill from "react-quill";
import { Formik, Field, FieldArray } from "formik";
import Swal from "sweetalert2";
import Select from "react-select";
import { getAllProjects } from "../../../reduxLogic/reducers/projects/getAllProjectSlice";

const initialValues = {
  name: "",
  userMobile: "",
  addressLine: "",
  district: "",
  city: "",
  state: "",
  pincode: "",
  isActive: true,
  language: "",
  aadharNumber: "", // This will be conditionally added for 'beemitra'
  produce: "", // This will be conditionally added for 'farmer'
};

const userSchema = Yup.object().shape({
  name: Yup.string().required("User Name is required"),
  mobileNumber: Yup.string()
    .required("User Mobile is required")
    .matches(/^[+]?[0-9]{10,15}$/, "Mobile number is not valid"),
  addressLine: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  district: Yup.string().required("District is required"),
  state: Yup.string().required("State is required"),
  pincode: Yup.string()
    .required("Pincode is required")
    .matches(/^[0-9]{6}$/, "Invalid pincode"),
  language: Yup.string().required("Language is required"),
  aadharNumber: Yup.string().when("userRoles", {
    is: (roles) => roles?.includes("beemitra"),
    then: Yup.string().required("Aadhar number is required for Beemitra"),
  }),
  produce: Yup.string().when("userRoles", {
    is: (roles) => roles?.includes("farmer"),
    then: Yup.string().required("Produce is required for Farmers"),
  }),
});

const UserForm = (props) => {
  const { openModal, setOpenModal } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);

  const { message, error } = useSelector((state) => state.makeFarmer);
  const dispatch = useDispatch();
  const { projectData } = useSelector((state) => state.allProjects);

  const language_options = [
    { value: "en", label: "English" },
    { value: "hi", label: "Hindi" },
    { value: "gu", label: "Gujarati" },
    { value: "mr", label: "Marathi" },
    { value: "bn", label: "Bengali" },
    { value: "ta", label: "Tamil" },
    { value: "te", label: "Telugu" },
    { value: "kn", label: "Kannada" },
    { value: "ml", label: "Malayalam" },
    { value: "or", label: "Odia" },
    { value: "pa", label: "Punjabi" },
    { value: "ur", label: "Urdu" },
  ];

  const produceOptions = [
    { value: "wheat", label: "Wheat" },
    { value: "rice", label: "Rice" },
    { value: "cotton", label: "Cotton" },
  ];

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(resetErrorinMakeUser());
    }
    if (message) {
      toast.success(message);
      dispatch(resetMessageinMakeUser());
    }
    dispatch(getAllProjects());
  }, [error, message, dispatch]);

  const handleSubmit = (values, { resetForm }) => {
    console.log("values", values);
    setIsSubmitting(true);
    const payload = {
      ...values,
      userRoles: selectedRoles.map((role) => role.value),
    };
    const address = {
      district: payload.district,
      state: payload.state,
      pincode: payload.pincode,
    };
    payload.address = address;
    const userInfo = {
      name: payload.name,
      mobileNumber: payload.mobileNumber,
      address: payload.address,
      userRoles: payload.userRoles,
      fulladdress: payload.addressLine,
      isActive: true,
      preferredLanguage: payload.language,
    };

    const userMetaInfo = {
      aadharNumber: payload.aadharNumber || null,
      produce: payload.produce || null,
    };

    console.log("payload", userInfo, userMetaInfo);

    const re = dispatch(makeUser({userInfo, userMetaInfo})).then(() => {
      setIsSubmitting(false);
      resetForm();
      setSelectedRoles([]);
      setOpenModal(false);
      dispatch(getAllUsers()); // Refresh user list
    });
    console.log("re", re);
  };

  const handleClose = () => {
    setOpenModal((prev) => !prev);
  };

  useEffect(() => {
    // Extract unique states from the JSON data
    const uniqueStates = [...new Set(BlockData.map((item) => item.state))];
    setStates(uniqueStates);
  }, []);

  const handleStateChange = (tempSelectedState) => {
    setSelectedState(tempSelectedState);
    // Extract unique districts based on the selected state
    const uniqueDistricts = [
      ...new Set(
        BlockData.filter((item) => item.state === tempSelectedState).map(
          (item) => item.district
        )
      ),
    ];
    setDistricts(uniqueDistricts);
  };

  const handleDistrictChange = (tempSelectedDistrict) => {
    setSelectedDistrict(tempSelectedDistrict);
    // Extract unique Cities based on the selected district
    const uniqueCities = [
      ...new Set(
        BlockData.filter((item) => item.district === tempSelectedDistrict).map(
          (item) => item.city
        )
      ),
    ];
    setCities(uniqueCities);
  };

  const handleCityChange = (tempSelectedCity) => {
    setSelectedCity(tempSelectedCity);
  };
  

  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={userSchema}
          validateOnMount
        >
          {(formik) => (
            <Styles.FormWithCloseButton>
              <Styles.CloseButtonDiv>
                <h3>Create User Form</h3>
                <Styles.FormCloseButton onClick={handleClose}>
                  <img src={close} alt="Close" />
                </Styles.FormCloseButton>
              </Styles.CloseButtonDiv>

              {/* Main Bode of Form Starts */}
              <Styles.MainFarmerFormDiv>
                <InputGroup
                  PlaceholderText="Enter  Name"
                  LabelText="Name"
                  name="name"
                  type="text"
                  star="*"
                  onChange={formik.handleChange}
                />
                <Styles.RowFlex>
                  <SelectGroup
                    PlaceholderText="Select State"
                    LabelText="State"
                    name="state"
                    onChange={(e) => {
                      handleStateChange(e.target.value);
                      formik.handleChange(e);
                    }}
                    value={selectedState} // Add this line
                    options={states}
                    star="*"
                  />

                  <SelectGroup
                    PlaceholderText="Select District"
                    LabelText="District"
                    name="district"
                    onChange={(e) => {
                      handleDistrictChange(e.target.value);
                      formik.handleChange(e);
                    }}
                    value={selectedDistrict} // Add this line
                    options={districts}
                    star="*"
                  />
                </Styles.RowFlex>
                <Styles.RowFlex>
                  <SelectGroup
                    PlaceholderText="Select City"
                    LabelText="City"
                    name="city"
                    options={cities}
                    onChange={(e) => {
                      handleCityChange(e.target.value);
                      formik.handleChange(e);
                    }}
                    value={selectedCity} // Add this line
                    star="*"
                  />

                  <InputGroup
                    PlaceholderText="Enter User Address"
                    LabelText="User Address"
                    name="addressLine"
                    type="text"
                    star="*"
                    onChange={formik.handleChange}
                  />
                </Styles.RowFlex>

                <Styles.RowFlex>
                  <InputGroup
                    PlaceholderText="Enter User Mobile"
                    LabelText="Mobile"
                    name="mobileNumber"
                    type="text"
                    star="*"
                    onChange={formik.handleChange}
                  />
                  <InputGroup
                    PlaceholderText="Enter User Pincode"
                    LabelText="User Pincode"
                    name="pincode"
                    type="number"
                    star="*"
                    onChange={formik.handleChange}
                  />
                </Styles.RowFlex>

                <label>Preferred Language</label>
                <Select
                  options={language_options}
                  onChange={(e) => {
                    formik.setFieldValue("language", e.value);
                  }}
                />
                <div
                  className="multi-select-group"
                  style={{ marginBottom: "20px", marginTop: "20px" }}
                >
                  <label>Select Roles</label>
                  <MultiSelect
                    options={ROLE_OPTIONS}
                    value={selectedRoles}
                    onChange={setSelectedRoles}
                    labelledBy="Select Roles"
                    hasSelectAll={false}
                  />
                </div>

                {/* Conditional Fields */}
                {selectedRoles.some((role) => role.value === "bee_mitra") && (
                  <InputGroup
                    PlaceholderText="Enter Aadhar Number"
                    LabelText="Aadhar Number"
                    name="aadharNumber"
                    type="text"
                    star="*"
                    onChange={formik.handleChange}
                  />
                )}

                {selectedRoles.some((role) => role.value === "farmer") && (
                  <div style={{ marginBottom: "20px" }}>
                    <label>Select Produce</label>
                    <Select
                      options={produceOptions}
                      onChange={(e) => formik.setFieldValue("produce", e.value)}
                    />
                  </div>
                )}


                {selectedRoles.some((role) => role.value === "funder" || role.value.includes("field_view")) && (
                  <div style={{ marginBottom: "20px" }}>
                    <label>Assign Project</label>
                    <Select
                      options={projectData.map((project) => ({
                        value: project._id,
                        label: project.name,
                      }))
                      }
                      onChange={(e) => formik.setFieldValue("projectId", e.value)}
                    />
                  </div>
                )}
              </Styles.MainFarmerFormDiv>

              {/* Main Body of Form stops */}

              <Styles.CreateFarmerButton
                // disabled={!formik.dirty || !formik.isValid}
                type="submit"
              >
                {isSubmitting ? "Submitting..." : "Create"}
              </Styles.CreateFarmerButton>
            </Styles.FormWithCloseButton>
          )}
        </Formik>
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  );
};

export default UserForm;
