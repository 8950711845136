import styled from "styled-components";
import { Field, Form } from "formik";

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(32, 35, 40, 0.1);
  backdrop-filter: blur(1px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background-color: white;
  width: 70vw;
  /* height: 30vh; */
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 720px) {
    width: 75vw;
  }
`;
export const FormWithCloseButton = styled(Form)`
  display: flex;
  flex-direction: column;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  padding: 20px;
  width: 100%;
  height: 90vh;
  overflow-y: scroll;
  gap: 8px;
`;
export const FormCloseButton = styled.button`
  border: none;
  cursor: pointer;

  background-color: white;
  height: 12px;
  margin-right: -12px;
  margin-top: -6px;
  border-radius: 12px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  :hover {
    font-weight: bold;
  }
  img {
    height: 20px;
  }
`;

export const CloseButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CreateSupplierButton = styled.button`
  border: none;
  cursor: pointer;
  padding: 4px 24px;
  background-color: #ff8701;
  height: 24px;
  width: fit-content;
  min-width: 70px;
  border-radius: 12px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  :hover {
    font-weight: bold;
  }

  :disabled {
    background-color: gray;
  }
`;

export const MainSupplierFormDiv = styled.div`
  border: 1px solid gray;
  padding: 12px;
`;

export const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`;

export const Labels = styled.label`
  height: 20px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* or 100% */

  letter-spacing: 0.01em;

  color: #000000;
  margin-top: 4px;
`;

export const RadioButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
`;

export const SelectedMultiDisplayGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding: 12px;
  gap: 4px;

  span {
    border: 1px solid gray;
    padding: 4px;
    border-radius: 8px;
  }
`;

export const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const MarginTopBottomBox = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const IMGTextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  border: 1px dotted gray;
  border-radius: 4px;
  padding: 4px;
`;

export const IMGBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;

  img {
    // max-height: 200px;
    // max-width: 45%;
  }
`;

export const FileInputClearButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;

  button {
    max-width: 10px;
    border: none;
    background-color: white;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
`;

export const FileInput = styled.input`
  &::-webkit-file-upload-button {
    /* Styles for the "Choose File" button */
    border-radius: 8px;
    border: none;
    padding: 4px;
    min-height: 30px;
    min-width: 90px;
    :hover {
      border: 1px solid gray;
    }
  }

  &::-webkit-file-upload-dummy {
    /* Styles for the "No File Chosen" text */
    /* ... */
  }
`;
