import styled from "styled-components";

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  background: rgba(32, 35, 40, 0.1);
  backdrop-filter: blur(1px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background-color: white;
  width: 40vw;
  min-width: 280px;
  /* height: 30vh; */
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px solid black;

  @media (max-width: 720px) {
    width: 75vw;
  }
`;

export const InnerContainer = styled.div`
  padding: 20px;
`;

export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
`;

export const DeleteButton = styled.button`
  border: none;
  padding: 12px;
  border-radius: 12px;
  background-color: red;
  color: white;
  cursor: pointer;
  min-width: 60px;

  :hover {
    font-weight: bold;
  }
`;

export const CloseButton = styled.button`
  border: none;
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;
  min-width: 60px;

  :hover {
    font-weight: bold;
  }
`;
