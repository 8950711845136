import React, { useEffect, useState } from "react";
import * as Styles from "./style";
import { Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import close from "../../../assets/images/close.png";
import { InputGroup } from "../../../shared/InputGroup/InputGroup";
import { TextareaGroup } from "../../../shared/TextareaGroup/TextareaGroup";
import CheckboxGroup from "../../../shared/CheckboxDropDown/CheckBoxGroup";
import {
  BEE_Array,
  DUMMY_FARMERS,
  DUMMY_HONEY_TYPES,
  ORDER_STATUS_ARRAY,
  monthsOfYear,
  monthsOfYearWithLabels,
} from "../../../shared/Constants/Constants";
import {
  getRandomAlphabets,
  generateId,
  changeDateTimeFormat,
} from "../../../shared/Helper";
import {
  makeBatch,
  resetErrorinMakeBatch,
  resetMessageinMakeBatch,
} from "../../../reduxLogic/reducers/batches/makeBatchSlice";
//@ts-ignore
import { getAllBatches } from "../../../reduxLogic/reducers/batches/getAllBatchesSlice";
import { getAllFarmers } from "../../../reduxLogic/reducers/farmers/getAllFarmersSlice";
import { FieldErrorMessage } from "../../../shared/ErrorMessage/FieldErrorMessage";
import { MultiSelect } from "react-multi-select-component";
import { getAllHoney } from "../../../reduxLogic/reducers/honeys/getAllHoneySlice";
import { renderPreviewForCurrent } from "../../../shared/RenderPreview/RenderPreview";
import LabelValueCheckBox from "../../../shared/LabelValueCheckBox/LabelValueCheckBox";
import Swal from "sweetalert2";


const batchSchema = Yup.object().shape({
  supplier: Yup.string().required("Supplier Name is required"),
  orderStatus: Yup.string().required("Order Status  is required"),
  dateOfOrder: Yup.string().required("Date of Order is required"),
  //dateOfDelivery: Yup.string().required("Date of Delivery is required"),
  nectorSource: Yup.string().required("Nector Source is required"),
  origin: Yup.string().required("Origin is required"),
  batchItems: Yup.array()
    .of(
      Yup.object().shape({
        honeyType: Yup.string().required("Honey Type is required"),
        volume: Yup.string().required("Volume is required"),
        contributingFarmers: Yup.array()
          .min(1, "At least one farmer should be selected")
          .required("Contributing Farmers is required"),
      })
    )
    .min(1, "At least one batch item is required"),
});

const initialValues = {
  supplier: "",
  dateOfOrder: "",
  dateOfDelivery: "",
  batchItems: [{ honeyType: "", volume: "", contributingFarmers: [], bee: "" }],
  currentToken: "",
  nectorSource: "",
  origin: "",
  harvestPeriod: [],
  orderStatus: "",
  batchDocuments: [],
};

export const BatchForm = (props) => {
  const { openModal, setOpenModal } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedHarvestPeriod, setSelectedHarvestPeriod] = useState([]);
  const [allBatchDocuments, setAllBatchDocuments] = useState([]);

  const { farmersData } = useSelector((state) => state.allFarmers);
  const { suppliersData } = useSelector((state) => state.allSuppliers);

  // const farmerNamesArray = farmersData.map((farmer) => ({
  //   label: farmer.farmerName,
  //   value: farmer._id,
  // }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllHoney());
  }, []);

  const { error, message } = useSelector((state) => state.makeBatch);
  console.log("this is message immedaitely after useSelector => ", message);
  console.log("this is error immedaitely after useSelector => ", error);

  const { honeyData } = useSelector((state) => state.allHoneys);

  const newHoneyArray = honeyData?.map((item) => ({
    label: `${item.honeyId}-${item.floralSource}`,
    value: item._id,
  }));

  console.log("newHoneyArray ======>> ", newHoneyArray);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleBatchDocumentsChange = (e, index) => {
    const { files } = e.target;
    setAllBatchDocuments((prev) => [...prev, ...files]);
  };

  const handleClearSelection = (e) => {
    e.preventDefault();
    const fileInput = document.querySelector('input[name="batchDocuments"]');
    if (fileInput) {
      fileInput.value = null;
    }
    setAllBatchDocuments([]);
  };

  const handleSubmit = (values) => {
    console.log("this is handleSubmit Values : ", values);
    setIsSubmitting(true);
    const token = localStorage.getItem("humblebeeAuthToken");
    let formData = new FormData();

    formData.append("supplier", values.supplier);
    formData.append("orderStatus", values.orderStatus);

    formData.append("dateOfOrder", values.dateOfOrder);

    formData.append("dateOfDelivery", values.dateOfDelivery);
    formData.append("currentToken", token);

    values.batchItems.forEach((batchItem, index) => {
      const { honeyType, volume, contributingFarmers, bee } = batchItem;

      formData.append(`batchItems[${index}].honeyType`, honeyType);
      formData.append(`batchItems[${index}].volume`, volume);

      formData.append(`batchItems[${index}].bee`, bee);

      // Append contributingFarmers as separate properties
      contributingFarmers.forEach((contributingFarmer, i) => {
        formData.append(
          `batchItems[${index}].contributingFarmers[${i}]`,
          contributingFarmer
        );
      });
    });

    let harvestMonths = selectedHarvestPeriod.map((item) => item.value);

    harvestMonths.forEach((item, index) => {
      formData.append(`harvestPeriod[${index}]`, item);
    });

    formData.append("nectorSource", values.nectorSource);

    formData.append("origin", values.origin);

    for (let i = 0; i < allBatchDocuments?.length; i++) {
      formData.append(`batchDocuments[${i}]`, allBatchDocuments[i]);
    }

    if (values.supplier == "Select Supplier") {
      toast.error("Invalid Supplier");
      return;
    }
    // const tempValues = {
    //   supplier: values.supplier,
    //   dateOfOrder: values.dateOfOrder,
    //   dateOfDelivery: values.dateOfDelivery,
    //   currentToken: token,
    //   batchItems: values.batchItems,
    //   nectorSource: values.nectorSource,
    //   origin: values.origin,
    // };

    // console.log("tempValues => ", tempValues);

    // if (values?.dateOfDelivery < values?.dateOfOrder) {
    //   toast.error("Date of Delivery must come after Date of Order");
    //   return;
    // }

    dispatch(makeBatch(formData))
      .then(() => setOpenModal(false))
      .then(() => dispatch(getAllBatches()))
      .then(() => setIsSubmitting(false));
  };

  useEffect(() => {
    dispatch(getAllFarmers());
  }, []);

  useEffect(() => {
    if (error) {
      console.log("inside error condition of toast", error);
      toast.error(`${error}`);
      dispatch(resetErrorinMakeBatch());
      dispatch(resetMessageinMakeBatch());
    }

    if (!error && message) {
      console.log("inside not error condition of toast", message);
      toast.success("Batch was added succesfully");
      dispatch(resetMessageinMakeBatch());
    }
  }, [error, dispatch, message]);

  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={batchSchema}
          validateOnMount
        >
          {(formik) => (
            <Styles.FormWithCloseButton>
              <Styles.CloseButtonDiv>
                <h3>Batch Form</h3>
                <Styles.FormCloseButton onClick={handleClose}>
                  <img src={close} alt="Close" />
                </Styles.FormCloseButton>
              </Styles.CloseButtonDiv>

              <Styles.LFGroup>
                <Styles.FieldArrayLabel htmlFor={formik.values.supplier}>
                  Supplier
                  <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                </Styles.FieldArrayLabel>
                <Styles.FieldArrayField
                  name="supplier"
                  as="select"
                  value={formik.values.supplier}
                  onChange={formik.handleChange}
                >
                  <option hidden>Select Supplier</option>
                  {suppliersData.map((singleSupplier, index) => (
                    <option
                      value={singleSupplier._id}
                      key={singleSupplier.supplierId}
                    >
                      {singleSupplier.supplierName}
                    </option>
                  ))}
                </Styles.FieldArrayField>
                <FieldErrorMessage name="supplier" />
              </Styles.LFGroup>

              <Styles.LFGroup>
                <Styles.FieldArrayLabel htmlFor={formik.values.orderStatus}>
                  Order Status
                  <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                </Styles.FieldArrayLabel>
                <Styles.FieldArrayField
                  name="orderStatus"
                  as="select"
                  value={formik.values.orderStatus}
                  onChange={formik.handleChange}
                >
                  <option hidden>Select Order Status</option>
                  {ORDER_STATUS_ARRAY.map((status, index) => (
                    <option value={status} key={status}>
                      {status}
                    </option>
                  ))}
                </Styles.FieldArrayField>
                <FieldErrorMessage name="orderStatus" />
              </Styles.LFGroup>

              <Styles.DateGroup>
                <InputGroup
                  PlaceholderText="Enter Order Date"
                  LabelText="Order Date"
                  name="dateOfOrder"
                  type="datetime-local"
                  star="*"
                  onChange={formik.handleChange}
                />

                {formik.values.orderStatus != "Delivered" ||
                formik.values.orderStatus == "" ? (
                  <InputGroup
                    PlaceholderText="Enter Delivery Date"
                    LabelText="Delivery Date"
                    name="dateOfDelivery"
                    type="datetime-local"
                    onChange={formik.handleChange}
                    isDisabled={true}
                  />
                ) : (
                  <InputGroup
                    PlaceholderText="Enter Delivery Date"
                    LabelText="Delivery Date"
                    name="dateOfDelivery"
                    type="datetime-local"
                    star="*"
                    onChange={formik.handleChange}
                  />
                )}
              </Styles.DateGroup>

              <TextareaGroup
                PlaceholderText="Enter Nector Source"
                LabelText="Nector Source"
                name="nectorSource"
                type="text"
                star="*"
                onChange={formik.handleChange}
              />

              <TextareaGroup
                PlaceholderText="Enter Origin"
                LabelText="Origin"
                name="origin"
                type="text"
                star="*"
                onChange={formik.handleChange}
              />

              <div style={{ marginTop: "20px" }}>
                <label style={{ fontWeight: "bold", marginBottom: "20px" }}>
                  Harvest Period
                  <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                </label>

                <div style={{ marginTop: "10px" }}>
                  <MultiSelect
                    options={monthsOfYearWithLabels}
                    value={selectedHarvestPeriod}
                    onChange={setSelectedHarvestPeriod}
                    labelledBy="Select"
                  />
                </div>

                {selectedHarvestPeriod?.length <= 0 ? (
                  <span style={{ color: "red" }}>
                    There should be atleast one month selected
                  </span>
                ) : null}
              </div>

              <FieldArray name="batchItems">
                {(arrayHelpers) =>
                  formik.values.batchItems.map((batchItem, index) => (
                    <Styles.BatchItemsContainer key={index}>
                      <Styles.LabelFieldGroup>
                        <Styles.FieldArrayLabel htmlFor={batchItem.honeyType}>
                          Honey Type
                        </Styles.FieldArrayLabel>
                        <Styles.FieldArrayField
                          name={`batchItems[${index}].honeyType`}
                          as="select"
                          value={batchItem.honeyType}
                          onChange={formik.handleChange}
                        >
                          <option hidden>Select Honey Type</option>
                          {newHoneyArray?.map((singleHoneyType, index) => (
                            <option
                              value={singleHoneyType.value}
                              key={singleHoneyType.value}
                              style={{ padding: "4px" }}
                            >
                              {singleHoneyType.label}
                            </option>
                          ))}
                        </Styles.FieldArrayField>
                        <FieldErrorMessage
                          name={`batchItems.${index}.honeyType`}
                        />
                      </Styles.LabelFieldGroup>

                      <Styles.LabelFieldGroup>
                        <Styles.FieldArrayLabel
                          htmlFor={`batchItems[${index}].volume`}
                        >
                          Volume(kg)
                        </Styles.FieldArrayLabel>
                        <Styles.FieldArrayField
                          name={`batchItems[${index}].volume`}
                          type="number"
                          placeholder="Enter Volume"
                        />
                        <FieldErrorMessage
                          name={`batchItems.${index}.volume`}
                        />
                      </Styles.LabelFieldGroup>

                      <Styles.LabelFieldGroup>
                        <Styles.FieldArrayLabel
                          htmlFor={`batchItems[${index}].contributingFarmers`}
                        >
                          Contributing Farmers
                        </Styles.FieldArrayLabel>
                        <Field
                          name={`batchItems.${index}.contributingFarmers`}
                          render={({ field }) => (
                            <>
                              <Styles.MultiSelectAllShowDiv>
                                {formik.values.batchItems[
                                  index
                                ].contributingFarmers.map(
                                  (singleFarmer, ind) => {
                                    // Find the farmer object with matching _id in farmersData array
                                    const farmer = farmersData.find(
                                      (item) => item._id === singleFarmer
                                    );

                                    // Check if farmer is found and retrieve the farmerName
                                    const farmerName = farmer
                                      ? farmer?.farmerName
                                      : singleFarmer;

                                    return (
                                      <Styles.MultiSelectSingleShowDiv
                                        key={ind + Date.now()}
                                      >
                                        {farmerName}
                                      </Styles.MultiSelectSingleShowDiv>
                                    );
                                  }
                                )}
                              </Styles.MultiSelectAllShowDiv>

                              <CheckboxGroup
                                id={`batchItems.${index}.contributingFarmers`}
                                options={farmersData}
                                onChange={formik.handleChange}
                                name={`batchItems.${index}.contributingFarmers`}
                              />
                            </>
                          )}
                        />
                        <FieldErrorMessage
                          name={`batchItems.${index}.contributingFarmers`}
                        />
                      </Styles.LabelFieldGroup>

                      <Styles.LabelFieldGroup>
                        <Styles.FieldArrayLabel htmlFor={batchItem.bee}>
                          Bee
                        </Styles.FieldArrayLabel>
                        <Styles.FieldArrayField
                          name={`batchItems[${index}].bee`}
                          as="select"
                          value={batchItem.bee}
                          onChange={formik.handleChange}
                        >
                          <option hidden>Select Bee</option>
                          {BEE_Array.map((singleBee, index) => (
                            <option value={singleBee} key={singleBee}>
                              {singleBee}
                            </option>
                          ))}
                        </Styles.FieldArrayField>
                        <FieldErrorMessage name={`batchItems.${index}.bee`} />
                      </Styles.LabelFieldGroup>

                      {/* <Styles.AddRemoveButtonContainer>
                        <Styles.RemoveButton
                          type="button"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <span>-</span>
                        </Styles.RemoveButton>
                        <Styles.AddButton
                          type="button"
                          onClick={() =>
                            arrayHelpers.push({
                              honeyType: "",
                              volume: "",
                              contributingFarmers: [],
                            })
                          }
                        >
                          <span>+</span>
                        </Styles.AddButton>
                      </Styles.AddRemoveButtonContainer> */}
                    </Styles.BatchItemsContainer>
                  ))
                }
              </FieldArray>

              <Styles.ColumnFlex>
                <Styles.Labels>Batch Documents</Styles.Labels>
                <Styles.FileInputClearButtonDiv>
                  <button onClick={(e) => handleClearSelection(e)}>
                    <img src={close} alt="clear" />
                  </button>
                  <Styles.FileInput
                    type="file"
                    multiple
                    onChange={(e, index) =>
                      handleBatchDocumentsChange(e, index)
                    }
                    name="batchDocuments"
                    accept=".pdf, image/*"
                  />
                </Styles.FileInputClearButtonDiv>

                <Styles.IMGTextBox>
                  {/* <p>Current</p> */}
                  <Styles.IMGBox>
                    {allBatchDocuments && allBatchDocuments?.length > 0
                      ? allBatchDocuments?.map((item, index) => (
                        <div key={index} className="image" style={{ height:"100px",width:"120px",display:"flex" }}>
                        <div>
                          {renderPreviewForCurrent(item, index)}
                        </div>
                        <button className="delete-button" type="button" onClick={() => {
                           

                          Swal.fire({
                            title: 'Delete Confirmation',
                            text: 'Are you sure you want to delete this item?',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: 'rgb(255, 135, 1)',
                            cancelButtonColor: 'lightgrey',
                            confirmButtonText: 'Delete',
                          }).then((result) => {
                            if (result.isConfirmed) {
                              // let filterImage = allMedia.filter((item,i) => i !== index );
                              //                               setAllMedia(filterImage)
                              
                              let filterImage = allBatchDocuments.filter((item,i) => i !== index );
                              setAllBatchDocuments(filterImage) 
                              Swal.fire('Deleted!', 'The item has been deleted.', 'success');
                            }
                          });
                          
                          
                          }}>
                            <img src={close} alt="clear"  style={{height:'10px'}}/>
                         </button>
                        </div>
                        ))
                      : null}
                  </Styles.IMGBox>
                </Styles.IMGTextBox>
              </Styles.ColumnFlex>

              <Styles.CreateBatchButton
                // disabled={!formik.dirty || !formik.isValid}
                type="submit"
              >
                {isSubmitting ? "...submitting" : "Create"}
              </Styles.CreateBatchButton>
            </Styles.FormWithCloseButton>
          )}
        </Formik>
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  );
};
