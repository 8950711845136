import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  singleFarmer: {},
  loading: true,
  error: "",
  message: "",
};

export const editSingleFarmer = createAsyncThunk(
  "editSingleFarmer",
  async ({ editId, formData }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_HUMBLEBEE_API_URL}/farmers/${editId}`,
        formData,
        {
          headers: {
            Accept: "/",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log("id from editFarmerSlice ", editId);
      // console.log("formData from editFarmerSlice ", formData);
      // console.log("res.data from editFarmerSlice  ", res.data);
      return res.data;
    } catch (error) {
      console.log(error);
      //@ts-ignore
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetMessageinEditFarmer = createAction(
  "resetMessageinEditFarmer"
);
export const resetErrorinEditFarmer = createAction("resetErrorinEditFarmer");

const editSingleFarmerSlice = createSlice({
  name: "editSingleFarmer",
  initialState,
  reducers: {},
  extraReducers: {
    //@ts-ignore
    [editSingleFarmer.pending]: (state, action) => {
      state.loading = true;
    },
    //@ts-ignore
    [editSingleFarmer.fulfilled]: (
      //@ts-ignore
      state,
      //@ts-ignore
      { payload: { error, singleFarmer, message } }
    ) => {
      state.loading = false;
      if (error) {
        state.error = message;
      } else {
        state.message = message;
        //state.singleFarmer = singleFarmer;
      }
    },
    //@ts-ignore
    [editSingleFarmer.rejected]: (state, { message, error }) => {
      state.error = error;
    },
    [resetErrorinEditFarmer]: (state) => {
      state.error = "";
    },
    [resetMessageinEditFarmer]: (state) => {
      state.message = "";
    },
  },
});

export default editSingleFarmerSlice.reducer;
