import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  singleSupplier: {},
  loading: true,
  error: "",
  message: "",
};

export const resetMessageinEditSupplier = createAction(
  "resetMessageinEditSupplier"
);
export const resetErrorinEditSupplier = createAction(
  "resetErrorinEditSupplier"
);

export const editSingleSupplier = createAsyncThunk(
  "editSingleSupplier",
  async ({ editId, formData }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_HUMBLEBEE_API_URL}/suppliers/${editId}`,
        formData,
        {
          headers: {
            Accept: "/",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("id from editSupplierSlice ", editId);
      console.log("formData from editSupplierSlice ", formData);
      console.log("res.data from editSupplierSlice  ", res.data);
      return res.data;
    } catch (error) {
      console.log(error);
      //@ts-ignore
      return rejectWithValue(error.response.data);
    }
  }
);

const editSingleSupplierSlice = createSlice({
  name: "editSingleSupplier",
  initialState,
  reducers: {},
  extraReducers: {
    //@ts-ignore
    [editSingleSupplier.pending]: (state, action) => {
      state.loading = true;
    },
    //@ts-ignore
    [editSingleSupplier.fulfilled]: (
      //@ts-ignore
      state,
      //@ts-ignore
      { payload: { error, singleSupplier, message } }
    ) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        state.message = message;
      }
    },
    //@ts-ignore
    [editSingleSupplier.rejected]: (
      state,
      //@ts-ignore
      { payload: { message, error } }
    ) => {
      state.error = error;
      state.message = message;
    },
    [resetErrorinEditSupplier]: (state) => {
      state.error = "";
    },
    [resetMessageinEditSupplier]: (state) => {
      state.message = "";
    },
  },
});

export default editSingleSupplierSlice.reducer;
