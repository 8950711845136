import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Styles from "./style";
import { toast } from "react-toastify";
import { deleteSupplier } from "../../../reduxLogic/reducers/suppliers/deleteSupplierSlice";
import { getAllSuppliers } from "../../../reduxLogic/reducers/suppliers/getAllSupplierSlice";

const DeleteModal = (props) => {
  const { idToDelete, setDeleteModal } = props;
  let dispatch = useDispatch();

  const handleDeleteSupplier = () => {
    dispatch(deleteSupplier(idToDelete))
      .then(() => dispatch(getAllSuppliers()))
      .then(() => setDeleteModal(false))
      .then(() => toast.warning(`Supplier with id ${idToDelete} deleted`));
  };

  const handleCloseModal = () => {
    setDeleteModal(false);
  };
  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        <Styles.InnerContainer>
          <h3>Are you sure you want to delete?</h3>
          <Styles.ButtonDiv>
            <Styles.DeleteButton onClick={handleDeleteSupplier}>
              Yes
            </Styles.DeleteButton>
            <Styles.CloseButton onClick={handleCloseModal}>
              No
            </Styles.CloseButton>
          </Styles.ButtonDiv>
        </Styles.InnerContainer>
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  );
};

export default DeleteModal;
