import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePagination, useTable, useSortBy,   useGlobalFilter, useAsyncDebounce, } from "react-table";
import {jwtDecode} from "jwt-decode";
import { getAllUsers } from "../reduxLogic/reducers/auth/getAllUsersSlice";
import { searchMobileUser } from "../reduxLogic/reducers/auth/searchMobileUser";
import { getAllProjects } from "../reduxLogic/reducers/projects/getAllProjectSlice";
import editIcon from "../assets/images/edit.png";
import deleteIcon from "../assets/images/delete.png";
import { changeDateTimeFormat } from "../shared/Helper";
import * as Styles from "./style";
import axios from "axios";
import Select from "react-select";
import UserForm from "../Farmers/components/UserForm/UserForm";
import Cookies from "universal-cookie";
import { act } from "react";
const cookies = new Cookies();

const options = [
  { value: "super_admin", label: "Super Admin" },
  { value: "beekind_app_admin", label: "Beekind App Admin" },
  { value: "field_view_app_admin", label: "Field View App Admin" },
  { value: "bee_mitra", label: "Bee Mitra" },
  { value: "community_leader", label: "Community Leader" },
  { value: "beekeeper", label: "Beekeeper" },
  { value: "trainee_beekeeper", label: "Trainee Beekeeper" },
  { value: "farmer", label: "Farmer" },
  { value: "expert", label: "Expert" },
  {value:"funder", label:"Funder"},
];

const roleFilterOptions = [
  { value: "", label: "All" },
  ...options, // Reuse the same roles from the previous options array
];

const USERS_TABLE_COLUMNS = [
  { Header: "Name", accessor: "userInfo.name" },
  { Header: "Mobile", accessor: "userInfo.mobileNumber",
  },
  {
    Header: "Profile", Cell: ({ row }) => {
        return (
          <a href={`/user-profile/${row.original.userInfo._id}`}>
            see profile
          </a>
        );
    }
  },
  { Header: "Roles", accessor: "userInfo.userRoles"},
  {Header: "App Installed", Cell: ({ row }) => {
    if (row.original.userInfo.isAppInstalled) {
      return (
        <span>Yes</span>
      );
    }
  }
  },
  { Header: "Added On", accessor: "userInfo.addedAt" },
  { Header: "Updated On", accessor: "userInfo.updatedAt" },
  // {
  //   Header: "Actions",
  // },
];

const UsersTable = () => {
  const dispatch = useDispatch();
  const { token } = ""//useSelector((state) => state.auth || {});
  const currentToken = cookies.get("access_token");

  const [mobileNumber, setMobileNumber] = useState("");
  const [filterByRole, setFilterByRole] = useState("");
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getAllProjects());
  }, [dispatch, token]);

  let role;
  if (currentToken?.length > 0) {
    const decoded = jwtDecode(currentToken);
    role = decoded?.role;
  }
  const [searchInput, setSearchInput] = useState(""); // Renamed for clarity

  const { allUsers } = useSelector((state) => state.allUsers);
  const { mobileUser } = useSelector((state) => state.mobileUser);

  const { projectData } = useSelector((state) => state.allProjects);

  const columns = useMemo(() => USERS_TABLE_COLUMNS, []);
  // const data = useMemo(() => mobileUser?.length > 0 ? mobileUser : allUsers, [allUsers, mobileUser]);
  // const data = useMemo(() => {
  //   const sourceData = mobileUser?.length > 0 ? mobileUser : allUsers;
  //   console.log("mobileUser",mobileUser.length, sourceData);
  //   if (filterByRole.value) {
  //     return sourceData.filter((user) =>
  //       user.userInfo.userRoles.includes(filterByRole.value)
  //     );
  //   }
  //   return sourceData;
  // }, [allUsers, mobileUser, filterByRole]);

  const data = useMemo(() => {
    const sourceData = mobileUser?.length > 0 ? mobileUser : allUsers;
    
    // Filter by role if specified
    let filteredData = filterByRole.value
      ? sourceData.filter((user) => user.userInfo.userRoles.includes(filterByRole.value))
      : sourceData;

    // Filter by search input
    if (searchInput) {
      const isNumeric = /^\d+$/.test(searchInput); // Check if input is numeric
      filteredData = filteredData.filter((user) => {
        const { name, mobileNumber } = user.userInfo;
        console.log("name",name, mobileNumber);
        return isNumeric
          ? mobileNumber.includes(searchInput) // Search by mobile number
          : name?.toLowerCase()?.includes(searchInput.toLowerCase()); // Search by name
      });
    }

    return filteredData;
  }, [allUsers, mobileUser, filterByRole, searchInput]);

  // console.log("data", data);
  // //if not data then return unable to fetch data
  // if (data.message === "Could not fetch users") {
  //   console.log("data.message", data.message);
  //   return <h3>Unable to fetch data</h3>;
  // }

  const tableInstance = useTable(
    {
      columns: columns,
      data: data,
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
  } = tableInstance;
  const { pageIndex } = state;

  const handlePatchRequestChangeRole = async (_id, newRole, action) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_APP_USER_URL}/user/update-user-role`,
        { 
          userId: _id,
          action: action,
          userRole: newRole,
        },
        {
          headers: {
            authorization: `Bearer ${currentToken}`,
            source: "humblebee_admin",
          },
        }
      );      
      dispatch(getAllUsers());
      if (mobileNumber?.length === 10) {
        dispatch(searchMobileUser(mobileNumber));
      }
      alert(`Role ${action === "add" ? "added" : "removed"} successfully`);
    } catch (error) {
      console.log("There was some error while changing role => ", error);
    }
  };

  const handleRoleChange = (_id, event) => {
    const selectedRoles = event.map((option) => option.value);
    const existingRoles = data.find((user) => user.userInfo._id === _id)
      .userInfo.userRoles;

    // Detect if a role is being added or removed
    let action = "";
    let changedRole = "";

    if (selectedRoles.length > existingRoles.length) {
      // Role added
      action = "add";
      changedRole = selectedRoles.find((role) => !existingRoles.includes(role));
    } else {
      // Role removed
      action = "remove";
      changedRole = existingRoles.find((role) => !selectedRoles.includes(role));
    }

    const confirmChange = window.confirm(
      `Are you sure you want to edit the role?\n\nDetails:\nName: ${
        data.find((user) => user.userInfo._id === _id).userInfo.name
      }\nMobile: ${
        data.find((user) => user.userInfo._id === _id).userInfo.mobileNumber
      }\nRole: ${changedRole}`
    );

    if (confirmChange) {
      handlePatchRequestChangeRole(_id, changedRole, action);
    } else {
      alert("Role change cancelled");
    }
  };
  // const handleMobileSearch = (mobileNumber) => {
  //   setMobileNumber(mobileNumber);
  //   if (mobileNumber?.length === 10) {
  //     dispatch(searchMobileUser(mobileNumber));
  //   } else if (mobileNumber?.length === 0) {
  //     dispatch(getAllUsers());
  //     dispatch(searchMobileUser(mobileNumber));
  //   }
  // };

  const handleMobileSearch = (value) => {
    setSearchInput(value); // Update searchInput state
    // Since filtering is done through useMemo, no need to dispatch here
  };


  const handleModalToggle = () => {
    setOpenModal((prev) => !prev);
  };

  const handleOpenEditModal = (_id, user) => {
    return;
  };

  return (
    <Styles.UserBox>
      <div style={{ height: "10vh" }}></div>
      <Styles.TableContainer>
        <Styles.SearchContainer>
          <Styles.SearchInput
            type="text"
            placeholder="Search by name / mobile number"
            onChange={(e) => handleMobileSearch(e.target.value)}
          />
          <Select
            options={roleFilterOptions}
            value={filterByRole}
            onChange={(selectedOption) =>
              setFilterByRole(selectedOption || { value: "", label: "All" })
            }
            placeholder="Filter by role"
            isClearable
            styles={{
              container: (provided) => ({
                ...provided,
                marginLeft: "10px",
                width: "300px",
              }),
            }}
          />

          <p
            style={{
              fontWeight: "bold",
              fontSize: "1rem",
            }}
          >
            Total Users Count: {data?.length}
          </p>
          <Styles.ModalButton
            onClick={handleModalToggle}
            style={{ marginLeft: "auto", display: "block" }}
          >
            Create User
          </Styles.ModalButton>

          {openModal ? (
            <UserForm openModal={openModal} setOpenModal={setOpenModal} />
          ) : null}
        </Styles.SearchContainer>
        {data ? (
          <Styles.Table {...getTableProps()}>
            <Styles.THead>
              {headerGroups.map((headerGroup) => (
                <Styles.THeadRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((headerColumn) => (
                    <Styles.TH
                      {...headerColumn.getHeaderProps(
                        headerColumn.getSortByToggleProps()
                      )}
                    >
                      {headerColumn.render("Header")}
                      <span>
                        {headerColumn.isSorted
                          ? headerColumn.isSortedDesc
                            ? " 🔼"
                            : " 🔽"
                          : ""}
                      </span>
                    </Styles.TH>
                  ))}
                </Styles.THeadRow>
              ))}
            </Styles.THead>

            <Styles.TBody {...getTableBodyProps()}>
              {page.map((singleRow) => {
                prepareRow(singleRow);

                return (
                  <Styles.TBodyRow {...singleRow.getRowProps()}>
                    {singleRow.cells.map((singleCell, index) => (
                      <Styles.TD key={index}>
                        <span>
                          {singleCell.column.id === "userInfo.addedAt" ||
                          singleCell.column.id === "userInfo.updatedAt" ? (
                            <span>
                              {singleCell?.value?.length > 0
                                ? changeDateTimeFormat(singleCell?.value)
                                : "-"}
                            </span>
                          ) : (
                            <>
                              <span>
                                {singleCell.column.id ===
                                "userInfo.userRoles" ? (
                                  <div
                                    style={{
                                      width: "auto",
                                    }}
                                  >
                                    <Select
                                      isMulti={true}
                                      value={singleCell.value.map((role) => {
                                        return options.find(
                                          (option) => option.value === role
                                        );
                                      })}
                                      options={options}
                                      onChange={(event) =>
                                        handleRoleChange(
                                          singleCell?.row?.original?.userInfo
                                            ?._id,
                                          event
                                        )
                                      }
                                      styles={{
                                        // container: (provided) => ({
                                        //   width: "200px",
                                        // }),
                                        control: (base) => ({
                                          ...base,
                                          minHeight: "30px", // Control the dropdown height
                                        }),
                                        menu: (base) => ({
                                          ...base,
                                          zIndex: 9999, // Ensures dropdown appears above other elements
                                        }),
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 9999, // Portal the dropdown so it doesn't get cut off
                                        }),
                                        option: (base) => ({
                                          ...base,
                                          cursor: "pointer",
                                        }),
                                      }}
                                      menuPortalTarget={document.body} // Ensure dropdown appears outside table
                                    />
                                  </div>
                                ) : (
                                  <span>{singleCell.render("Cell")}</span>
                                )}
                              </span>
                              <span>
                                {singleCell.column.Header === "Actions" ? (
                                  <Styles.ActionSpan>
                                    <button
                                      onClick={() =>
                                        handleOpenEditModal(
                                          singleCell?.row?.original?._id,
                                          singleRow?.original
                                        )
                                      }
                                    >
                                      <img src={editIcon} alt="Edit" />{" "}
                                    </button>
                                  </Styles.ActionSpan>
                                ) : null}
                              </span>
                            </>
                          )}
                        </span>
                      </Styles.TD>
                    ))}
                  </Styles.TBodyRow>
                );
              })}
            </Styles.TBody>
          </Styles.Table>
        ) : (
          <h3>There was some error displaying table</h3>
        )}
      </Styles.TableContainer>

      <Styles.PaginationmainContainer>
        <span>
          <strong>
            Page {pageIndex + 1} of {pageOptions.length}
            <br />
          </strong>
        </span>
        <Styles.PaginationDiv>
          <Styles.PaginationButton
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </Styles.PaginationButton>
          <Styles.PaginationButton
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            Previous
          </Styles.PaginationButton>

          <Styles.PaginationInputDiv>
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                let jumpToPageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(jumpToPageNumber);
              }}
            />
          </Styles.PaginationInputDiv>

          <Styles.PaginationButton onClick={nextPage} disabled={!canNextPage}>
            Next
          </Styles.PaginationButton>
          <Styles.PaginationButton
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </Styles.PaginationButton>
        </Styles.PaginationDiv>
      </Styles.PaginationmainContainer>
    </Styles.UserBox>
  );
};

export default UsersTable;
