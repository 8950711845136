import React from "react";
import { FieldErrorMessage } from "../ErrorMessage/FieldErrorMessage";
import * as Styles from "./style";

export const TextareaGroup = (props) => {
  const {
    PlaceholderText,
    LabelText,
    name,
    type,
    onChange,
    defaultValue,
    isDisabled,
    onBlur,
    star,
    value,
  } = props;

  let tempDisabled = isDisabled === true ? isDisabled : false;

  return (
    <Styles.TextareaGroupContainer>
      <Styles.Labels>
        {LabelText} <span style={{ color: "red" }}>{star}</span>
      </Styles.Labels>
      <Styles.Textarea
        placeholder={PlaceholderText}
        name={name}
        type={type}
        onChange={onChange}
        defaultValue={defaultValue}
        disabled={tempDisabled}
        onBlur={onBlur}
        value={value}
      />
      <FieldErrorMessage name={name} />
    </Styles.TextareaGroupContainer>
  );
};
