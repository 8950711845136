// export const BATCH_TABLE_COLUMNS = [
//   { Header: "Batch ID", accessor: "batchId" },
//   { Header: "Supplier", accessor: "supplierName" },
//   // { Header: "Date of Order", accessor: "dateOfOrder" },
//   {
//     Header: "Date of Delivery",
//     accessor: "dateOfDelivery",
//   },
//   { Header: "Added By", accessor: "addedBy.name" },

//   // { Header: "Batch Items", accessor: "batchItems" },
// ];

// export const FARMERS_TABLE_COLUMNS = [
//   { Header: "Farmer", accessor: "farmerName" },
//   { Header: "Mobile", accessor: "farmerMobile" },
//   { Header: "State", accessor: "state" },
//   { Header: "QR Code", accessor: "qrCode" },
//   { Header: "Added By", accessor: "addedBy.name" },
//   { Header: "Added On", accessor: "addedOn" },
// ];

export const DUMMY_FARMERS = [
  "Farmer 1",
  "Farmer 2",
  "Farmer 3",
  "Farmer 4",
  "Farmer 5",
  "Farmer 6",
  "Farmer 7",
  "Farmer 8",
  "Farmer 9",
  "Farmer 10",
];

export const DUMMY_HONEY_TYPES = [
  "Sundarban Multi-Flora",
  "Tulsi",
  "Ajwain",
  "Litchi",
  "Kashmir Acacia",
  "Jamun",
  "Eucalyptus",
  "Mustard",
  "Sidr",
  "Clover",
  "Sunflower",
  "Karanj",
  "Shesham",
  "Neem",
  "Kadi Patta",
];

export const DUMMY_HONEY_TYPES_WITH_LABELS = [
  { label: "Jamun", value: "Jamun" },
  { label: "Eucalyptus", value: "Eucalyptus" },
  { label: "Mustard", value: "Mustard" },
  { label: "Ajwain", value: "Ajwain" },
  { label: "Litchi", value: "Litchi" },
  { label: "Sidr", value: "Sidr" },
  { label: "Clover", value: "Clover" },
  { label: "Sunflower", value: "Sunflower" },
  { label: "Karanj", value: "Karanj" },
  { label: "Shesham", value: "Shesham" },
  { label: "Jhanti", value: "Jhanti" },
  { label: "Acacia", value: "Acacia" },
  { label: "Solai", value: "Solai" },
  { label: "Cotton", value: "Cotton" },
  { label: "Kadi Patta", value: "Kadi Patta" },
  { label: "Bansa", value: "Bansa" },
];

export const statesList = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];

export const monthsOfYear = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const monthsOfYearWithLabels = [
  { label: "January", value: "January" },
  { label: "February", value: "February" },
  { label: "March", value: "March" },
  { label: "April", value: "April" },
  { label: "May", value: "May" },
  { label: "June", value: "June" },
  { label: "July", value: "July" },
  { label: "August", value: "August" },
  { label: "September", value: "September" },
  { label: "October", value: "October" },
  { label: "November", value: "November" },
  { label: "December", value: "December" },
];

export const ORDER_STATUS_ARRAY = ["Draft", "Placed", "Cancelled", "Delivered"];

export const BEE_Array = [
  "Apis Mellifera",
  "Apis Cerena Indica",
  "Apis Florea",
  "Apis Dorsata",
  "Apis Trigona",
];

export const HONEY_FLORAL_TYPES = ["Monofloral", "Multifloral"];

export const ROLE_OPTIONS = 
  [
    { value: "farmer", label: "Farmer" },
    { value: "expert", label: "Expert" },
    { value: "community_leader", label: "Community Leader" },
    { value: "bee_mitra", label: "Bee Mitra" },
    { value: "beekeeper", label: "Beekeeper" },
    { value: "beekind_app_admin", label: "Beekind App Admin" },
    { value: "super_admin", label: "Super Admin" },
    { value: "field_view_app_admin", label: "Field View App Admin" },
    { value: "funder", label: "Funder" },
  ]