/** @format */

import React, { useEffect, useMemo, useState } from "react";
import * as Styles from "./style";
import { Link, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { changeDateTimeFormat } from "../shared/Helper";
import { usePagination, useTable, useSortBy } from "react-table";
import editIcon from "../assets/images/edit.png";
import deleteIcon from "../assets/images/delete.png";
import ProjectForm from "./components/ProjectForm/ProjectForm";
import { useDispatch, useSelector } from "react-redux";
import { getAllProjects } from "../reduxLogic/reducers/projects/getAllProjectSlice";
import { getAllClusters } from "../reduxLogic/reducers/clusters/getAllClustersSlice";
import { getClusterByProject } from "../reduxLogic/reducers/clusters/getClusterByProjectSlice";
import EditProjectForm from "./components/EditProjectForm/EditProjectForm";
import DeleteModal from "./components/DeleteModal/DeleteModal";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const Clusters = () => {
  var { projectId } = useParams();

  const formatDate = (date) => {
    if (!date) return "-";
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const Project_TABLE_COLUMNS = [
    { Header: "Cluster ID", accessor: "clusterId" },
    { Header: "Cluster", accessor: "clusterName" },
    { Header: "Project", accessor: "projectId.name" },
    { Header: "Status", accessor: "status" },
    { Header: "State", accessor: "state" },
    { Header: "District", accessor: "district" },
    {
      Header: "Created On",
      accessor: "createdOn",
      Cell: ({ value }) => formatDate(value),
    },
    { Header: "Edit" },
  ];

  const [openModal, setOpenModal] = useState(false);
  const [singleRow, setSingleRow] = useState({});
  const [idToEdit, setIdToEdit] = useState("");
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState();
  const [openEditModal, setOpenEditModal] = useState(false);

  const currentToken = cookies.get("access_token");
  const decoded = jwtDecode(currentToken);
  const role = decoded.role;

  const dispatch = useDispatch();

  const fetchAllProjectsData = () => {
    dispatch(getAllClusters());
  };

  const fetchClusterByProjectId = () => {
    dispatch(getClusterByProject(projectId));
    console.log("projectId", projectId);
    console.log("clusterData", clusterData);
  };

  useEffect(() => {
    if (projectId) {
      fetchClusterByProjectId();
    } else {
      fetchAllProjectsData();
    }
  }, [dispatch, projectId]);

  const { clusterData } = useSelector((state) => state.clusterData);
  const { clusterViaProjectData } = useSelector(
    (state) => state.clusterViaProjectData
  );

  console.log(
    "clusterData",
    clusterData,
    "clusterViaProjectData",
    clusterViaProjectData
  );

  const columns = useMemo(() => {
    const newColumns = [...Project_TABLE_COLUMNS];
    // newColumns[0] = {
    //   Header: "S.No",
    //   accessor: (_, index) => `P${index + 1}`,
    // };
    return newColumns;
  }, []);

  const data = useMemo(() => {
    // If clusterData is empty, use clusterViaProjectData
    return clusterData.length > 0 && !projectId  ? clusterData : clusterViaProjectData || [];
  }, [clusterData, clusterViaProjectData]);

  const tableInstance = useTable({ columns, data }, useSortBy, usePagination);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
  } = tableInstance;

  const { pageIndex } = state;

  const handleModalToggle = () => {
    setOpenModal((prev) => !prev);
  };

  const handleOpenEditModal = (id, singleRow) => {
    console.log("singleRow", singleRow);
    setSingleRow(singleRow);
    setIdToEdit(id);
    setOpenEditModal(true);
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteModal(true);
    setIdToDelete(id);
  };

  return (
    <Styles.SupplierBox>
      <Styles.SupplierTopSection>
        <Styles.ModalButton onClick={handleModalToggle}>
          Add Cluster
        </Styles.ModalButton>
        {openModal && (
          <ProjectForm openModal={openModal} setOpenModal={setOpenModal} />
        )}
      </Styles.SupplierTopSection>

      <Styles.TableContainer>
        {clusterData || clusterViaProjectData ? (
          <Styles.Table {...getTableProps()}>
            <Styles.THead>
              {headerGroups.map((headerGroup) => (
                <Styles.THeadRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((headerColumn) => (
                    <Styles.TH
                      {...headerColumn.getHeaderProps(
                        headerColumn.getSortByToggleProps()
                      )}
                    >
                      {headerColumn.render("Header")}
                      <span>
                        {headerColumn.isSorted
                          ? headerColumn.isSortedDesc
                            ? " 🔼"
                            : " 🔽"
                          : ""}
                      </span>
                    </Styles.TH>
                  ))}
                </Styles.THeadRow>
              ))}
            </Styles.THead>

            <Styles.TBody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <Styles.TBodyRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <Styles.TD key={cell.column.id}>
                          {cell.column.Header === "BeeMitras" ? (
                            <Link to={`/beemitras/${row.original._id}`}>
                              {cell.render("Cell")}
                            </Link>
                          ) : (
                            <>
                              {cell.column.Header === "Edit" ? (
                                <Styles.ActionSpan>
                                  <button
                                    onClick={() =>
                                      handleOpenEditModal(
                                        row.original._id,
                                        row.original
                                      )
                                    }
                                    title="Edit Project"
                                  >
                                    <img src={editIcon} alt="Edit" />
                                  </button>
                                  {role === "Admin" && (
                                    <button
                                      onClick={() =>
                                        handleOpenDeleteModal(row.original._id)
                                      }
                                      title="Delete Project"
                                    >
                                      <img src={deleteIcon} alt="Delete" />
                                    </button>
                                  )}
                                </Styles.ActionSpan>
                              ) : (
                                cell.render("Cell")
                              )}
                            </>
                          )}
                        </Styles.TD>
                      );
                    })}
                  </Styles.TBodyRow>
                );
              })}
            </Styles.TBody>
          </Styles.Table>
        ) : (
          <h3>No projects to display</h3>
        )}
      </Styles.TableContainer>

      <Styles.PaginationmainContainer>
        <span>
          <strong>
            Page {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <Styles.PaginationDiv>
          <Styles.PaginationButton
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </Styles.PaginationButton>
          <Styles.PaginationButton
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            Previous
          </Styles.PaginationButton>
          <Styles.PaginationInputDiv>
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
            />
          </Styles.PaginationInputDiv>
          <Styles.PaginationButton onClick={nextPage} disabled={!canNextPage}>
            Next
          </Styles.PaginationButton>
          <Styles.PaginationButton
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </Styles.PaginationButton>
        </Styles.PaginationDiv>
      </Styles.PaginationmainContainer>

      {openEditModal && (
        <EditProjectForm
          openEditModal={openEditModal}
          setOpenEditModal={setOpenEditModal}
          idToEdit={idToEdit}
          setIdToEdit={setIdToEdit}
          singleRow={singleRow}
          setSingleRow={setSingleRow}
        />
      )}
      {openDeleteModal && (
        <DeleteModal idToDelete={idToDelete} setDeleteModal={setDeleteModal} />
      )}
    </Styles.SupplierBox>
  );
};

export default Clusters;
