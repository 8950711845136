import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async Thunk to handle user creation
export const makeUser = createAsyncThunk(
    'makeUser',
    async ({ userInfo, userMetaInfo }, { rejectWithValue }) => {  // Use object destructuring for payload
        console.log("in makeUser thunk", userInfo, userMetaInfo);
        try {
            const response = await axios.post(`${process.env.REACT_APP_HUMBLEBEE_API_URL}/users/create`, {
                userInfo, userMetaInfo
            });
            alert("User Created Successfully");
            return response.data;
        } catch (error) {
            alert("Failed to create user");
            // Return a custom error message if the request fails
            return rejectWithValue(error.response?.data?.message || 'Failed to create user');
        }
    }
);


const initialState = {
    loading: false,
    error: null,
    message: null,
};

// Slice
const makeUserSlice = createSlice({
    name: 'makeUser',
    initialState,
    reducers: {
        // Resets the error state
        resetErrorinMakeUser: (state) => {
            state.error = null;
        },
        // Resets the success message
        resetMessageinMakeUser: (state) => {
            state.message = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(makeUser.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(makeUser.fulfilled, (state, action) => {
                state.loading = false;
                state.message = 'User created successfully';
                state.error = null;
            })
            .addCase(makeUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.message = null;
            });
    },
});

// Export actions
export const { resetErrorinMakeUser, resetMessageinMakeUser } = makeUserSlice.actions;

// Export the reducer to be included in the store
export default makeUserSlice.reducer;
