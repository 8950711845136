import React, { useEffect, useState, useRef } from "react";
import * as Styles from "./style";
import { Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import close from "../../../assets/images/close.png";
import { InputGroup } from "../../../shared/InputGroup/InputGroup";
import { TextareaGroup } from "../../../shared/TextareaGroup/TextareaGroup";
import CheckboxGroup from "../../../shared/CheckboxDropDown/CheckBoxGroup";
import {
  BEE_Array,
  DUMMY_FARMERS,
  DUMMY_HONEY_TYPES,
  ORDER_STATUS_ARRAY,
  monthsOfYearWithLabels,
} from "../../../shared/Constants/Constants";

//@ts-ignore
import { getAllBatches } from "../../../reduxLogic/reducers/batches/getAllBatchesSlice";
import { getAllFarmers } from "../../../reduxLogic/reducers/farmers/getAllFarmersSlice";
import { FieldErrorMessage } from "../../../shared/ErrorMessage/FieldErrorMessage";
import {
  editSingleBatch,
  resetErrorinEditBatch,
  resetMessageinEditBatch,
} from "../../../reduxLogic/reducers/batches/editSingleBatchSlice";
import {
  getSingleBatch,
  resetSingleBatchData,
} from "../../../reduxLogic/reducers/batches/getSingleBatchSlice";
import { MultiSelect } from "react-multi-select-component";
import LoadingSpinner from "../../../shared/LoadingSpinner/LoadingSpinner";
import { getAllHoney } from "../../../reduxLogic/reducers/honeys/getAllHoneySlice";
import moment from "moment";
import {
  renderPreviewForCurrent,
  renderPreviewForPrevious,
} from "../../../shared/RenderPreview/RenderPreview";
import LabelValueCheckBox from "../../../shared/LabelValueCheckBox/LabelValueCheckBox";
import Swal from "sweetalert2";

const batchSchema = Yup.object().shape({
  //supplier: Yup.string().required("Supplier Name is required"),
  dateOfOrder: Yup.string().required("Date of Order is required"),
  // dateOfDelivery: Yup.string().required("Date of Delivery is required"),
  nectorSource: Yup.string().required("Nector Source is required"),
  origin: Yup.string().required("Origin is required"),
  batchItems: Yup.array()
    .of(
      Yup.object().shape({
        honeyType: Yup.mixed().required("Honey Type is required"),
        volume: Yup.string().required("Volume is required"),
        contributingFarmers: Yup.array()
          .min(1, "At least one farmer should be selected")
          .required("Contributing Farmers is required"),
      })
    )
    .min(1, "At least one batch item is required"),
});

export const EditBatchForm = (props) => {
  const {
    openEditBatchModal,
    setOpenEditBatchModal,
    idToEdit,
    // singleRow,
    // setSingleRow,
  } = props;
  const [singleRow, setSingleRow] = useState(props.singleRow);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedHarvestPeriod, setSelectedHarvestPeriod] = useState([]);
  const [selectedContributingFarmers, setSelectedContributingFarmers] =
    useState(singleRow?.batchItems?.contributingFarmers?.map(() => []));
  const [allBatchDocuments, setAllBatchDocuments] = useState([]);
  const { farmersData } = useSelector((state) => state.allFarmers);
  const { suppliersData } = useSelector((state) => state.allSuppliers);
  const [isBatchDataAvailable, SetIsBatchDataAvailable] = useState(true);
  const [documents,setDocuments] = useState(props?.singleRow?.batchDocuments || []);

  // const farmerNamesArray = farmersData.map((farmer) => ({
  //   ...farmer,
  //   label: farmer.farmerName,
  //   value: farmer._id,
  // }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllFarmers());
  }, [dispatch]);

  // useEffect(() => {
  //   fetchSingleBatchData();
  // }, [idToEdit, dispatch]);

  const formikBatchRef = useRef(null);
  const { error, message } = useSelector((state) => state.updateBatch);

  //console.log("this is singleRow from editbatch ============> ", singleRow);

  // const { batchData } = useSelector((state) => state.getSingleBatch);

  // console.log("batchData from editbatchform ==> ", batchData);

  // const fetchSingleBatchData = () => {
  //   dispatch(getSingleBatch(idToEdit));
  // };

  useEffect(() => {
    if (singleRow) {
      formikBatchRef.current.setValues(singleRow);

      const preselectedOptions =
        singleRow &&
        singleRow?.harvestPeriod?.map((months) => ({
          label: months,
          value: months,
        }));

      setSelectedHarvestPeriod(preselectedOptions);
    }
  }, [singleRow, idToEdit]);

  // useEffect(() => {
  //   if (batchData && batchData?.length > 0) {
  //     SetIsBatchDataAvailable(true);
  //   }
  // }, [batchData, idToEdit]);

  useEffect(() => {
    dispatch(getAllHoney());
  }, []);

  const { honeyData } = useSelector((state) => state.allHoneys);

  const newHoneyArray = honeyData?.map((item) => ({
    label: `${item.honeyId}-${item.floralSource}`,
    value: item._id,
  }));

  const handleBatchDocumentsChange = (e, index) => {
    const { files } = e.target;
    setAllBatchDocuments((prev) => [...prev, ...files]);
  };

  const handleClose = () => {
    dispatch(resetSingleBatchData());
    setOpenEditBatchModal(false);
  };

  const handleClearSelection = (e) => {
    e.preventDefault();
    const fileInput = document.querySelector('input[name="batchDocuments"]');
    if (fileInput) {
      fileInput.value = null;
    }
    setAllBatchDocuments([]);
  };

  const handleSubmit = (values) => {

    setIsEditing(true);
    let formData = new FormData();

    const token = localStorage.getItem("humblebeeAuthToken");
    //const newBatchId=
    let tempSupplier = singleRow.supplier;

    let tempDateOfOrder = values.dateOfOrder
      ? values.dateOfOrder
      : singleRow.dateOfOrder;

    formData.append("dateOfOrder", tempDateOfOrder);

    let tempDateOfDelivery = values.dateOfDelivery
      ? values.dateOfDelivery
      : singleRow.dateOfDelivery || "";

    formData.append("dateOfDelivery", tempDateOfDelivery);

    let tempBatchItems = values.batchItems
      ? values.batchItems
      : singleRow.batchItems;

      for (let index = 0; index < tempBatchItems.length; index++) {
        const batchItem = tempBatchItems[index];
        const { honeyType, volume, contributingFarmers, bee } = batchItem;
      
        if (typeof honeyType != "string") {
          formData.append(`batchItems[${index}].honeyType`, honeyType._id);
        } else {
          formData.append(`batchItems[${index}].honeyType`, honeyType);
        }
        formData.append(`batchItems[${index}].volume`, volume);
        formData.append(`batchItems[${index}].bee`, bee);
      
        // Append contributingFarmers as separate properties
        for (let i = 0; i < contributingFarmers.length; i++) {
          const contributingFarmer = contributingFarmers[i];
          if (typeof contributingFarmer != "string") {
            formData.append(
              `batchItems[${index}].contributingFarmers[${i}]`,
              contributingFarmer._id
            );
          } else {
            formData.append(
              `batchItems[${index}].contributingFarmers[${i}]`,
              contributingFarmer
            );
          }
        }
      }
      
    let harvestMonths = selectedHarvestPeriod?.map((item) => item.value);

    harvestMonths.forEach((item, index) => {
      formData.append(`harvestPeriod[${index}]`, item);
    });

    let tempOrigin = values.origin ? values.origin : singleRow.origin;

    formData.append("origin", tempOrigin);

    let tempNectorSource = values.nectorSource
      ? values.nectorSource
      : singleRow.nectorSource;

    formData.append("nectorSource", tempNectorSource);

    let tempOrderStatus = values.orderStatus
      ? values.orderStatus
      : singleRow.orderStatus;

    formData.append("orderStatus", tempOrderStatus);

    formData.append("currentToken", token);

    
    let tempAllbatchDocuments =
      allBatchDocuments?.length > 0
        ? [...allBatchDocuments,...documents]
        : documents || singleRow.batchDocuments;

    for (let i = 0; i < tempAllbatchDocuments?.length; i++) {
      formData.append(`batchDocuments[${i}]`, tempAllbatchDocuments[i]);
    }

    let editId = singleRow._id;
    dispatch(editSingleBatch({ editId, formData }))
      .then(() => dispatch(getAllBatches()))
      .then(() => setOpenEditBatchModal(false))
      .then(() => resetSingleBatchData())
      .then(() => setIsEditing(false));
  };

  useEffect(() => {
    if (error) {
      toast.error(`${error}`);
      dispatch(resetErrorinEditBatch());
      dispatch(resetMessageinEditBatch());
    }

    if (!error && message) {
      toast.success("Batch was updated succesfully");
      dispatch(resetMessageinEditBatch());
    }
  }, [error, dispatch, message]);

  if (!singleRow) {
    return <LoadingSpinner />;
  }

  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        {singleRow ? (
          <Formik
            initialValues={singleRow}
            onSubmit={handleSubmit}
            validationSchema={batchSchema}
            validateOnMount
            innerRef={formikBatchRef}
            enableReinitialize
          >
            {(formik) => (
              <Styles.FormWithCloseButton>
                <Styles.CloseButtonDiv>
                  <h3>Edit Batch Form for ID {formik.values.batchId}</h3>
                  <Styles.FormCloseButton onClick={handleClose}>
                    <img src={close} alt="Close" />
                  </Styles.FormCloseButton>
                </Styles.CloseButtonDiv>

                <Styles.LFGroup>
                  <Styles.FieldArrayLabel htmlFor={formik.values.supplier}>
                    Supplier
                    <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                  </Styles.FieldArrayLabel>
                  <Styles.FieldArrayField
                    name="supplier"
                    as="select"
                    disabled={true}
                    //defaultValue={batchData.supplier}
                    value={formik?.values?.supplier?.supplierName}
                    onChange={formik.handleChange}
                  >
                    <option>{formik?.values?.supplier?.supplierName}</option>
                    {suppliersData?.map((singleSupplier, index) => (
                      <option
                        value={singleSupplier._id}
                        key={singleSupplier.supplierId}
                      >
                        {singleSupplier.supplierName}
                      </option>
                    ))}
                  </Styles.FieldArrayField>
                  <FieldErrorMessage name="supplier" />
                </Styles.LFGroup>

                <Styles.LFGroup>
                  <Styles.FieldArrayLabel htmlFor={formik.values.orderStatus}>
                    Order Status
                    <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                  </Styles.FieldArrayLabel>
                  <Styles.FieldArrayField
                    name="orderStatus"
                    as="select"
                    id="orderStatus"
                    value={formik.values.orderStatus}
                    onChange={formik.handleChange}
                  >
                    <option hidden>Select Order Status</option>
                    {ORDER_STATUS_ARRAY?.map((status, index) => (
                      <option value={status} key={status}>
                        {status}
                      </option>
                    ))}
                  </Styles.FieldArrayField>
                  <FieldErrorMessage name="orderStatus" />
                </Styles.LFGroup>

                <Styles.DateGroup>
                  <InputGroup
                    PlaceholderText="Enter Order Date"
                    LabelText="Order Date"
                    name="dateOfOrder"
                    type="datetime-local"
                    star="*"
                    defaultValue={
                      singleRow.dateOfOrder
                        ? singleRow.dateOfOrder.slice(0, 16)
                        : ""
                    }
                    onChange={formik.handleChange}
                  />

                  {formik.values.orderStatus != "Delivered" ||
                  formik.values.orderStatus == "" ? (
                    <InputGroup
                      PlaceholderText="Enter Delivery Date"
                      LabelText="Delivery Date"
                      name="dateOfDelivery"
                      type="datetime-local"
                      defaultValue={
                        singleRow.dateOfDelivery
                          ? singleRow.dateOfDelivery.slice(0, 16)
                          : ""
                      }
                      onChange={formik.handleChange}
                      isDisabled={true}
                    />
                  ) : (
                    <InputGroup
                      PlaceholderText="Enter Delivery Date"
                      LabelText="Delivery Date"
                      name="dateOfDelivery"
                      type="datetime-local"
                      defaultValue={
                        singleRow.dateOfDelivery
                          ? singleRow.dateOfDelivery.slice(0, 16)
                          : ""
                      }
                      onChange={formik.handleChange}
                      max={moment().format("YYYY-MM-DDTHH:mm")}
                    />
                  )}
                </Styles.DateGroup>

                <TextareaGroup
                  PlaceholderText="Enter Nector Source"
                  LabelText="Nector Source"
                  name="nectorSource"
                  type="text"
                  star="*"
                  value={formik.values.nectorSource}
                  //defaultValue={singleRow.nectorSource}
                  onChange={formik.handleChange}
                />

                <TextareaGroup
                  PlaceholderText="Enter Origin"
                  LabelText="Origin"
                  name="origin"
                  type="text"
                  star="*"
                  value={formik.values.origin}
                  //defaultValue={singleRow.origin}
                  onChange={formik.handleChange}
                />

                <div style={{ marginTop: "20px" }}>
                  <label style={{ fontWeight: "bold", marginBottom: "20px" }}>
                    Harvest Period
                    <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                  </label>

                  <div style={{ marginTop: "10px" }}>
                    <MultiSelect
                      options={monthsOfYearWithLabels}
                      value={selectedHarvestPeriod}
                      onChange={setSelectedHarvestPeriod}
                      labelledBy="Select"
                    />
                  </div>

                  {selectedHarvestPeriod &&
                  selectedHarvestPeriod?.length <= 0 ? (
                    <span style={{ color: "red" }}>
                      There should be atleast one month selected
                    </span>
                  ) : null}
                </div>
                <FieldArray name="batchItems">
                  {(arrayHelpers) =>
                    formik?.values?.batchItems?.map((batchItem, index) => (
                      <Styles.BatchItemsContainer key={index}>
                        <Styles.LabelFieldGroup>
                          <Styles.FieldArrayLabel htmlFor={batchItem.honeyType}>
                            Honey Type
                          </Styles.FieldArrayLabel>
                          <Styles.FieldArrayField
                            name={`batchItems[${index}].honeyType`}
                            as="select"
                            value={
                              typeof formik.values?.batchItems[index]
                                ?.honeyType != "string"
                                ? formik.values?.batchItems[index]?.honeyType
                                    ?._id
                                : formik.values?.batchItems[index]?.honeyType
                            }
                            onChange={formik.handleChange}
                          >
                            <option>Select Honey Type </option>
                            {newHoneyArray?.map((singleHoneyType, index) => {
                              return (
                                <option
                                  value={singleHoneyType.value}
                                  key={singleHoneyType.value}
                                >
                                  {singleHoneyType.label}
                                </option>
                              );
                            })}
                          </Styles.FieldArrayField>
                          <FieldErrorMessage
                            name={`batchItems.${index}.honeyType`}
                          />
                        </Styles.LabelFieldGroup>

                        <Styles.LabelFieldGroup>
                          <Styles.FieldArrayLabel
                            htmlFor={`batchItems[${index}].volume`}
                          >
                            Volume(kg)
                          </Styles.FieldArrayLabel>
                          <Styles.FieldArrayField
                            name={`batchItems[${index}].volume`}
                            type="number"
                            placeholder="Enter Volume"
                          />
                          <FieldErrorMessage
                            name={`batchItems.${index}.volume`}
                          />
                        </Styles.LabelFieldGroup>

                        <Styles.LabelFieldGroup>
                          <Styles.FieldArrayLabel
                            htmlFor={`batchItems[${index}].contributingFarmers`}
                          >
                            Contributing Farmers
                          </Styles.FieldArrayLabel>
                          <Field
                            name={`batchItems.${index}.contributingFarmers`}
                            render={({ field }) => (
                              <>
                                <Styles.MultiSelectAllShowDiv>
                                  {formik?.values?.batchItems[
                                    index
                                  ]?.contributingFarmers?.map(
                                    (singleFarmer, ind) => (
                                      <Styles.MultiSelectSingleShowDiv
                                        key={ind + Date.now()}
                                      >
                                        {singleFarmer?.farmerName}
                                      </Styles.MultiSelectSingleShowDiv>
                                    )
                                  )}
                                </Styles.MultiSelectAllShowDiv>
                                <LabelValueCheckBox
                                  id={`batchItems.${index}.contributingFarmers`}
                                  options={farmersData}
                                  onChange={formik.handleChange}
                                  name={`batchItems.${index}.contributingFarmers`}
                                  defaultValue={batchItem.contributingFarmers}
                                  formik={formik}
                                />
                              </>
                            )}
                          />
                          <FieldErrorMessage
                            name={`batchItems.${index}.contributingFarmers`}
                          />
                        </Styles.LabelFieldGroup>

                        <Styles.LabelFieldGroup>
                          <Styles.FieldArrayLabel htmlFor={batchItem.bee}>
                            Bee
                          </Styles.FieldArrayLabel>
                          <Styles.FieldArrayField
                            name={`batchItems[${index}].bee`}
                            as="select"
                            value={batchItem.bee}
                            onChange={formik.handleChange}
                          >
                            <option hidden>Select Bee</option>
                            {BEE_Array.map((singleBee, index) => (
                              <option value={singleBee} key={singleBee}>
                                {singleBee}
                              </option>
                            ))}
                          </Styles.FieldArrayField>
                          <FieldErrorMessage name={`batchItems.${index}.bee`} />
                        </Styles.LabelFieldGroup>

                        {/* <Styles.AddRemoveButtonContainer>
                          <Styles.RemoveButton
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <span>-</span>
                          </Styles.RemoveButton>
                          <Styles.AddButton
                            type="button"
                            onClick={() =>
                              arrayHelpers.push({
                                honeyType: "",
                                volume: "",
                                contributingFarmers: [],
                              })
                            }
                          >
                            <span>+</span>
                          </Styles.AddButton>
                        </Styles.AddRemoveButtonContainer> */}
                      </Styles.BatchItemsContainer>
                    ))
                  }
                </FieldArray>

                <Styles.ColumnFlex>
                  <Styles.Labels>Batch Documents</Styles.Labels>
                  <Styles.FileInputClearButtonDiv>
                    <button onClick={(e) => handleClearSelection(e)}>
                      <img src={close} alt="clear" />
                    </button>
                    <Styles.FileInput
                      type="file"
                      multiple
                      onChange={(e, index) =>
                        handleBatchDocumentsChange(e, index)
                      }
                      name="batchDocuments"
                      accept=".pdf, image/*"
                    />
                  </Styles.FileInputClearButtonDiv>

                  <Styles.IMGContainer>
                    <Styles.IMGTextBox>
                      <p>Previous</p>
                      <Styles.IMGBox>
                        {/* {formik?.values?.batchDocuments?.map((item, index) => (
                          // <img key={item} src={item} alt="documents" />
                          <div key={item}>
                            {renderPreviewForPrevious(item, index)}
                          </div>
                        ))} */}

              <FieldArray name="batchDocuments">
                                {({ push, remove }) => (
                                      <div style={{display:"flex"}}>
                              {formik?.values?.batchDocuments?.map((image, index) => (
                                       <div key={index} className="image" style={{ height:"30px",width:"100px",display:"flex" }}>
                                           <div className="caption" style={{background:"lightgrey"}} >
                                              {image.endsWith(".pdf") ?  <a className="anchor-tag" href={image}  target="_blank"
                                                rel="noopener noreferrer" >{`PDF Link ${index}`}</a> : 
                                                <a className="anchor-tag" href={image} target="_blank" 
                                                rel="noopener noreferrer" style={{marginLeft:3}}>Photo</a> }
                      
                      <button className="delete-button" style={{marginTop:4,marginRight:2}} type="button" onClick={() => {
                         Swal.fire({
                          title: 'Delete Confirmation',
                          text: 'Are you sure you want to delete this item?',
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: 'rgb(255, 135, 1)',
                          cancelButtonColor: 'lightgrey',
                          confirmButtonText: 'Delete',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            remove(index)
                            setDocuments(() => documents.filter((_,i) => i !== index ) )
                            Swal.fire('Deleted!', 'The item has been deleted.', 'success');
                          }
                        });
                        }}>
                        <img src={close} alt="clear"  style={{height:'10px'}}/></button>
                        </div>
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>
                      </Styles.IMGBox>
                    </Styles.IMGTextBox>

                    <Styles.IMGTextBox>
                      <p>Current</p>
                      <Styles.IMGBox >
                        {allBatchDocuments?.length > 0
                          ? allBatchDocuments?.map((item, index) => (
                             <div key={index} className="image" style={{ height:"100px",width:"120px",display:"flex" }}>
                                 <div> {renderPreviewForCurrent(item, index)} </div>
                                   <button className="delete-button" type="button" onClick={() => {
                                      
                                          
                                       Swal.fire({
                                        title: 'Delete Confirmation',
                                        text: 'Are you sure you want to delete this item?',
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: 'rgb(255, 135, 1)',
                                        cancelButtonColor: 'lightgrey',
                                        confirmButtonText: 'Delete',
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          let filterBatchDocuments = allBatchDocuments.filter((item,i) => i !== index ); 
                                          setAllBatchDocuments(filterBatchDocuments) ;
                                          Swal.fire('Deleted!', 'The item has been deleted.', 'success');
                                        }
                                      });


                                       }} >
                                            <img src={close} alt="clear"  style={{height:'10px'}}/>
                                        </button>
                                        </div>
                            ))
                          : null}
                      </Styles.IMGBox>
                    </Styles.IMGTextBox>
                  </Styles.IMGContainer>
                </Styles.ColumnFlex>
                <Styles.CreateBatchButton
                  //disabled={!formik.dirty || !formik.isValid}
                  type="submit"
                >
                  {!isEditing ? "Edit" : "...editing"}
                </Styles.CreateBatchButton>
              </Styles.FormWithCloseButton>
            )}
          </Formik>
        ) : (
          <div>
            <h3>There was error fetching details of Batch</h3>
            <button
              style={{
                border: "none",
                padding: "8px",
                borderRadius: "8px",
                background: "#ff8701",
              }}
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        )}
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  );
};
