/** @format */

import React, { useState, useEffect } from "react";
import * as Styles from "./style";
import { Formik } from "formik";
import * as Yup from "yup";

import "react-quill/dist/quill.snow.css";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import close from "../../../assets/images/close.png";

import { BlockData } from "../../../shared/Constants/blocks";
import { SelectGroup } from "../../../shared/SelectGroup/SelectGroup";

import { getAllProjects } from "../../../reduxLogic/reducers/projects/getAllProjectSlice";
import { getAllClusters  } from "../../../reduxLogic/reducers/clusters/getAllClustersSlice";
import { createCluster, resetErrorinClusterCreation } from "../../../reduxLogic/reducers/clusters/clusterCreationSlice";
import Select from "react-select";
import { InputGroup } from "../../../shared/InputGroup/InputGroup";
import { TextareaGroup } from "../../../shared/TextareaGroup/TextareaGroup";

const initialValues = {
  project: "",
  clusterName: "",
  status: "",
  state: "",
  district: "",
  beekeepersCount: 0,
  beeMitraIds: [],
  comments: "",
};

const projectSchema = Yup.object().shape({
  clusterName: Yup.string().required("Cluster Name is required"),
  status: Yup.string().required("Status is required"),
  state: Yup.string().required("State is required"),
  district: Yup.string().required("District is required"),
});

const ProjectForm = (props) => {
  const { openModal, setOpenModal } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(initialValues.status);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [villages, setVillages] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");

  const { projectData } = useSelector((state) => state.allProjects || {});
  const { error, message } = useSelector((state) => state.createProject);

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenModal((prev) => !prev);
  };

  useEffect(() => {
    // dispatch(getAllClusters());
    dispatch(getAllProjects());
  }, [dispatch]);

  useEffect(() => {
    // Extract unique states from the JSON data
    const initialUniqueStates = [
      ...new Set(BlockData.map((item) => item.state)),
    ];
    setStates(initialUniqueStates);
  }, []);


  const statusOptions = ["active", "inactive"];

  const handleStateChange = (tempSelectedState) => {
    setSelectedState(tempSelectedState);

    const uniqueDistricts = [
      ...new Set(
        BlockData.filter((item) => item.state === tempSelectedState).map(
          (item) => item.district
        )
      ),
    ];
    setDistricts(uniqueDistricts);
    setBlocks([]);
    setVillages([]);
  };

  const handleDistrictChange = (tempSelectedDistrict) => {
    setSelectedDistrict(tempSelectedDistrict);

    const uniqueBlocks = [
      ...new Set(
        BlockData.filter(
          (item) => item.state === selectedState && item.district === tempSelectedDistrict
        ).map((item) => item.block)
      ),
    ];
    setBlocks(uniqueBlocks);
    setVillages([]);
  };

  const handleBlockChange = (tempSelectedBlock) => {
    // setSelectedBlock(tempSelectedBlock);

    const uniqueVillages = [
      ...new Set(
        BlockData.filter(
          (item) =>
            item.state === selectedState &&
            item.district === selectedDistrict &&
            item.block === tempSelectedBlock
        ).map((item) => item.village)
      ),
    ];
    setVillages(uniqueVillages);
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);

    const projectData = {
      clusterName: values.clusterName,
      status: values.status,
      state: values.state,
      district: values.district,
      // block: values.block,
      // village: values.village,
      comments: values.comments,
      projectId: values.project,
    };

    dispatch(createCluster(projectData))
      .then(() => dispatch(getAllClusters()))
      .then(() => setOpenModal(false));
  };

  useEffect(() => {
    if (error) {
      toast.error(`${error}`);
      dispatch(resetErrorinClusterCreation());
    }

    if (!error && message) {
      toast.success("Cluster created successfully");
      // dispatch(resetMessageinClusterCreation());
    }
  }, [error, dispatch, message]);

  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={projectSchema}
          validateOnMount
        >
          {(formik) => (
            <Styles.FormWithCloseButton>
              <Styles.CloseButtonDiv>
                <h3>Cluster Creation Form</h3>
                <Styles.FormCloseButton onClick={handleClose}>
                  <img src={close} alt="Close" />
                </Styles.FormCloseButton>
              </Styles.CloseButtonDiv>

              <Styles.MainSupplierFormDiv>
                <label>Project</label>
                <Select
                  isSearchable
                  options={projectData?.map((project) => ({
                    value: project._id,
                    label: project.name,
                  }))}
                  onChange={(selectedOption) => {
                    formik.setFieldValue("project", selectedOption.value);
                  }}
                />
                <InputGroup
                  PlaceholderText="Enter Cluster Name"
                  LabelText="Cluster Name"
                  name="clusterName"
                  type="text"
                  star="*"
                  onChange={formik.handleChange}
                />

                <Styles.RowFlex>
                  <SelectGroup
                    PlaceholderText="Status"
                    LabelText="Select status"
                    name="status"
                    onChange={(e) => {
                      setSelectedStatus(e.target.value);
                      formik.handleChange(e);
                    }}
                    value={selectedStatus}
                    options={statusOptions}
                    star="*"
                  />
                </Styles.RowFlex>

                <Styles.RowFlex>
                  <Styles.ColHalf>
                  <SelectGroup
                      PlaceholderText="Select State"
                      LabelText="State"
                      name="state"
                      onChange={(e) => {
                        handleStateChange(e.target.value);
                        formik.handleChange(e);
                      }}
                      value={selectedState}
                      defaultValue={selectedState}
                      options={states}
                      star="*"
                    />
                  </Styles.ColHalf>

                  <Styles.ColHalf>
                    <label>District</label>
                    <Select
                      options={districts.map((district) => ({
                        value: district,
                        label: district,
                      }))}
                      onChange={(selectedOption) => {
                        handleDistrictChange(selectedOption.value);
                        formik.setFieldValue("district", selectedOption.value);
                      }}
                    />
                  </Styles.ColHalf>
                </Styles.RowFlex>

                <Styles.RowFlex>
                  <InputGroup
                    PlaceholderText="Enter Beekeepers Count"
                    LabelText="Beekeepers Count"
                    name="beekeepersCount"
                    type="number"
                    onChange={formik.handleChange}
                  />
                  {/* <Select
                    isMulti
                    options={beekeepers.map((beekeeper) => ({
                      value: beekeeper._id,
                      label: beekeeper.name,
                    }))}
                    onChange={(selectedOptions) => {
                      const selectedBeeMitras = selectedOptions.map(
                        (option) => option.value
                      );
                      formik.setFieldValue("beeMitraIds", selectedBeeMitras);
                    }}
                    value={formik.values.beeMitraIds.map((beekeeper) => ({
                      value: beekeeper,
                      label: beekeeper,
                    }))}
                  /> */}
                </Styles.RowFlex>
              </Styles.MainSupplierFormDiv>
              <TextareaGroup
                PlaceholderText="Enter Comments"
                LabelText="Comments"
                name="comments"
                onChange={formik.handleChange}
              />

              <Styles.CreateSupplierButton
                type="submit"
                disabled={isSubmitting || !formik.isValid}
              >
                {isSubmitting ? "Submitting..." : "Create"}
              </Styles.CreateSupplierButton>
            </Styles.FormWithCloseButton>
          )}
        </Formik>
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  );
};

export default ProjectForm;
