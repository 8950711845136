import React, { Component } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Sidebar from './components/partials/0-menus/Sidebar';
import TopMenu from './components/partials/0-menus/TopMenu';

import Dashboard from './components/partials/1-dashboard/Dashboard';

import Leads from './components/partials/2-leads/Leads';
import AddNewLead from './components/partials/2-leads/AddNewLead';
import CreateNewLead from './components/partials/2-leads/CreateNewLead';
import SingleLead from './components/partials/2-leads/SingleLead';
import EditLead from './components/partials/2-leads/EditLead';

//Humblebee
import Clusters from './components/partials/humblebee/Clusters/index.js';
import Users from './components/partials/humblebee/Users/UsersTable.jsx';
import Projects from './components/partials/humblebee/Projects/index.js';
import Batches from './components/partials/humblebee/Batches/index.js';
import Suppliers from './components/partials/humblebee/Suppliers/index.jsx';
import Honeys from './components/partials/humblebee/honey/HoneyTable.jsx';
import SingleHoney from './components/partials/humblebee/honey/components/SingleHoney/SingleHoney.jsx';
import SingleBatch from './components/partials/humblebee/Batches/components/SingleBatch/SingleBatch.jsx';
import Surveys from './components/partials/humblebee/Surveys/index.js';
// import MatureLeads from './components/partials/2-leads/MatureLeads';

import BPlans from './components/partials/10-bplans/BPlans';
import Schemes from './components/partials/11-schemes/Schemes';
import Service from './components/partials/enterprise-support-service/Service';

import EnterpriseCreated from './components/partials/2-leads/EnterpriseCreated';

import TCACR from './components/partials/9-tcacr/TCACR';

import Newsletter from './components/partials/12-newsletter/Newsletter'

import IVRSLeadsDashboard from './components/partials/5-ivrs/leads/IVRSLeadsDashboard';

import AllActivities from './components/partials/6-activities/AllActivities';

// import ResourcesTypes from './components/partials/7-resources-types/ResourcesTypes';
// import ResourcesOfSingleType from './components/partials/7-resources-types/ResourcesOfSingleType';

import MyTasks from './components/partials/8-tasks/MyTasks';
import FollowUpAnalytics from './components/partials/analytics/components/followUpAnalytics';
//training center imports
import Analytics from './components/partials/analytics/components/graph';
import UpdateContact from "./components/partials/training-center/contactDetails/pages/updateContact";
import Navpage from './components/partials/training-center/contactDetails/pages/Navbarpage'
import UserCenter from './components/partials/training-center/components/pages/userCenter';
import UpdateCenter from './components/partials/training-center/components/pages/updateCenter';

// import UserBank from './components/partials/bank-branches/components/pages/userBank';
import BankBranches from './components/partials/13-bankBranches/BankBranches';
import BankProfile from './components/partials/13-bankBranches/BankProfile';

// import UpdateBank from './components/partials/bank-branches/components/pages/updateBank';
import MessagePage from './components/partials/communication/maturation-messages/pages/table';
import Tracker from './components/partials/follow-up-tracker/pages/button';
//import ShowButton from './components/partials/training-center/components/FormElements/addCenterButton';

// import AllSuccessStories from './components/partials/7-successStories/AllSuccessStories';
import BPlanLiterature from './components/partials/15-bplan-literature/BplanLiterature';
import Settings from './components/partials/3-settings/Settings';
import ModuleManagement from './components/partials/3-settings/ModuleManagement';
import PlatformUserTypes from './components/partials/3-settings/PlatformUserTypes';
import PlatformUsers from './components/partials/3-settings/PlatformUsers';
import PlatformOrgs from './components/partials/3-settings/PlatformOrgs';
import PlatformOrg from './components/partials/3-settings/PlatformOrg';
import PlatformOrgEdit from './components/partials/3-settings/PlatformOrgEdit';
import PlatformUser from './components/partials/3-settings/PlatformUser';
import AddPlatformUser from './components/partials/3-settings/AddPlatformUser';

import PartnerLogin from './components/partials/4-partner/PartnerLogin';
import PartnerRegister from './components/partials/4-partner/PartnerRegister';
import PartnerVerification from './components/partials/4-partner/PartnerVerification';

import Layout from './components/partials/Layout';
import ChangePasswordLayout from './components/partials/ChangePasswordLayout';
import NotFound from './components/NotFound';

import SessionSummary from './components/partials/session/sessionSummary';

// stake holders module
import ManageStakeholders from './components/partials/stakeholders/Screens/ManageStakeholders/ManageStakeholders';
// import ManageStakeholders from './components/partials/stakeholders/Screens/ManageStakeholders/ManageStakeholders';
import StakeHolderAddEditUser from './components/partials/stakeholders/Screens/AddEditUser/AddEditUser';
import StakeholderHome from './components/partials/stakeholders/Screens/Home/Home';
//for SCK analytics
import SckAnalytics from './components/partials/analytics/components/SckAnalytics';

// lead stats
import LeadStats from './components/partials/analytics/components/leadStats';
import SummaryStats from './components/partials/analytics/components/summaryStats';
import ProjectsLandingpage from './components/partials/16-projects-listing/ProjectsLandingPage';

import Dialer from './components/partials/dialer';
import BusinessIdeas from './components/partials/14-businessIdeas/BusinessIdeas';
// import BplanLiterature from './components/partials/15-bplan-literature/BplanLiterature';
import FocusStates from './components/partials/3-settings/FocusStates';
import GoogleMapEnterprises from './components/partials/GoogleMapEnterprises/GoogleMapEnterprises';
import UdaanOrgs from './components/partials/3-settings/UDAAN/UdaanOrgs';
import UdaanOrg from './components/partials/3-settings/UDAAN/UdaanOrg';
import UdaanOrgEdit from './components/partials/3-settings/UDAAN/UdaanOrgEdit';
import AddUdaanUser from './components/partials/3-settings/UDAAN/AddUdaanUser';
import { FieldVisits } from './components/partials/humblebee/FieldVisits/FieldVisits.js';
import { GroupMeetings } from './components/partials/humblebee/GroupMeetings/GroupMeetings.js';
const createCookie = require('./components/partials/cookie.js').createCookie;
const readCookie = require('./components/partials/cookie.js').readCookie;
const eraseCookie = require('./components/partials/cookie.js').eraseCookie;
const history = createBrowserHistory();
const knowlarity_emails = require('./emails_knowlarity.json');

// latest login check for multi project deloyment
const MULTI_PROJECT_DEPLOYMENT_DATE = new Date('2022-12-05T11:43:12.229Z').toISOString();

const DefaultAppLayout = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => (
      <div className="swati">
        <Sidebar logoutUser={rest.logoutUser} userData={rest.userData} toggleSidebar={rest.toggleSidebar} sidebarMinimized={rest.sidebarMinimized} />
        <TopMenu logoutUser={rest.logoutUser} userData={rest.userData} toggleSidebar={rest.toggleSidebar} sidebarMinimized={rest.sidebarMinimized} />
        <Component {...matchProps} userData={rest.userData} sidebarMinimized={rest.sidebarMinimized} />
        {(knowlarity_emails.includes(rest.userData.email) ||
        rest.userData.userType.name === "Zonal Manager" ||
        (rest.userData.userType.name ===
          "Swavalamban Fellows" || 
          rest.userData.userType.name === "Project Management Unit"
          // &&
          //   rest.userData.fellowAccessType === "Full"
          )) ?
        <div className="bottom-left-caller" style={{ position: "fixed", bottom: "20px", right: "20px", cursor: "pointer" }}><Dialer /></div>
      :null}</div>
    )} />
  )
}

export default class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: null,
      sidebarMinimized: true
    }
  }

  // ip info methods
  createNewSession() {
    fetch('https://ipapi.co/json/')
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        let userData = JSON.parse(readCookie('userData'));
        let body = {
          sessionObj: {
            ipAddress: data.ip,
            userId: userData.id,
            userName: userData.fullName,
            email: userData.email,
            city: data.city,
            state: data.region,
          }
        };
        fetch(process.env.REACT_APP_API_URL + '/api/v1/session', {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': JSON.parse(readCookie('access_token'))
          },
          body: JSON.stringify(body)
        }).then((response) => {
          return response.json();
        }).then((data) => {
          createCookie('swati_session', JSON.stringify(data.sessionData), 7);
        }).catch((error) => {
        });
      });
  }
  updateSession() {
    // let self=this;
    fetch('https://ipapi.co/json/')
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        // let userData = JSON.parse(readCookie('userData'));
        let sessionData = JSON.parse(readCookie('swati_session'));
        // if(sessionData.city!=data.city || sessionData.state!==data.region){
        //   self.createNewSession();
        // }
        // else{
        fetch(process.env.REACT_APP_API_URL + '/api/v1/session/update/' + sessionData._id, {
          method: "PUT",
          body: {
            // ipAddress: data.ip,
            // userId: userData.id,
            // userName: userData.fullName,
            // email: userData.email,
            city: data.city,
            state: data.state,
          }
        }).then((response) => {
          return response.json();
        }).then((data) => {
          //eraseCookie('swati_session');
          createCookie('swati_session', JSON.stringify(data.sessionData), 7);
        }).catch((error) => {
        });
        //  }
      });
  }
  componentWillMount() {

    if (readCookie('currentProjectDetails') && JSON.parse(readCookie("currentProjectDetails")).themeColor)
    { document.documentElement.style.setProperty('--themeColor', JSON.parse(readCookie("currentProjectDetails")).themeColor); }
    if (readCookie('userData') !== null) {
      this.setState({ userData: JSON.parse(readCookie('userData')) });
      if (!JSON.parse(readCookie('userData')).newKey || JSON.parse(readCookie('userData')).newKey<MULTI_PROJECT_DEPLOYMENT_DATE) {
        eraseCookie('userData');
        eraseCookie('access_token');
        eraseCookie('project_id');
        window.location.reload();
      }
      if (JSON.parse(readCookie('userData')).userType.name === 'Swavalamban Fellows' && !JSON.parse(readCookie('userData')).address.state) {
        eraseCookie('userData');
        eraseCookie('access_token');
        eraseCookie('project_id');
        window.location.reload();
      }
      // // loading session data
      // if (readCookie('swati_session') !== null){
      // this.updateSession();
      // }
      // else
      // {
      //   this.createNewSession();
      // }
    } else {
      eraseCookie('userData');
      eraseCookie('access_token');
      eraseCookie('project_id');

    }

    let sidebarMinimized = sessionStorage.getItem('sidebarMinimized');
    if (window.screen.width >= 500 && sidebarMinimized && sidebarMinimized === 'false') {
      this.setState({ sidebarMinimized: false });
    }
  }

  logoutUser = () => {
    //close session
    // let sessionId = JSON.parse(readCookie('swati_session'))._id;
    // fetch(process.env.REACT_APP_API_URL + '/api/v1/session/close/'+sessionId, {
    //   method: "PUT",
    // }).then((response) => {
    //   return response.json();
    // }).then((data) => {
    //   eraseCookie('swati_session');
    // }).catch((error) => {
    //   eraseCookie('swati_session');
    // });
    fetch(process.env.REACT_APP_API_URL + '/logout', {
      method: "POST",
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token'))
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
      eraseCookie('noticed');
      eraseCookie('userData');
      eraseCookie('project_id');
      eraseCookie('access_token');
      eraseCookie("currentProjectDetails");
      this.setState({ userData: null }, () => { window.location.pathname = '/' });
    }).catch((error) => {
      eraseCookie('noticed');
      eraseCookie('userData');
      eraseCookie('project_id');
      eraseCookie('access_token');
      eraseCookie("currentProjectDetails");
      this.setState({ userData: null }, () => { window.location.pathname = '/' });
    });
  }

  toggleSidebar = () => {
    let sidebarMinimized = this.state.sidebarMinimized;
    this.setState({ sidebarMinimized: !sidebarMinimized });
    sessionStorage.setItem('sidebarMinimized', !sidebarMinimized);
  }

  updateUser = (first_name, last_name, profile_picture, others) => {
    var userData = this.state.userData;
    userData.newKey = true;
    if (first_name !== '') userData['first_name'] = first_name;
    if (last_name !== '') userData['last_name'] = last_name;
    if (profile_picture !== '') userData['profile_picture'] = profile_picture;
    if (others) {
      if (others.hasOwnProperty('bank_name')) userData['bank_name'] = others['bank_name'];
      if (others.hasOwnProperty('bank_account_number')) userData['bank_account_number'] = others['bank_account_number'];
      if (others.hasOwnProperty('ifsc')) userData['ifsc'] = others['ifsc'];
      if (others.hasOwnProperty('upi')) userData['upi'] = others['upi'];
      if (others.hasOwnProperty('mobile')) userData['mobile'] = others['mobile'];
    }
    this.setState({ userData: userData });
    eraseCookie('project_id');
    eraseCookie('userData');
    createCookie('userData', JSON.stringify({ ...userData, newKey: new Date() }), 7);
  }

  render() {
    // return <GoogleMapEnterprises />;
    if (this.state.userData !== null) {
      return (
        <Router history={history}>
          <Switch>
            {/*@Todo : update project param array (userData.hasAccessToApp Array)  */}
            {console.log("")}
            {this.state.userData.accountType !== 'saarthi' ? (
              (this.state.userData.hasAccessToProjects.length > 1 && !readCookie('project_id'))   ?
              <Redirect exact from="/" to="/project-landing" /> : <Redirect exact from="/" to="/dashboard" />
            ) : (
              <Redirect exact from="/" to="/leads" />
            )}
            {(this.state.userData.type === 'le') ? (
              <DefaultAppLayout exact path="/bplan-literature" component={BPlanLiterature} userData={this.state.userData} logoutUser={this.logoutUser.bind(this)} />
            ) : (null)}
                        {(this.state.userData.type === 'le') ? (
              <DefaultAppLayout exact path="/enterprise-map" component={GoogleMapEnterprises} userData={this.state.userData} logoutUser={this.logoutUser.bind(this)} />
            ) : (null)}
            {this.state.userData.accountType !== 'saarthi' ? (
              <DefaultAppLayout exact path="/dashboard" component={Dashboard} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar} />
            ) : (null)}
            <Route exact path ='/project-landing' render={()=><ProjectsLandingpage  userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar}/> } />
            <DefaultAppLayout exact path="/sck-performance" component={SckAnalytics} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar} />

            {(this.state.userData.type === 'le') || (this.state.userData.type === 'sidbi') ?
              (<DefaultAppLayout exact path="/lead-stats" component={LeadStats} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar} />
              ) : (null)}
            {/* {(this.state.userData.type === 'le') || (this.state.userData.type === 'sidbi') ?
              (<DefaultAppLayout exact path="/lead-stats" component={BplanLiterature} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar} />
              ) : (null)} */}
            {(this.state.userData.type === 'le') ?
              (<DefaultAppLayout exact path="/summary-stats" component={SummaryStats} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar} />
              ) : (null)}
            
            <DefaultAppLayout exact path="/leads" component={Leads} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar} />
            <DefaultAppLayout exact path="/lead/:leadId/:type?" component={SingleLead} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar} />

            <DefaultAppLayout exact path="/clusters" component={Clusters} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar} />
            <DefaultAppLayout exact path="/projects" component={Projects} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar} />
            <DefaultAppLayout exact path="/groupMeetings/:projectId" component={GroupMeetings} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar} />
            <DefaultAppLayout exact path="/fieldVisits/:projectId" component={FieldVisits} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar} />
            <DefaultAppLayout exact path="/users" component={Users} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar} />
            <DefaultAppLayout exact path="/clusters/:projectId" component={Clusters} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar} />
            <DefaultAppLayout exact path="/suppliers" component={Suppliers} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar} />
            <DefaultAppLayout exact path="/suppliers/:_id" component={Suppliers} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar} />
             <DefaultAppLayout exact path="/honeys" component={Honeys} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar} />
             <DefaultAppLayout exact path="/honeys/:_id" component={SingleHoney} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar} />

              <DefaultAppLayout exact path="/batches" component={Batches} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar} />
              <DefaultAppLayout exact path="/batches/:_id" component={SingleBatch} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar} />
              <DefaultAppLayout exact path="/user-profile/:_id" component={Surveys} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar} />


            {this.state.userData.type === 'le' && this.state.userData.accountType !== 'saarthi' ? (
              <DefaultAppLayout exact path="/offline-aspirants" component={TCACR} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)}

            {/* {this.state.userData.type === 'le' && this.state.userData.accountType !== 'saarthi' ? (
              <DefaultAppLayout exact path="/mature-leads/:type?" component={MatureLeads} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)} */}

            {this.state.userData.type === 'le' && this.state.userData.userType.name === 'Project Management Unit' ? (
              <DefaultAppLayout exact path="/business-plans" component={BPlans} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)}

            {this.state.userData.type === 'le' && ['Project Management Unit', "Swavalamban Fellows"].includes(this.state.userData.userType.name) ? (
              <DefaultAppLayout exact path="/schemes" component={Schemes} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)}
            {this.state.userData.type === 'le' && ['Project Management Unit', "Swavalamban Fellows"].includes(this.state.userData.userType.name) ? (
              <DefaultAppLayout exact path="/enterprise-support-service" component={Service} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)}
            {this.state.userData.userType.name === 'Project Management Unit' || this.state.userData.userType.name === 'Zonal Manager' ? (
              <DefaultAppLayout exact path="/follow-up-analytics" component={FollowUpAnalytics} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)}
            {this.state.userData.type === 'le' && this.state.userData.userType.name === 'Project Management Unit' ? (
              <DefaultAppLayout exact path="/analytics" component={Analytics} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)}

            {this.state.userData.type === 'le' && this.state.userData.email === 'monika@letsendorse.com' ? (
              <DefaultAppLayout exact path="/training-center" component={UserCenter} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)}

            {this.state.userData.type === 'le' && this.state.userData.email === 'monika@letsendorse.com' ? (
              <DefaultAppLayout exact path="/training-center/contactView/:centerId" component={Navpage} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)}

            {this.state.userData.type === 'le' && this.state.userData.email === 'monika@letsendorse.com' ? (
              <DefaultAppLayout exact path="/training-center/centers/:centerId" component={UpdateCenter} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)}

            {this.state.userData.type === 'le' && this.state.userData.email === 'monika@letsendorse.com' ? (
              <DefaultAppLayout exact path="/training-center/centers/contact/:contactId" component={UpdateContact} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)}

            {/* Sessions summary */}
            {this.state.userData.type === 'le' && (this.state.userData.email === 'monika@letsendorse.com') ? (
              <DefaultAppLayout exact path="/session/summary" component={SessionSummary} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)}
            {/*this.state.userData.type === 'le' && this.state.userData.email === 'monika@letsendorse.com' && false ? (
              <DefaultAppLayout exact path="/bank-branches" component={UserBank} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)*/}

            {this.state.userData.type === 'le'
              // ? (
              // && this.state.userData.email === 'monika@letsendorse.com'
              ? (
                <DefaultAppLayout exact path="/bank-branches" component={BankBranches} userData={this.state.userData} logoutUser={this.logoutUser} />
              ) : (null)}

              {this.state.userData.type === 'le'
              // ? (
              // && this.state.userData.email === 'monika@letsendorse.com'
              ? (
                <DefaultAppLayout exact path="/business-ideas" component={BusinessIdeas} userData={this.state.userData} logoutUser={this.logoutUser} />
              ) : (null)}

            {this.state.userData.type === 'le'
              // ? (
              // && this.state.userData.email === 'monika@letsendorse.com'
              ? (
                <DefaultAppLayout exact path="/bank-branches/:ifsc" component={BankProfile} userData={this.state.userData} logoutUser={this.logoutUser} />
              ) : (null)}

            {/*this.state.userData.type === 'le' && this.state.userData.email === 'monika@letsendorse.com' && false ? (
              <DefaultAppLayout exact path="/bank-branches/bank/edit/:bankId" component={UpdateBank} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)*/}

            {this.state.userData.type === 'le' && this.state.userData.email === 'monika@letsendorse.com' ? (
              <DefaultAppLayout exact path="/follow-up-tracker" component={Tracker} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)}

            {this.state.userData.type === 'le' && this.state.userData.email === 'monika@letsendorse.com' ? (
              <DefaultAppLayout exact path="/maturation-messages" component={MessagePage} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)}

            {(this.state.userData.type === 'le' && this.state.userData.accountType !== 'saarthi') || this.state.userData.type === 'sidbi' ? (
              <DefaultAppLayout exact path="/enterprises-created" component={EnterpriseCreated} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)}

            {this.state.userData.type === 'le' && this.state.userData.userType.name === 'Project Management Unit' ? (
              <DefaultAppLayout exact path="/communication" component={Newsletter} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)}

            {this.state.userData.type !== 'sidbi' && this.state.userData.accountType !== 'saarthi' ? (
              <DefaultAppLayout exact path="/leads/new" component={AddNewLead} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar}/>
            ) : (null)}
            {this.state.userData.type !== 'sidbi' && false ? (
              <DefaultAppLayout exact path="/leads/create" component={CreateNewLead} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)}

            {this.state.userData.type !== 'sidbi' ? (
              <DefaultAppLayout exact path="/edit-lead/:leadId/:step?" component={EditLead} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar}/>
            ) : (null)}

            {this.state.userData.accountType !== 'saarthi' && this.state.userData.userType.name !== 'Funder' ? (
              <DefaultAppLayout exact path="/ivrs/leads" component={IVRSLeadsDashboard} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar}/>
            ) : (null)}

            {this.state.userData.type !== 'sidbi' && this.state.userData.accountType !== 'saarthi' ? (
              <DefaultAppLayout exact path="/ivrs-to-lead/:ivrsId" component={AddNewLead} userData={this.state.userData} logoutUser={this.logoutUser} sidebarMinimized={this.state.sidebarMinimized} toggleSidebar={this.toggleSidebar}/>
            ) : (null)}

            {this.state.userData.accountType !== 'saarthi' && this.state.userData.userType.name !== 'Funder' ? (
              <DefaultAppLayout exact path="/activities" component={AllActivities} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)}

            {this.state.userData.type === 'le' && this.state.userData.accountType !== 'saarthi' ? (
              <DefaultAppLayout exact path="/tasks" component={MyTasks} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)}

            {this.state.userData.type === 'le' && this.state.userData.accountType !== 'saarthi' ? (
              <DefaultAppLayout exact path="/stakeholders/user/:userId/:userName" component={StakeHolderAddEditUser} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)}
            {this.state.userData.type === 'le' && this.state.userData.accountType !== 'saarthi' ? (
              <DefaultAppLayout exact path="/stakeholders/manage" component={ManageStakeholders} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)}
            {this.state.userData.type === 'le' && this.state.userData.accountType !== 'saarthi' ? (
              <DefaultAppLayout exact path="/stakeholders" component={StakeholderHome} userData={this.state.userData} logoutUser={this.logoutUser} />
            ) : (null)}
            {/*<DefaultAppLayout exact path="/resources" component={ResourcesTypes} userData={this.state.userData} logoutUser={this.logoutUser} />
            <DefaultAppLayout exact path="/resources/:type" component={ResourcesTypes} userData={this.state.userData} logoutUser={this.logoutUser} />*/}

            {/*<DefaultAppLayout exact path="/success-stories" component={AllSuccessStories} userData={this.state.userData} logoutUser={this.logoutUser} />*/}

            <DefaultAppLayout exact path="/settings" component={Settings} userData={this.state.userData} logoutUser={this.logoutUser.bind(this)} />
            {(this.state.userData.type === 'le' && this.state.userData.accountType !== 'saarthi') || this.state.userData.type === 'sidbi' ? (
              <DefaultAppLayout exact path="/settings/user-types" component={PlatformUserTypes} userData={this.state.userData} logoutUser={this.logoutUser.bind(this)} />
            ) : (null)}
            {(this.state.userData.type === 'le' && this.state.userData.accountType !== 'saarthi') || this.state.userData.type === 'sidbi' ? (
              <DefaultAppLayout exact path="/settings/focus-locations" component={FocusStates} userData={this.state.userData} logoutUser={this.logoutUser.bind(this)} />
            ) : (null)}
            {(this.state.userData.type === 'le' && this.state.userData.userType.name === 'Project Management Unit') ? (
              <DefaultAppLayout exact path="/settings/modules" component={ModuleManagement} userData={this.state.userData} logoutUser={this.logoutUser.bind(this)} />
            ) : (null)}
            {this.state.userData.type === 'partners' || this.state.userData.accountType === 'saarthi' ? (
              <DefaultAppLayout exact path="/settings/:tab" component={Settings} userData={this.state.userData} logoutUser={this.logoutUser.bind(this)} />
            ) : (null)}
            {(this.state.userData.type === 'le' && this.state.userData.accountType !== 'saarthi') || this.state.userData.type === 'sidbi' ? (
              <DefaultAppLayout exact path="/user-types/:type" component={PlatformUsers} userData={this.state.userData} logoutUser={this.logoutUser.bind(this)} />
            ) : (null)}
            {(this.state.userData.type === 'le' && this.state.userData.userType.name === 'Project Management Unit') ? (
              <DefaultAppLayout exact path="/user-types/:type/add-user" component={AddPlatformUser} userData={this.state.userData} logoutUser={this.logoutUser.bind(this)} />
            ) : (null)}
            {(this.state.userData.type === 'le' && this.state.userData.accountType !== 'saarthi') || this.state.userData.type === 'sidbi' ? (
              <DefaultAppLayout exact path="/user-types/:type/:userId" component={PlatformUser} userData={this.state.userData} logoutUser={this.logoutUser.bind(this)} />
            ) : (null)}
            {this.state.userData.type === 'le' && this.state.userData.accountType !== 'saarthi' ? (
              <DefaultAppLayout exact path="/user-types/:type/:userId/edit" component={AddPlatformUser} userData={this.state.userData} logoutUser={this.logoutUser.bind(this)} />
            ) : (null)}
            {(this.state.userData.type === 'le' && this.state.userData.accountType !== 'saarthi') || this.state.userData.type === 'sidbi' ? (
              <DefaultAppLayout exact path="/org-types/:type" component={PlatformOrgs} userData={this.state.userData} logoutUser={this.logoutUser.bind(this)} />
            ) : (null)}
            {(this.state.userData.type === 'le' && this.state.userData.accountType !== 'saarthi') || this.state.userData.type === 'sidbi' ? (
              <DefaultAppLayout exact path="/org-types/:type/:orgId" component={PlatformOrg} userData={this.state.userData} logoutUser={this.logoutUser.bind(this)} />
            ) : (null)}
            {(this.state.userData.type === 'le' && this.state.userData.accountType !== 'saarthi') ? (
              <DefaultAppLayout exact path="/org-types/:type/:orgId/edit" component={PlatformOrgEdit} userData={this.state.userData} logoutUser={this.logoutUser.bind(this)} />
            ) : (null)}
            {(this.state.userData.type === 'le' && this.state.userData.accountType !== 'saarthi') ? (
              <DefaultAppLayout exact path="/org-types/:type/:orgId/add-user" component={AddPlatformUser} userData={this.state.userData} logoutUser={this.logoutUser.bind(this)} />
            ) : (null)}
            

            {/* 
            UDAAN CENTRES
             */}
            {(this.state.userData.type === 'le' && this.state.userData.accountType !== 'saarthi') || this.state.userData.type === 'sidbi' ? (
              <DefaultAppLayout exact path="/udaan-centres/:type" component={UdaanOrgs} userData={this.state.userData} logoutUser={this.logoutUser.bind(this)} />
            ) : (null)}
            {(this.state.userData.type === 'le' && this.state.userData.accountType !== 'saarthi') || this.state.userData.type === 'sidbi' ? (
              <DefaultAppLayout exact path="/udaan-centres/:type/:orgId" component={UdaanOrg} userData={this.state.userData} logoutUser={this.logoutUser.bind(this)} />
            ) : (null)}
            {(this.state.userData.type === 'le' && this.state.userData.accountType !== 'saarthi') ? (
              <DefaultAppLayout exact path="/udaan-centres/:type/:orgId/edit" component={UdaanOrgEdit} userData={this.state.userData} logoutUser={this.logoutUser.bind(this)} />
            ) : (null)}
            {(this.state.userData.type === 'le' && this.state.userData.accountType !== 'saarthi') ? (
              <DefaultAppLayout exact path="/udaan-centres/:type/:orgId/add-user" component={AddUdaanUser} userData={this.state.userData} logoutUser={this.logoutUser.bind(this)} />
            ) : (null)}


            <Route exact path="/404" component={NotFound} />
          {/*@Todo : update project param array (userData.hasAccessToApp Array)  */}
            {this.state.userData.accountType !== 'saarthi' ? (
              this.state.userData.hasAccessToProjects.length < 2 ? <Redirect exact from="/" to= "/dashboard" />:
              <Redirect exact from="/" to="/project-landing" />
            ) : (
              <Redirect path="*" to="/leads" />
            )}
          </Switch>
        </Router>
      )
    } else {
      return (
        <Router history={history}>
          <Switch>
            <Route exact path="/reset-password/:token" component={ChangePasswordLayout} />
            <Route exact path="/partner-login/register" component={PartnerRegister} />
            <Route exact path="/partner-login/verify" component={PartnerVerification} />
            <Route exact path="/partner-login/authenticate" component={PartnerLogin} />
            <Route exact path="/" component={Layout} />
            <Redirect path="*" to="/" />
          </Switch>
        </Router>
      )
    }
  }
}
