import React from "react";
import { FieldErrorMessage } from "../ErrorMessage/FieldErrorMessage";
import { InputGroupContainer, Labels, Input } from "./style";

export const InputGroup = (props) => {
  const {
    PlaceholderText,
    LabelText,
    name,
    type,
    onChange,
    defaultValue,
    isDisabled,
    onBlur,
    star,
    max,
  } = props;
  let tempDisabled = isDisabled === true ? isDisabled : false;

  // console.log("input group defaultValue => ", defaultValue);
  const handleWheel = (event) => {
    event.preventDefault();
  };
  return (
    <InputGroupContainer>
      <Labels>
        {LabelText} <span style={{ color: "red" }}>{star}</span>
      </Labels>

      <Input
        placeholder={PlaceholderText}
        name={name}
        type={type}
        onChange={onChange}
        defaultValue={defaultValue}
        disabled={tempDisabled}
        onBlur={onBlur}
        max={max}
        onWheel={(e) => e.target.blur()}
      />
      <FieldErrorMessage name={name} />
    </InputGroupContainer>
  );
};
