import React from 'react';
import Swal from 'sweetalert2';
import { toast } from "react-toastify";
import { readCookie } from './cookie';



const Dialer = () => {
    const userMobNumber = JSON.parse(readCookie('userData')).mobileNumber;
    const notifySuccess = (text) => toast.success(text);
    const notifyError = (text) => toast.error(text);
    const callNumber = (num) => {
        // let knowlarity_makecall__url =
        //     "https://kpi.knowlarity.com/Basic/v1/account/call/makecall";
        let EXOTEL_MAKECALL_URL = process.env.REACT_APP_API_URL + '/api/v1/call-logs/exotel/click-To-Call';
        fetch(EXOTEL_MAKECALL_URL, {
            method: "POST",
            headers: {
                // "x-api-key": "qRwE4Ugq4C3XJfNlUWUkl3CpwZ77ryWradPalKfY",
                // Authorization: "f925251f-c0aa-4b89-bdf9-60f1b06ec111",
                Accept: "application/json",
                "Content-Type": "application/json",
                "x-auth-token": JSON.parse(readCookie("access_token")),

            },
            body: JSON.stringify({
                "from": "+91" + userMobNumber,
                "to": "+91" + num.slice(num.length - 10, num.length),
            }),
        })
            .then((data) => data.json())
            .then((data) => {
                if (!data.error) {
                    notifySuccess(data.message);
                } else {
                    notifyError(`Couldn't make call!`);
                }
            });
    }

    const dial = () => {
        // Swal.fire({
        //     title: 'Enter mobile number',
        //     input: 'text',
        //     inputLabel: 'Number to dial',
        //     position: 'top-end',
        //     showCancelButton: true,
        //     inputValidator: (value) => {
        //         if (!value) {
        //             return 'Phone number can\'t be empty!'
        //         }
        //         else if (!/^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[7896]\d{9}$/.test(value)) {
        //             return 'Enter a valid phone number!'
        //         }
        //     }
        // }).then(res => {
        //     if (res.value) {
        //         callNumber(res.value);
        //     }

        // })
        
        alert('Dialer is disabled for now');

    }
    return <i onClick={dial} class="btn btn-success btn-lg fa fa-circle fa-flip-horizontal fa-5x fa-volume-control-phone" aria-hidden="true"/>
}

export default Dialer;